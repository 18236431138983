import React from "react";
import { resourcesMap } from "../../../../utils/mapping";
import { Resource } from "pixi.js";
import { Resources } from "../../../../enums/resources";

export const Friend = ({
  name,
  revShareBrought,
  imgSrc,
  length,
  index,
}: {
  index: number;
  name: string;
  revShareBrought: number;
  length: number;
  imgSrc: string;
}) => {
  return (
    <div
      className={`${
        index === length - 1 ? "mb-[100px]" : ""
      } w-full h-[72px]  bg-[#2a2827] rounded shadow-inner-sm-white border border-[#18191a] flex gap-2 justify-between p-2`}
    >
      <div className="flex justify-start items-center gap-2">
        <div className="flex items-center justify-center w-14 h-14 bg-stone-600 rounded-full border border-black">
          <img
            className="w-12 h-12  rounded-full border-[1px] border-black"
            src={
              imgSrc
                ? imgSrc
                : require("../../../../assets/images/noPhotoFriend.png")
            }
          />
        </div>

        <div className="text-white text-sm font-normal leading-[14px]">
          {name}
        </div>
      </div>
      <div className="h-full flex justify-center items-center gap-2">
        <div className="w-6 h-6">
          <img src={resourcesMap[Resources.kitsu].image} />
        </div>
        <div className="text-center text-[#47c77a] text-2xl font-black font-['Sofia Sans Semi Condensed'] leading-normal">
          +{revShareBrought.toFixed(2)}
        </div>
      </div>
    </div>
  );
};
