import {
  PanOnScrollMode,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
  Viewport,
} from "@xyflow/react";
import { PopupButton } from "../../../../../../components/PopupButton";
import { SkillNode } from "../SkillNode";
import { useTranslation } from "react-i18next";
import CustomEdge from "../CustomEdge";
import { TierLevel } from "../../../../../../interfaces/hero";
import {
  getHeroTierUpgradePrice,
  updateTierLevel,
} from "../../../../../../endpoints/heroEndpoints";
import { useTelegram } from "../../../../../../hooks/useTelegram";
import React, { useEffect, useRef, useState } from "react";
import { getBalance } from "../../../../../../endpoints/farmMock";
import { HeroRating } from "../../../HeroRating";
import { heroShardMap } from "../../../../../../utils/mapping";
import { HeroShard } from "../../../../../../interfaces/lootBotx";
import { HandleBackButton } from "../../../../../../layout/components/HeaderCave/components";
import { Offer } from "../../../../../../pages/Shop/components";
import { usePurchase } from "../../../../../../hooks/usePurchase";
import { OfferProps } from "../../../../../../pages/Shop/components/Offer";
import { PurchaseModal } from "../../../../../../components/PurchaseModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../app/store";
import {
  TutorialFarmSave,
  TutorialFarmStage,
} from "../../../../../../interfaces/tutorial";
import { saveFarmTutorialProgress } from "../../../../../../app/features/farmTutoralSlice";
import { PointerHand } from "../../../../../../components/PointerHand";
import { SuccessAnimation } from "../../../../../../pages/Quests";
import { SuccessOverlay } from "../../../../../../components/SuccessOverlay";

export const HeroTierModal = ({
  getHeroesList,
  upgradeCards,
  nodes,
  edges,
  onClose,
  rating,
  onNodeClick,
  selectedSkill,
  heroId,
  offer,
}: {
  getHeroesList: () => void;
  upgradeCards: number;
  nodes: any;
  edges: any;
  onClose?: any;
  rating: { claimedLevels: number; totalLevels: number };
  onNodeClick: any;
  selectedSkill: any;
  heroId: number;
  offer: OfferProps;
}) => {
  const nodeTypes = {
    customSkillNode: SkillNode,
  };
  const edgeTypes = {
    custom: CustomEdge,
  };
  const { t } = useTranslation();

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save,
  );

  const dispatch = useDispatch<AppDispatch>();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  useEffect(() => {
    if (farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade) {
      updateSave({
        stage: TutorialFarmStage.upgradeTier,
      });
    }
  }, [selectedSkill]);

  const [upgradePrice, setUpgradePrice] = useState<number>();
  const { userId } = useTelegram();

  const updateTierHero = async () => {
    const result = await updateTierLevel({
      clientId: userId,
      heroId: heroId,
      tier: Number(selectedSkill.id),
    });

    await getBalance({
      clientId: userId,
    });

    if (result) {
      getHeroesList();
      fetchHeroTierUpgradePrice();
    }
  };

  useEffect(() => {
    getHeroesList();
  }, []);

  const fetchHeroTierUpgradePrice = async () => {
    try {
      const price = await getHeroTierUpgradePrice({
        clientId: userId,
        heroId: heroId,
      });
      setUpgradePrice(price);
    } catch (error) {}
  };

  useEffect(() => {
    fetchHeroTierUpgradePrice();
  }, []);

  const allLevelsClaimed =
    selectedSkill?.data?.levels?.every((level: TierLevel) => level.isClaimed) ||
    false;

  const YScroll = selectedSkill
    ? window.innerHeight < 800
      ? 1060
      : 950
    : window.innerHeight < 800
      ? 980
      : 920;

  const [showOffer, setShowOffer] = useState(false);

  const handleNoTiersPromoteButPack = () => {
    setShowOffer(true);
  };

  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  return (
    <>
      <div
        className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm 
    ${
      farmTutorialSave?.stage === TutorialFarmStage.clickOnRankUpgrade ||
      farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade ||
      farmTutorialSave?.stage === TutorialFarmStage.upgradeTier
        ? "z-[11]"
        : "z-1"
    }`}
      >
        <div
          className={`absolute top-[50px] w-full max-w-4xl pt-2 overflow-hidden 
          ${
            farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade &&
            !selectedSkill
              ? "z-100 animate-highlight"
              : ""
          } `}
          style={{ height: "75vh" }}
        >
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodesDraggable={false}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onNodeClick={onNodeClick}
              elementsSelectable={true}
              style={{ backgroundColor: "transparent", height: "100%" }}
              proOptions={{ hideAttribution: true }}
              fitView
              zoomOnScroll={false}
              zoomOnPinch={false}
              panOnScroll={true}
              panOnDrag={true}
              zoomOnDoubleClick={false}
              panOnScrollMode={PanOnScrollMode.Vertical}
              translateExtent={[
                [124, 0],
                [455, YScroll],
              ]}
            />
          </ReactFlowProvider>
        </div>
        <div
          className={`w-full absolute h-auto left-0 bottom-0  flex flex-col  z-[11] bg-gradient-to-t from-[#201B18] via-[#201B18]/100 via-80% to-transparent to-100%
          ${
            farmTutorialSave?.stage === TutorialFarmStage.chooseTierToUpgrade
              ? "brightness-50 pointer-events-none"
              : ""
          }`}
        >
          <div className="relative w-full h-fit top-[15%] flex flex-col">
            <div className="py-2">
              <HeroRating
                claimedLevels={rating.claimedLevels}
                totalLevels={rating.totalLevels}
                starsWidth={"w-[32px]"}
              />
            </div>

            {selectedSkill && (
              <div className="flex flex-col gap-1 px-4  overflow-auto max-h-28 ">
                {selectedSkill.data.levels?.map((level: TierLevel) => (
                  <div
                    key={level.id}
                    className="flex justify-center items-center relative overflow-hidden flex-shrink-0 min-h-7"
                    style={{
                      background:
                        "radial-gradient(circle, rgba(153, 153, 153, 0.2) 0%, rgba(255, 255, 255, 0) 80%)",
                    }}
                  >
                    {/* Верхня Рамка */}
                    <div
                      className="absolute top-0 left-0 w-full h-[1px] pointer-events-none"
                      style={{
                        background:
                          "radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)",
                      }}
                    ></div>

                    {/* Нижня Рамка */}
                    <div
                      className="absolute bottom-0 left-0 w-full h-[1px] pointer-events-none"
                      style={{
                        background:
                          "radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)",
                      }}
                    ></div>
                    <div className="grid grid-cols-4 justify-items-center">
                      {!level.isClaimed ? (
                        <div className="w-[24px] h-[24px]">
                          <img
                            className="w-full h-full"
                            src={require("../../../../../../assets/images/heroes/icons/closed.png")}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* Вміст Елемента */}
                      <div
                        className={`${
                          level.isClaimed
                            ? "flex flex-col items-center justify-center text-center text-[#ffefcb] text-sm font-bold leading-3 col-span-2"
                            : "flex flex-col items-center justify-center text-center text-[#6a6565] text-sm font-bold leading-3 col-span-2"
                        }  `}
                        style={
                          level.isClaimed
                            ? {
                                filter:
                                  "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
                              }
                            : {}
                        }
                      >
                        {level.rewards.map((reward, index) => {
                          return (
                            <div
                              key={index}
                              className="flex justify-center items-center"
                            >
                              {t(`bonusTypes.${reward.bonusType}`)}:{" "}
                              {reward.value}
                            </div>
                          );
                        })}
                      </div>
                      <div />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex gap-2 justify-center items-center pt-2 pb-5 flex-grow h-[15vh] ">
              {/* <div className="flex flex-col gap-1">
              <div className="flex gap-2 justify-center items-center">
                <div className="flex gap-1">
                  <div className="text-right text-[#3adc96] text-sm font-black uppercase leading-[14px]">
                    2
                  </div>
                  <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                    /
                  </div>
                  <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                    4
                  </div>
                </div>
              </div>
              <PopupButton type={"blue"} height="64px">
                <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px]">
                  Upgrade Tier
                </div>
              </PopupButton>
            </div> */}

              <div className="flex flex-col justify-between gap- h-full">
                {!allLevelsClaimed ? (
                  <div className="flex gap-2 justify-center items-center">
                    <div className="flex gap-1 justify-center items-center">
                      <img
                        src={heroShardMap[heroId as HeroShard].image}
                        className="w-6 h-6"
                      />
                      <div
                        className={`text-right ${
                          upgradePrice! > upgradeCards
                            ? "text-[#dd5444]"
                            : "text-[#3adc96]"
                        } text-sm font-black uppercase leading-[14px]`}
                      >
                        {upgradeCards}
                      </div>
                      <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                        /
                      </div>
                      <div className="text-right text-white text-sm font-black uppercase leading-[14px]">
                        {upgradePrice}
                      </div>
                    </div>
                  </div>
                ) : null}
                {allLevelsClaimed ? (
                  <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px] pt-4">
                    {t("heroes.skillFullClaimed") || "Skill full claimed"}
                  </div>
                ) : (
                  <div className={`mb-[25px]`}>
                    <PopupButton
                      type={selectedSkill?.data?.available ? "green" : "gray"}
                      disabled={!selectedSkill?.data?.available}
                      onClick={() => {
                        updateSave({
                          stage: TutorialFarmStage.finishTierTutorial,
                        });
                        if (
                          upgradePrice !== undefined &&
                          upgradeCards >= upgradePrice
                        ) {
                          updateTierHero();
                        } else {
                          handleNoTiersPromoteButPack();
                        }
                      }}
                    >
                      <div className="text-center text-[#ffefcb] text-lg font-normal leading-[18px] p-[5px]">
                        {t("heroes.upgradeTier")}
                      </div>
                      {farmTutorialSave?.stage ===
                        TutorialFarmStage.upgradeTier && (
                        <div
                          className={`absolute z-[9999] right-[20px] top-[20px]`}
                        >
                          <PointerHand />
                        </div>
                      )}
                      {selectedSkill?.data?.available &&
                        upgradePrice !== undefined &&
                        upgradeCards >= upgradePrice && (
                          <img
                            className="absolute top-0 right-0 z-50 w-3 h-3"
                            src={require("../../../../../../assets/images/heroes/cards/notification.png")}
                            alt="Notification"
                          />
                        )}
                    </PopupButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showOffer && (
        <div className="fixed inset-0 min-w-full min-h-full z-20">
          {/* Чорний фоновий шар */}

          <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

          {/* Компонент Offer, який має бути поверх чорного фону */}
          <div className="absolute inset-0 z-30 flex items-center justify-center">
            <Offer
              onClose={() => setShowOffer(false)}
              onClick={() => {
                confirmPurchase(offer.id);
              }}
              offerType={offer.offerType}
              id={offer.id}
              resourceList={offer.resourceList}
              layout={offer.layout}
              type={offer.type}
              label={offer.label}
              kitsu={offer.kitsu}
              price={offer.price}
              deadline={offer.deadline}
            />
          </div>
        </div>
      )}
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </>
  );
};
