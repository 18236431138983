export enum OfferType {
  kitsune,
  experience,
  speedUp,
  stoneOffer,
  keysOffer,
  rankOffer,
  freeRankOffer,
}

// export enum OfferCategory {
//   shop,
//   smallInGameOffer,
//   bigInGameOffer,
// }

export enum ItemType {
  timeBoosts,
  lootBox,
  farmResource,
  universalShard,
  heroShard,
}
export interface RewardsPremium {
  itemId: number;
  itemType: ItemType;
  amount: number;
}

interface ConfigTask {
  isPremium: boolean;
  id: number;
  amount: number;
  rewards: RewardsPremium[];
}

export interface PremiumTask {
  isReadyToClaim: boolean;
  isClaimed: boolean;
  configTask: ConfigTask;
}

export interface Items {
  rewardId: { value: number };
  rewardType: { value: ItemType };
  amount: { value: number };
}

export interface ConfigOffer {
  offerId: { value: number };
  price: { value: number };
  stars: { value: number };
  items: { value: Items[] };
  offerType: { value: OfferType };
  // offerCategory: { value: OfferCategory };
  title?: string;
  description?: string;
  firstBuy?: boolean;
  isClaimed?: boolean;
}
