import { CardImage, TextColor } from "../components/Card/CardComponentBuilder";
import { Items } from "../mock/offers";

export interface BattleInit {
  maxHp: number;
  currentHp: number;
  maxMana: number;
  defense: number;
  heroId: number;
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  actionPoints: number;
  enemy: {
    id: number;
    lvl: number;
    maxHp: number;
    maxMana: number;
    defense: number;
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

export enum ExecutionActionType {
  addEffect, // накалдываем бафф/дебафф
  executeEffect, // исполняется еффект бафф/дебаффа
  removeEffect, // удаляем бафф/дебафф
  executeSkill, // исполняем скилл
  changeParameters, // добавление/уменьшения хп/маны....
  updateCards, // обновляем карты в руке
  discardCards, // сбрасываем карты
  shuffleCards, // перемешиваем карты
}

export enum Opponent {
  hero,
  enemy,
}

export enum Parameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CardType {
  atk,
  equip,
  action,
  spell,
}

// export interface MakeAction {
//   type: ExecutionActionType;
//   data: {
//     effectUid?: string;
//     effectId?: number;
//     durationInRounds?: number;
//     skillId?: string;
//     target: Opponent;
//     parameters?: {
//       parameter: Parameter,
//       value: number;
//     }
//   }
// }

export interface HeroCards {
  cardId: number;
  stars: number;
}

export enum HeroType {
  warrior,
  wizard,
}

export const heroConfig: HeroConfig[] = [
  {
    id: 0,
    type: HeroType.warrior,
    hp: 100,
    mana: 100,
    cards: [],
    lvl: 1,
  },
];

export interface HeroConfig {
  id: number;
  type: HeroType;
  hp: number; //начальные х-ки
  mana: number; //начальные х-ки
  cards: HeroCards[];
  lvl: number;
}

export type MakeAction =
  | {
      type: ExecutionActionType;
      effectUid: string;
      effectId: number;
      durationInRounds: number;
      target: Opponent;
    }
  | {
      type: ExecutionActionType;
      parameter: Parameter;
      value: number;
      target: Opponent;
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      skillId: string;
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      cardIds: number[];
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      value: number;
      cardIds: number[];
    }
  | {
      type: ExecutionActionType;
      target: Opponent;
      value: number;
    };

export interface EndTurn {
  steps: {
    cardInfo?: { cardId: number; lvl: number; uid: string };
    actions: MakeAction[];
  }[];
  cardsAtHand: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  cardsAtDeck: [
    {
      id: number;
      uid: string;
      lvl: number;
    }
  ];
  enemy: {
    cardsAtHand: [
      {
        id: number;
        uid: string;
        lvl: number;
      }
    ];
  };
}

// enum CardType {
//   atk
// act
// equip
// spell
// }

export enum ConditionParameter {
  health,
  mana,
  actionPoints,
  defense,
}

export enum CalculationMethod {
  percent,
  count,
}

export enum ConditionSide {
  less,
  more,
}

export enum StageType {
  enemy,
  elixir,
  shop,
  forge,
  bonfire,
  abyss,
  boss,
  chest,
  random,
}

export enum AwardType {
  gold,
  card,
  mana,
  health,
}

export interface DungeonStagesResponse {
  id: number;
  stageId: number;
  type: StageType;
  amount: number;
  currentAmount: number;
}

export type DungeonStage =
  | {
      id: number; //хилка
      type: StageType;
      amount: number;
      lvl?: number;
      title: string;
      description: string;
      currentAmount: number;
      percent?: number;
      pool?: StageType[];
      buildingId?: number;
    }
  | {
      //батл
      id: number;
      type: StageType;
      enemyId: number;
      lvl?: number;
      title: "";
      description?: string;
      buildingId?: number;
      pool?: StageType[];
    }
  | {
      id: number; // сундук
      type: StageType;
      awardType: AwardType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      rewardAmount?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // магазин/кузница/таверна/сундук
      type: StageType;
      title: string;
      description?: string;
      buildingId?: number;
      lvl?: number;
      percent?: number;
      pool?: StageType[];
    }
  | {
      id: number; // random
      type: StageType;
      description?: string;
      lvl?: number;
      buildingId?: number;
      poolId?: StageType[];
    };

export interface DungeonType {
  dungeonId: number;
  description: string;
  stages: DungeonStage[];
}

interface DungeonStageConfig {
  id: { value: number };
  amount?: { value: number };
  currentAmount?: { value: number };
  enemyId?: { value: number };
  awardType?: { value: AwardType };
  rewardAmount?: { value: number };
  title?: { value: string };
  percent?: { value: number };
  pool?: { value: StageType[] };
  type: { value: StageType };
  description?: { value: string };
  lvl?: { value: number };
  buildingId?: { value: number };
  poolId?: { value: StageType[] };
}

export interface DungeonTypeConfig {
  description: {value: string}
  dungeonId: { value: number };
  rewards: { value: Items[] };
  stages: { value: DungeonStageConfig[] };
}

export interface BattleSaves {
  dungeonId: number;
  stages: DungeonStagesResponse[];
  enemyId?: number;
  currentHp: number;
  currentMana: number;
  gold: number;
  currentStage?: number;
  buildingId: number;
}

//export const dungeonConfig: DungeonType[] = [];
// dungeonId: 1,
//   stages: [
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//      type: StageType.enemy,
//       enemyId: 0,
//     },
//     {
//       type: StageType.chest,
//      awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//       type: StageType.enemy,
//       enemyId: 0,
//     },
//     {
//       type: StageType.chest,
//       awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//     {
//       type: StageType.health,
//       amount: 2,
//     },
//     {
//       type: StageType.boss,
//       enemyId: 1,
//     },
//     {
//       type: StageType.chest,
//       awardType: AwardType.gold,
//       rewardAmount: 10,
//     },
//   ]
// }];

export enum EffectType {
  defense,
  triggeredByEnemiesAttackCard,
  doubleDamage,
  returnDamage,
  damage,
  repeatableDamage,
  getCard,
  shuffle,
  discard,
  gainMaxHp,
  lessCardPerRound,
  heal,
  piercingDamage,
  negateAttack,
  damageEqualPercentCurrentHealth,
  exiled,
  addActionPoint,
  copyAllFromHandByTypeAndShuffle,
  lessDamage,
  additionalDamage,
  additionalDefense,
  returnDamageByHandSize,
  damagePerLostHP, // - убрано
  damagePerEquiped, // 23 - убрано
  copyPreviousEffects, // 24 - убрано
  viewCardsandKeep, // view - value, keep - animationCount, card 37, 39, новые
  drawCardPerUseATK, // amount - value не используется
  transformLeftmostAttackToCopy, // card 40, новые
  shuffleTempCopiesOfAttack, // card 41, новые
  reduceCost, // - -1 cost, на зеленых
  priceManna,
  getRandomCardFromDeck, //
  gainRandomTempAttack,
  getRandomConfigCard,
  damagePerArtefact, // five red
  viewCard,
}

export type level = {
  value: number;
  target: "enemy" | "self";
  effectType: number;
  animationCount?: number;
};
export interface CardsConfig {
  id: number;
  data: {
    type: CardType; //TODO потом переделать на енам
    name: string;
    description: string;
    stars: number;
    image: CardImage;
    priceManna?: number;
    actionPoints?: number;
    draggable?: boolean;
    condition?: {
      target: Opponent;
      parameter: ConditionParameter;
      value: number;
      calculationMethod: CalculationMethod;
      conditionSide: ConditionSide;
    };
  };
  levels?: level[][];
}

export const mockCards: CardsConfig[] = [
  {
    id: 0,
    data: {
      type: CardType.atk,
      image: CardImage.STONE,
      name: "ROCK",
      description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg}`,
      stars: 3,
      draggable: true,
    },
    levels: [],
  },
  {
    id: 8, // DOUBLE ID
    data: {
      type: CardType.atk,
      image: CardImage.PRANK,
      name: "Prank",
      description: "Probably nothings",
      stars: 0,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 9,
    data: {
      type: CardType.atk,
      image: CardImage.GRIM_HARVEST,
      name: "Grim Harvest",
      description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg}. Kill: Gain {${EffectType.gainMaxHp}} max health`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 17,
    data: {
      type: CardType.atk,
      image: CardImage.RECKLESS_SLASH,
      name: "Reckless Slash",
      description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg} +1 per for 5 lost HP`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 6,
    data: {
      type: CardType.atk,
      image: CardImage.DESPERATION_STRIKE,
      name: "Desperation Strike",
      description: `Deal {${TextColor.RED}:{${EffectType.damage}} dmg}. {${TextColor.WHITE}:{selfPierce}}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  }, // {t('cards.9', {textColor: TextColor.RED})}
  {
    id: 18,
    data: {
      type: CardType.atk,
      image: CardImage.EXILED_FURY,
      name: "Exiled Fury",
      description: `Exiled. Deal {${TextColor.RED}:{${EffectType.exiled}} dmg}. Get a level 3 Basic Attack`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 19,
    data: {
      type: CardType.atk,
      image: CardImage.WEIGHT_OF_STEEL,
      name: "Weight of Steel",
      description: `Deal {${TextColor.RED}:{dmg} dmg} per equipped item`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 1,
    data: {
      type: CardType.atk,
      image: CardImage.TRIPLE_SCAR,
      name: "Triple Scar",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Repeats {repeats} times}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 15,
    data: {
      type: CardType.atk,
      image: CardImage.FROST_BITE,
      name: "Frost Bite",
      description: `Deal {${TextColor.RED}:{dmg} dmg}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 20,
    data: {
      type: CardType.atk,
      image: CardImage.TRICKSTERS_GAMBIT,
      name: "Trickster's Gambit",
      description: "Shuffle {shuffle} Pranks into enemy deck",
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 4,
    data: {
      type: CardType.atk,
      image: CardImage.DEATH_SENTENCE,
      name: "Death Sentence",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. If <30% HP, deal {${TextColor.RED}:{conditionalDmg} dmg}`,
      stars: 3,
      priceManna: 0,
      condition: {
        target: Opponent.enemy,
        parameter: ConditionParameter.health,
        value: 30,
        calculationMethod: CalculationMethod.percent,
        conditionSide: ConditionSide.less,
      },
    },
    levels: [],
  },
  {
    id: 13,
    data: {
      type: CardType.atk,
      image: CardImage.BORROWED_INSIGHT,
      name: "Borrowed Insight",
      description: `Draw {drawCards} cards. {${TextColor.WHITE}:Next turn: -{reduceCards} card}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 11,
    data: {
      type: CardType.atk,
      image: CardImage.SHADOW_SUPPRESSION,
      name: "Shadow Suppression",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Both draw 1 less card}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 22,
    data: {
      type: CardType.atk,
      image: CardImage.STORMCALLERS_WRATH,
      name: "Stormcaller's Wrath",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.RED}:+{extraDmg} dmg} this turn`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 23,
    data: {
      type: CardType.atk,
      image: CardImage.SHIELD_BASH,
      name: "Shield Bash",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. Take 1 less dmg till next turn`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 21,
    data: {
      type: CardType.atk,
      image: CardImage.GHOST_PIERCER,
      name: "Ghost Piercer",
      description: `Deal {${TextColor.RED}:{dmg} piercing dmg}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 10,
    data: {
      type: CardType.atk,
      image: CardImage.MIND_RIPPER,
      name: "Mind Ripper",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. Draw 1 card`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 14,
    data: {
      type: CardType.atk,
      image: CardImage.LIFE_DRAIN,
      name: "Life Drain",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. Restore same HP`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 36,
    data: {
      type: CardType.atk,
      image: CardImage.ECHO_STRIKE,
      name: "Echo Strike",
      description: "Copy previous attack card's effects.",
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 12,
    data: {
      type: CardType.atk,
      image: CardImage.BLADES_DANCE,
      name: "Blades Dance",
      description: `Deal {${TextColor.RED}:{dmg} dmg} {${TextColor.WHITE}:and draw 2 cards}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 24,
    data: {
      type: CardType.atk,
      image: CardImage.SHADOW_PARRY,
      name: "Shadow Parry",
      description: `Negate enemy attack. {${TextColor.RED}:2 dmg}. Draw 1 card`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 25,
    data: {
      type: CardType.spell,
      image: CardImage.SANGUINE_PACT,
      name: "Sanguine Pact",
      description: "Deal dmg equal to {percent}% of your health.",
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 26,
    data: {
      type: CardType.spell,
      image: CardImage.OBSIDIAN_GUARD,
      name: "Obsidian Guard",
      description: `Gain {${TextColor.GREEN}:Armor +{armor}}, {${TextColor.WHITE}:take 1 pierce dmg}`,
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 2,
    data: {
      type: CardType.spell,
      image: CardImage.BOLSTERED_DEFENSES,
      name: "Bolstered Defenses",
      description: `Gain {${TextColor.GREEN}:{armor} Armor}. {${TextColor.WHITE}:Draw a card.}`,
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 27,
    data: {
      type: CardType.spell,
      image: CardImage.STONEHOLD_BARRIER,
      name: "Stonehold \\n Barrier",
      description: "Gain {armor} Armor.",
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 37,
    data: {
      type: CardType.spell,
      image: CardImage.FORSAKEN_KNOWLEDGE,
      name: "Forsaken Knowledge",
      description: "Exiled. View 3 cards, keep 1 permanently.",
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 16,
    data: {
      type: CardType.spell,
      image: CardImage.QUICKDRAW_STRATEGY,
      name: "Quickdraw Strategy",
      description: "Draw {drawCards} cards. Cost -1 Action  per power.",
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 28,
    data: {
      type: CardType.spell,
      image: CardImage.VOIDSTRIKE,
      name: "Voidstrike",
      description: `Exiled, {${TextColor.GREEN}:+{mana} Mana,} {${TextColor.RED}:{selfPierce} pierce dmg}, {${TextColor.WHITE}:draw 1 card}`,
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 38,
    data: {
      type: CardType.spell,
      image: CardImage.ARCANE_ECHO,
      name: "Arcane Echo",
      description: `{${TextColor.WHITE}:Exiled. This turn,} {${TextColor.WHITE}:draw 1 card} per {${TextColor.GREEN}:use ATK card}`,
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 29,
    data: {
      type: CardType.spell,
      image: CardImage.MIRAGE_ASSAULT,
      name: "Mirage Assault",
      description: `Copy all {${TextColor.GREEN}:ATK} cards, shuffle into deck. {${TextColor.WHITE}:Draw 1 card}`,
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 33,
    data: {
      type: CardType.spell,
      image: CardImage.MINDBREAK,
      name: "Mindbreak",
      description: "Each draws {drawCards} cards. Enemy discards non-attacks.",
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 30,
    data: {
      type: CardType.spell,
      image: CardImage.BLAZING_WRATH,
      name: "Blazing Wrath",
      description: `Deal {${TextColor.RED}:{dmg} dmg}. {${TextColor.WHITE}:Exile this card}`,
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 34,
    data: {
      type: CardType.spell,
      image: CardImage.WARRIORS_GAMBIT,
      name: "Warrior's Gambit",
      description: "Draw {drawCards} card. Gain random temp attack.",
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 31,
    data: {
      type: CardType.spell,
      image: CardImage.KEEN_INSIGHT,
      name: "Keen Insight",
      description: `Next card {${TextColor.RED}:dmg is} {${TextColor.RED}:doubled} this turn`,
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 39,
    data: {
      type: CardType.spell,
      image: CardImage.EXILED_BASTION,
      name: "Exiled Bastion",
      description: "View 3 your cards, copy 1 to hand.",
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 32,
    data: {
      type: CardType.spell,
      image: CardImage.ASTRAL_WARD,
      name: "Astral Ward",
      description: `Exiled. Gain {${TextColor.GREEN}:Armor +{armor}}, -{dmg} dmg for 2 turns`,
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 40,
    data: {
      type: CardType.spell,
      image: CardImage.SPECTRAL_ONSLAUGHT,
      name: "Spectral Onslaught",
      description: `{${TextColor.GREEN}:Armor +4}. Transform {${TextColor.WHITE}:leftmost attack} {${TextColor.WHITE}:to copy of this}`,
      stars: 2,
      actionPoints: 0,
    },
  },
  {
    id: 7,
    data: {
      type: CardType.spell,
      image: CardImage.JESTERS_MALICE,
      name: "Jester's Malice",
      description:
        "Shuffle {drawCards} Pranks into enemy deck. Enemy discards 1 next turn",
      stars: 3,
      actionPoints: 0,
    },
  },
  {
    id: 41,
    data: {
      type: CardType.spell,
      image: CardImage.HARLEQUINS_TRICK,
      name: "Harlequin’s Trick",
      description: "Exiled. Shuffle 3 temp copies of attack into deck",
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 411,
    data: {
      type: CardType.equip,
      image: CardImage.SLINGSHOT,
      name: "Slingshot",
      description: `When use 2 attacks: Deal {${TextColor.RED}:{dmg} dmg}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 42,
    data: {
      type: CardType.equip,
      image: CardImage.BLADE_OF_VENGEANCE,
      name: "Blade of Vengeance",
      description: `Take dmg on your turn: {${TextColor.GREEN}:Next your dmg is doubled}`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 43,
    data: {
      type: CardType.equip,
      image: CardImage.SPIKED_SHIELD,
      name: "Spiked Shield",
      description: `{${TextColor.BROWN}:Start turn:} Take 1 pierce dmg, gain {armor} armor`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 44,
    data: {
      type: CardType.equip,
      image: CardImage.ROGUES_SATCHEL,
      name: "Rogue's Satchel",
      description: `{${TextColor.BROWN}:Start turn:} Copy 1 random card to hand`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 45,
    data: {
      type: CardType.equip,
      image: CardImage.POTION_OF_RAGE,
      name: "Potion of Rage",
      description: `{${TextColor.BROWN}:When use attacks:} Deal {${TextColor.RED}:{dmg} dmg}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 46,
    data: {
      type: CardType.equip,
      image: CardImage.BAG_OF_PAIN,
      name: "Bag of Pain",
      description: `{${TextColor.BROWN}:Start turn:} Take {selfPierce} pierce dmg, draw {drawCards} card`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 47,
    data: {
      type: CardType.equip,
      image: CardImage.SORCERERS_HORN,
      name: "Sorcerer's Horn",
      description: `{${TextColor.BROWN}:When Use Mana:} Next attack deals double dmg, {${TextColor.GREEN}:+1 Mana} next turn`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 48,
    data: {
      type: CardType.equip,
      image: CardImage.MIRACLE_MIRROR,
      name: "Miracle Mirror",
      description: `{${TextColor.BROWN}:Use ACT:} {${TextColor.WHITE}:Enemy gets basic attack,} you draw {drawCards} card`,
      stars: 3, // stars.length
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 35,
    data: {
      type: CardType.equip,
      image: CardImage.SNAKE_TRAP,
      name: "Snake Trap",
      description: `{${TextColor.BROWN}:Each time enemy ATK:} Deal pierce dmg = hand size`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 50,
    data: {
      type: CardType.equip,
      image: CardImage.BEAR_SNARE,
      name: "Bear Snare",
      description: `{${TextColor.BROWN}:Start Enemy turn:} +{dmg} all dmg`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 51,
    data: {
      type: CardType.equip,
      image: CardImage.CAGE_OF_FURY,
      name: "Cage of Fury",
      description: `{${TextColor.BROWN}:Enemy uses an attack} {${TextColor.BROWN}:card:} Takes {${TextColor.RED}:{dmg} dmg}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 52,
    data: {
      type: CardType.equip,
      image: CardImage.TOME_OF_CURSES,
      name: "Tome of Curses",
      description: `{${TextColor.BROWN}:Play Basic Attack:} Add temp copy to hand`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 53,
    data: {
      type: CardType.equip,
      image: CardImage.GOBLET_OF_BLOOD,
      name: "Goblet of Blood",
      description: "Restore 1 health for every {dmg} damage you deal.",
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 54,
    data: {
      type: CardType.equip,
      image: CardImage.ARMOR_OF_THE_WOLF,
      name: "Armor of the Wolf",
      description: `{${TextColor.BROWN}:Start turn:} Draw armor card, {${TextColor.GREEN}:+1 Mana}`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 55,
    data: {
      type: CardType.equip,
      image: CardImage.SCYTHE_OF_DESPAIR,
      name: "Scythe of Despair",
      description: "Attacks take effect twice, discard left-most card.",
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 56,
    data: {
      type: CardType.equip,
      image: CardImage.AMULET_OF_LIGHT,
      name: "Amulet of Light",
      description: `{${TextColor.BROWN}:End turn:} {${TextColor.WHITE}:Equip random item,} become Wind-Up Knight`,
      stars: 1,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 57,
    data: {
      type: CardType.equip,
      image: CardImage.WARRIORS_BREASTPLATE,
      name: "Warrior's Breastplate",
      description: "You take -{dmg} damage.",
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 3,
    data: {
      type: CardType.equip,
      image: CardImage.STAFF_OF_ARCANE_POWER,
      name: "Staff of Arcane Power",
      description: `{${TextColor.BROWN}:End turn:} {${TextColor.RED}:{dmg} dmg} per power`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  {
    id: 5,
    data: {
      type: CardType.equip,
      image: CardImage.HELM_OF_VALOR,
      name: "Helm of Valor",
      description: `{${TextColor.BROWN}:Start turn:} {${TextColor.WHITE}:Gain +{armor} armor}`,
      stars: 3,
      actionPoints: 0,
    },
    levels: [],
  },
  // {
  //   id: 5,
  //   data: {
  //     type: CardType.equip,
  //     name: "20Blue",
  //     description: "At the start of your turn gain Armor x2.",
  //     stars: 0,
  //     actionPoints: 0,
  //   },
  // },
  // last id: 58
];
