import { useTranslation } from "react-i18next";

export const InventoryButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="select-none cursor-pointer w-14 h-10 pb-[2px] bg-[#452B31] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-95">
      <div
        className="w-full h-full rounded-lg flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative"
        onClick={onClick}
      >
        <div className="relative flex flex-col items-center ">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <img
              src={require("../../assets/images/inventory.png")}
              className="absolute -top-6 w-[34px] h-[34px] max-w-none"
            />
          </div>
          <div
            className="absolute -top-[23px] text-center text-white text-sm font-bold leading-3 mt-8"
            style={{
              filter:
                "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
            }}
          >
            {t("inventory.name")}
          </div>
        </div>
      </div>
    </div>
  );
};
