import { useEffect, useState } from "react";
import { SkillData } from "../../../containers/Heroes/components/HeroCard/components/transformTreeToSkillsData";
import { useTranslation } from "react-i18next";

interface TiersModalProps {
  skillData: SkillData[];
  expanded: boolean
}

export const TiersModal: React.FC<TiersModalProps> = ({ skillData, expanded }) => {
  const [tiersExpand, setTiersExpand] = useState(false);
  useEffect(() => {
    setTiersExpand(false)
  }, [expanded])
  const { t } = useTranslation();

  return (
    <>
      <button
        onClick={() => setTiersExpand(true)}
        className="absolute w-[50px] h-[50px] left-[9px] bottom-[101px] z-[1]"
      >
        <img
          src={require("../../../assets/images/battle-tiers.png")}
          alt="Battle Tiers"
        />
      </button>

      {/* Overlay */}
      {tiersExpand && (
        <div
          onClick={() => setTiersExpand(false)}
          className="absolute w-full h-full z-[8] "
        ></div>
      )}

      {/* Modal Content */}
      {tiersExpand && (
        <div className="absolute flex flex-col items-center left-[10px] bottom-[156px] max-h-[240px] w-[225px] bg-[#19191b] z-[11] rounded-[7px] border border-[#534737] overflow-auto">
          <div className="w-full">
            {skillData
              // Фільтруємо навики, які доступні та вже отримані
              .filter((skill) => skill.isClaimed && skill.isAvailable)
              .map((skill, index) => {
                return (
                  <div key={index}>
                    {skill.levels
                      // Фільтруємо рівні, які вже отримані
                      .filter((level) => level.isClaimed)
                      .map((level) => (
                        <div key={level?.id} className={`flex justify-start items-center ${index % 2 === 0
                            ? "bg-[#342a21] rounded"
                            : "bg-[#18191a]"
                            } py-1 px-2 `}
                        >
                          <div className="flex items-center">
                            <img
                              src={require(`../../../assets/images/heroes/upgradeIcons/${skill.image}`)}
                              className="w-[34px] min-w-[34px] h-[34px] mr-2"
                              alt="Skill Icon"
                            />
                          </div>
                          <div className="flex flex-col">
                            {level.rewards.map((reward, i) => (
                              <div
                                key={i}
                                className="flex gap-2 justify-center items-center"
                              >
                                <div className="text-white text-sm font-light leading-[14px]">
                                  {t(`bonusTypes.${reward.bonusType}`) ||
                                    reward.bonusType}
                                  :
                                </div>

                                <span className="text-[#ff3a3a] text-sm font-light  leading-[14px]">
                                  {reward.value}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
