import { useTranslation } from "react-i18next";
import { UpgradeArrow } from "../../containers/Room";
import { LevelUpBanner } from "../LevelUp";
import { UpgradeDataRow } from "../PopupUpgradeTable";
import { RootState } from "../../app/store";
import { LevelUpRays } from "../animation/LevelUpRays";
import { useSelector } from "react-redux";
import { FriendsButton } from "../FriendsButton";
import { InventoryButton } from "../InventoryButton";
import { QuestsButton } from "../QuestsButton";
import { TutorialFarmStage } from "../../interfaces/tutorial";

export const LevelUpBuilding = ({
  text,
  upgradeData,
  buildingId,
  roomLvl
}: {
  text: string;
  upgradeData: UpgradeDataRow[];
  buildingId?: number;
  roomLvl?: number
}) => {
  const { t } = useTranslation();

  const isFarmTutorialCompleted = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave?.save?.completed
  );
  
  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  function msToTime(duration: any) {
    if (!duration) {
      return;
    }
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }

  const parseUpgradeData = (value: number, id: number) => {
    switch(id){
      case 0:{
        return value*720 //720 ticks in 1 hour (1 tick per 5 seconds)
      }
      case 1:{
        return msToTime(value)
      }
      default:{
        return value
      }
    }
  }

  return (
    <div className="fixed inset-0 z-[999]">
      <div className="w-full h-full absolute bg-gradient-to-b from-black via-black/70 to-black opacity-80"></div>
      <div className="relative top-[35%] w-full h-full inset-x-4 rounded-[2px] p-[2px] left-0">
        <div className="relative top-[-47%] flex w-full h-full">
          <LevelUpRays />
        </div>

        <div className="absolute top-[-20px] left-0 right-0 flex justify-center">
          <LevelUpBanner text={`${t(text)} Level Up!`} />
        </div>
        <div className="absolute top-0 left-0 right-0 w-full flex flex-col justify-center pt-5">
          <div className="w-full max-w-[600px]">
            {upgradeData && upgradeData.length > 0
              ? upgradeData.map((row, i) => (
                  <div
                    className={`w-full flex justify-center items-center px-6 ${
                      i % 2 === 1 ? "py-2.5" : "py-4"
                    }`}
                    key={i}
                  >
                    <p className="text-[18px] text-white w-1/2 pl-6">
                      {t(row.title)}
                    </p>
                    <div className="flex flex-row items-center justify-center gap-1  w-1/2">
                      <p className="text-[16px] text-white">{parseUpgradeData(row.nowValue, i)}</p>
                      <UpgradeArrow direction="right" />
                      <p className="text-[16px] text-[#F6A000]">
                        {parseUpgradeData(row.newValue, i)}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        {farmTutorialSave?.stage &&
          ((farmTutorialSave.stage < TutorialFarmStage.finish &&
            !isFarmTutorialCompleted) ||
            farmTutorialSave.stage ===
              TutorialFarmStage.finishSecondBuilding && roomLvl  && roomLvl <= 1) &&
          buildingId &&
          (buildingId === 2 || buildingId === 3) && (
            <div className="absolute top-[330px] left-0 right-0 w-full flex flex-col items-center justify-center pt-5 gap-4">
              <div className="flex gap-2">
                {farmTutorialSave?.stage ===
                  TutorialFarmStage.finishFirstBuilding && (
                  <>
                    <InventoryButton onClick={() => {}} />
                  </>
                )}

                {farmTutorialSave?.stage ===
                  TutorialFarmStage.finishSecondBuilding && (
                  <>
                    <QuestsButton />
                    <FriendsButton />
                  </>
                )}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
