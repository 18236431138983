import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardsModal } from "../../../pages/Battle/CardsModal";
import { CardProps } from "../../../interfaces/card";
import { HpBarAnimation } from "../../../components";
import { useUtils } from "../../../utils/navigateTo";
import { ManaBarAnimation } from "../../../components/animation/ManaBarAnimation";
import { useTranslation } from "react-i18next";
import { useSoundService } from "../../../utils/soundService";
import { PopupButton } from "../../../components/PopupButton";
import TypingFinger from "../../../components/animation/TypingFinger";
import { RootState } from "../../../app/store";
import { TutorialStage } from "../../../interfaces/tutorial";

interface ProfileBattleDataProps {
  currentHp: number;
  maxHp: number;
  currentMana: number;
  maxMana: number;
  heroName: string;
  isDeckOpen: boolean;
  openDeck: () => void;
  closeDeck: () => void;
  cardsAtDeck: CardProps[];
  cardsAtHand: CardProps[];
  endTurnEndpoint: any;
  currentDefense: number;
  isDraggingCards: boolean;
  isFirstCardPlayed: boolean;
  startTurn: boolean;
}
export const ProfileBattleData = (props: ProfileBattleDataProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { playSound } = useSoundService();
  const { activeDiv } = useUtils();
  const [isClick, setIsClick] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isPlayerTurn, setIsPlayerTurn] = useState<boolean>(true)
  const tutorialSave = useSelector(
    (state: RootState) => state.tutorialSave.tutorialSave.save
  );




  // const [currentHp, setCurrentHp] = useState(15);
  // const [currentMana, setCurrentMana] = useState(2);
  // const [maxHp, setMaxHp] = useState(20);
  // const [maxMana, setMaxMana] = useState(8);
  // const [isDeckOpen, setIsDeckOpen] = useState(false);
  const {
    isDeckOpen,
    maxHp,
    maxMana,
    currentMana,
    currentHp,
    heroName,
    cardsAtDeck,
    cardsAtHand,
    endTurnEndpoint,
    currentDefense,
    isDraggingCards,
    isFirstCardPlayed,
    startTurn,
  } = props;

  useEffect(() => {
    if(startTurn){
      setIsPlayerTurn(true)
    }
  }, [startTurn])

  //console.log("cards At Deck", cardsAtDeck);
  //console.log("cardsAtHand", cardsAtHand);
  // const openDeck = () => {
  //   setIsDeckOpen(true);
  // };
  //
  // const closeDeck = () => {
  //   setIsDeckOpen(false);
  // };
  //console.log('PROFILE DATA CURRENT HP', currentHp);
  //console.log('ACTIVE DIV!!!!!!!', activeDiv);
  useEffect(() => {
    if (cardsAtHand.length === 0 && !isClick && tutorialSave?.enemyId === 0) {
      setIsShow(true);
    }
    if (isClick) {
      setIsShow(false)
    }
  }, [cardsAtHand.length, isClick])
  return (
    <div
      className={`bottom-0 fixed px-4 pt-5 pb-8 w-full bg-[#1b1b1d] flex flex-nowrap items-center ${isDeckOpen ? 'z-[11]' : 'z-[1]'}`}>
      {isDeckOpen && (
        <CardsModal cardsAtDeck={cardsAtDeck} closeDeck={props.closeDeck} />
      )}
      <div
        className="absolute top-0 left-0 w-full h-[6px] bg-gradient-to-b from-[#4f3e35] to-[#302620] border-y border-black shadow-[0_0.4px_0_1px_#131314]"></div>
      <div className="flex-1 mr-2">
        {heroName ? (
          <div className="text-white font-[900] text-[16px] leading-[1] mb-1">{heroName}</div>
        ) : null}
        <div className="relative text-white h-[16px] bg-[#312e2b] text-center text-xl rounded-[3px] leading-3 w-full">
          {activeDiv === "/battle" && (
            <HpBarAnimation currentHp={currentHp} maxHp={maxHp} />
          )}
        </div>
      </div>

      <div className="flex text-white bg-[#12140e] text-center text-[14px] rounded-[3px]
        leading-4 border border-black py-2.5 px-1.5 mr-2 flex-shrink-0">
        <img src={require("../../../assets/images/mana-icon.png")} className="w-[16px] h-[16px] mr-1" alt="" />
        {currentMana}
      </div>

      <button
        className="flex-none mr-3 p-1.5 bg-[#837E60] border border-black shadow-[inset_0_-3px_0_0_#302b20]
        rounded text-center text-white"
        onClick={() => {
          if (isPlayerTurn) {
            props.openDeck();
            playSound('button');
          }
        }}
      >
        <div className="text-[18px] leading-[1] text-stroke-small">
          {cardsAtDeck.length}
        </div>
      </button>
      <PopupButton type="green" className="flex-shrink-0"
        onClick={() => {
          if (!isDraggingCards) return;
          endTurnEndpoint();
          setIsClick(true)
          playSound('button');
          setIsPlayerTurn(false)
          if (cardsAtHand.length > 0) {
            playSound('discard');
          }
        }}>
        {isShow && <TypingFinger />}
        {isFirstCardPlayed && isDraggingCards && <div className="btn-pulse transition animate-opacityTransition
          absolute z-[-1] w-full h-full left-0 top-0"></div>}
        {t('tutorial.endTurn')}
      </PopupButton>
    </div>
  );
};
