import { RequestWebsocket } from "../interfaces/wsInterfaces";
import { generateRequestId } from "../utils/generateRequestId";
import { sendRequestAndGetResponse } from "../Websocket/websocketInit";

export const claimPremiumReferralTask = async (params: {
  clientId: string;
  referralTaskId: number;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "user_claimReferralTask",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const claimRegularReferralTask = async (params: {
  clientId: string;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "user_claimRegularReferrals",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};
export const claimRevShare = async (params: {
  clientId: string;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-farm",
    lang: "en",
    method: "referralReward_claimAllSum",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getRewardsToClaim = async (params: {
  clientId: string;
}): Promise<any> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "user_getRewardsToClaim",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getUserReferrals = async (params: {
  clientId: string;
}): Promise<any[]> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "user_getMyReferrals",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getInfluencerStatus = async (params: {
  clientId: string;
}): Promise<{
  revShare?: number;
  isInfluencer: boolean;
  isPending: boolean;
}> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "influencer_getMyStatus",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const getInfluencerRewards = async (params: {
  clientId: string;
}): Promise<{
  totalBalance: number,
  toClaimBalance: number,
}> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "influencer_getInfluencerRewards",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const claimAllAvailableInfluencerRewards = async (params: {
  clientId: string;
}): Promise<boolean> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "influencer_claimAllAvailable",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};

export const createRequestForInfluencer = async (params: {
  clientId: string;
  link: string,
  about: string,
}): Promise<{
  revShare?: number;
  isInfluencer: boolean;
  isPending: boolean;
}> => {
  const request: RequestWebsocket = {
    agent: "battler-tg-user",
    lang: "en",
    method: "influencer_createRequest",
    id: generateRequestId(),
    auth: {
      type: "mobileApp",
      deviceId: params.clientId,
    },
    params,
  };
  try {
    const result = await sendRequestAndGetResponse(request);
    return result?.value;
  } catch (e) {
    console.error((e as Error).message);
    return (e as Error).message as unknown as any;
  }
};
