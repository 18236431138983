import { useSelector } from "react-redux";
import { PopupButton } from "../../components/PopupButton";
import { HeroesList } from "../../containers";
import PageTransition from "../../containers/Router/components/PageTransition";
import { TutorialFarmStage } from "../../interfaces/tutorial";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { RootState } from "../../app/store";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";
import {useTelegram} from "../../hooks/useTelegram";

export const Heroes = () => {
  const backgroundHeroes = require("../../assets/images/backgroundHeroes.jpg");
  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );
  const { userId } = useTelegram();

  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      <PageTransition>
         {farmTutorialSave?.stage === TutorialFarmStage.clickOnHeroes && (
          <div className="absolute inset-0 bg-black bg-opacity-50 z-[10] pointer-events-none"></div>
            )}
        <main
          className={`w-full absolute min-h-full bg-[#201B18] left-0 top-0 flex flex-col overflow-hidden`}
          style={{
            background: `linear-gradient(to top, rgba(32, 27, 24, 1), rgba(32, 27, 24, 1), rgba(32, 27, 24, 1), rgba(32, 27, 24, 1), rgba(32, 27, 24, 0.4), rgba(32, 27, 24, 0.1), transparent), url(${backgroundHeroes}) no-repeat center/cover`,
          }}
        >
          <HeroesList/>
        </main>
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
