// Типи помилок
export enum ErrorType {
  websocket,
  runtime,
}

// Інтерфейс для помилки вебсокету
export interface WebSocketErrorData {
  requestId: string;
  requestParams: any;
  responseData?: any;
}

// Інтерфейс для рантайм помилок
export interface RuntimeErrorData {
  message: string;
  stack?: string;
}

// Загальний тип помилки
export interface ReportableError {
  clientId: string;
  type: ErrorType;
  errorData: WebSocketErrorData | RuntimeErrorData;
}
