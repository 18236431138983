import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestBlock, SocialBlock } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { saveFarmTutorialProgress } from "../../app/features/farmTutoralSlice";
import { AppDispatch, RootState, store } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import { TutorialFarmSave, TutorialFarmStage } from "../../interfaces/tutorial";
import { fetchIslands } from "../../app/features/islandsSlice";
import { setSelectedIsland } from "../../app/features/selectedIsland";

export enum QuestType {
  quests,
  achievements,
  explore,
}

export const QuestsList = ({
  quests,
  socialTask,
  activeTab,
  tutorQuest,
}: {
  quests: any[];
  socialTask: any[];
  activeTab: QuestType;
  tutorQuest?: any;
}) => {
  const { t } = useTranslation();

  console.log("quests", quests);
  console.log("socialTask", socialTask);

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();
  const [mainBuildingLevel, setMainBuildingLevel] = useState(0);
  const islands = useSelector((state: RootState) => state.islands.islands);
  let selectedIsland = useSelector(
    (state: RootState) => state.selectedIsland.selectedIsland
  );

  useEffect(() => {
    dispatch(fetchIslands(`${userId}`));
    if (!selectedIsland && islands.length > 0) {
      store.dispatch(setSelectedIsland({ island: islands[0] }));
      selectedIsland = islands[0];
    }
  }, []);

  useEffect(() => {
    if (selectedIsland) {
      const mainBuilding = selectedIsland.buildings.find(
        (building) => building.id === 1
      );
      if (mainBuilding) {
        setMainBuildingLevel(mainBuilding.lvl || 1);
      }
    }
  }, [selectedIsland]);

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  console.log("Tutorial stage in quests:", farmTutorialSave?.stage!);

  return (
    <>
      <div
        className={`flex flex-col justify-start items-center pb-4 gap-2 overflow-y-auto overflow-x-hidden max-h-full 
        ${
          farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList
            ? "z-[20]"
            : ""
        } `}
      >
        {/* Transparent bg for clickOnQuestList stage */}
        {farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList && (
          <div
            className={`absolute w-full h-full bg-transparent z-[100]`}
            onClick={() => {
              updateSave({
                stage: TutorialFarmStage.clickOnCompletedQuest,
              });
            }}
          ></div>
        )}

        {activeTab === QuestType.quests && (
          <>
            {socialTask.map((quest, index) => (
              <React.Fragment key={quest.id}>
                <SocialBlock quest={quest} />
              </React.Fragment>
            ))}
            {quests.map((quest, index) => {
              return (
                <React.Fragment key={quest.questId.value}>
                  <QuestBlock
                    quest={quest}
                    mainBuildingLevel={mainBuildingLevel}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}
        {/* {activeTab === QuestType.explore && (
          <>
            {socialTask.filter((task) => task.isFriends === false).length ? (
              <div className="flex justify-center items-center text-center text-white text-2xl font-normal leading-normal">
                $KITSU
              </div>
            ) : null}
            {socialTask
              .filter((task) => task.isFriends === false)
              .map((quest, index) => (
                <React.Fragment key={quest.id}>
                  <SocialBlock quest={quest} />
                  {index !== quests.length - 1 && (
                    <div className="w-full min-h-px bg-[#584d3c]"></div>
                  )}
                </React.Fragment>
              ))}
            {socialTask.filter((task) => task.isFriends === true).length ? (
              <div className="flex justify-center items-center h-10 text-center text-white text-2xl font-normal  leading-normal">
                Kitsune's Friends
              </div>
            ) : null}
            {socialTask
              .filter((task) => task.isFriends === true)
              .map((quest, index) => (
                <React.Fragment key={quest.id}>
                  <SocialBlock quest={quest} />
                  {index !== quests.length - 1 && (
                    <div className="w-full min-h-px bg-[#584d3c]"></div>
                  )}
                </React.Fragment>
              ))}
          </>
        )} */}
      </div>
    </>
  );
};
