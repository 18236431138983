import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WebSocketState {
  webSocket: WebSocket | null;
  status: 'idle' | 'connecting' | 'connected' | 'failed';
  error: string | null;
}

const initialState: WebSocketState = {
  webSocket: null,
  status: 'idle',
  error: null,
};

const webSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setWebSocket(state, action: PayloadAction<WebSocket>) {
      state.webSocket = action.payload;
    },
    setWebSocketStatus(state, action: PayloadAction<'idle' | 'connecting' | 'connected' | 'failed'>) {
      state.status = action.payload;
    },
    clearWebSocket(state) {
      state.webSocket = null;
    },
    setWebSocketError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.status = 'failed';
    },
  },
});

export const { setWebSocket, clearWebSocket, setWebSocketStatus, setWebSocketError } = webSocketSlice.actions;

export default webSocketSlice.reducer;
