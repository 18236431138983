import {Card} from "../../../components/Card";
import {PopupButton} from "../../../components/PopupButton";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const SelectCards = ({
  count,
  cards,
  mainCard,
  setSelectedCardHandler
}: {
  count: number,
  cards: {id: {value: number}}[],
  mainCard: {cardId: number, cardUid: string}|null,
  setSelectedCardHandler: ({cardId, cardUid, drawCardId}: {cardId: number, cardUid: string, drawCardId: number}) => void;
}) => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<number|null>(null);

  const handleCardClick = (id: number) => {
    if (selectedCard === id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(id);
    }
  };

  const handleConfirm = () => {
    if (selectedCard !== null && mainCard) {
      setSelectedCardHandler({
        cardId: mainCard.cardId,
        cardUid: mainCard.cardUid,
        drawCardId: selectedCard
      });
    }
  };

  return (
    <div className="absolute bg-[black]/[0.7] w-full h-full z-[100] left-0 top-0 flex items-center justify-center">
      <div>
        <div className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto"></div>
        <div className="w-[200px] bg-gradient-to-r from-transparent via-gray-400/[0.2] to-traansparent mx-auto
          text-[#FFEFCB] text-center text-[14px] p-2">{t('battleSelectCards', {count})}</div>
        <div className="w-[200px] bg-gradient-to-r from-transparent via-orange-500 to-traansparent bg-opacity-50
          h-[1px] mx-auto mb-[30px]"></div>
        <div className="flex">
          {cards.map((card, index) => (
            <div key={index} className={`relative ${selectedCard === card.id.value ? 'orange-glow' : ''}`}
              onClick={() => handleCardClick(card.id.value)}>
              <Card id={card.id.value} lvl={1} uid={(card.id.value+index).toString()}/>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <PopupButton
            type={selectedCard !== null ? 'blue' : 'gray'}
            size="medium"
            className="mt-[30px] mx-auto"
            onClick={handleConfirm}
            disabled={selectedCard === null}>
            <div className="px-6">{t('confirm')}</div>
          </PopupButton>
        </div>
      </div>
    </div>
  );
}
