import {
  RequestWebsocket,
  ResponseWebsocket,
} from "../interfaces/wsInterfaces";
import { WebsocketState } from "../enums/wsEnums";
import { generateRequestId } from "../utils/generateRequestId";
import {
  setWebSocket,
  setWebSocketError,
  setWebSocketStatus,
} from "../app/features/webSocketSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../app/store";
import { API_URL } from "../config";
import * as Sentry from "@sentry/react";
import { setError } from "../app/features/errorSlice";
import { ErrorReportingService } from "../services/errorReportingService";
import { ErrorType } from "../interfaces/error";

const MAX_RECONNECT_ATTEMPTS = 3; // Максимальна кількість спроб підключення

export const openWebSocket = createAsyncThunk<
  void,
  void,
  { rejectValue: string }
>("webSocket/openWebSocket", async (_, { dispatch, rejectWithValue }) => {
  let attempt = 0;

  const connect = async (): Promise<void> => {
    attempt++;
    try {
      dispatch(setWebSocketStatus("connecting"));
      const webSocket = new WebSocket("wss://" + API_URL);

      await new Promise<void>((resolve, reject) => {
        webSocket.onopen = () => {
          // console.log("WebSocket connection opened");
          dispatch(setWebSocket(webSocket));
          dispatch(setWebSocketStatus("connected"));
          resolve();
        };

        webSocket.onerror = (event) => {
          console.error("WebSocket connection error", event);
          reject(new Error("Failed to open WebSocket connection"));
        };

        webSocket.onclose = (event) => {
          console.warn("WebSocket connection closed", event);
          reject(new Error(`WebSocket closed with code ${event.code}`));
        };
      });
    } catch (e) {
      console.error(`Error on attempt ${attempt} to open WebSocket:`, e);
      if (attempt < MAX_RECONNECT_ATTEMPTS) {
        console.log(`Retrying to connect... Attempt ${attempt + 1}`);
        await connect();
      } else {
        dispatch(setWebSocketStatus("failed"));
        dispatch(setWebSocketError(e as string));
        throw e;
      }
    }
  };

  try {
    await connect();
  } catch (e) {
    console.error("Final error in openWebSocket after retries:", e);
    Sentry.captureException(e);
    return rejectWithValue(e as string);
  }
});

export const sendRequestAndGetResponse = async (request: RequestWebsocket) => {
  try {
    //@ts-ignore
    const webSocket: WebSocket = store.getState().webSocket.webSocket;
    if (!webSocket || webSocket.readyState !== WebsocketState.open) {
      store.dispatch(setWebSocketStatus("failed"));
      store.dispatch(setWebSocketError("WebSocket is not open"));
      throw new Error("WebSocket is not open");
    }

    const response = await new Promise<ResponseWebsocket>((resolve, reject) => {
      const messageHandler = (event: MessageEvent) => {
        try {
          const parsedMSG = JSON.parse(event.data) as ResponseWebsocket;
          if (request.id === parsedMSG.result.request.id) {
            webSocket.removeEventListener("message", messageHandler);
            if (parsedMSG.result.response.type === "Left") {
              ErrorReportingService.reportError({
                type: ErrorType.websocket,
                errorData: {
                  requestId: request.id,
                  requestParams: request.params,
                  responseData: parsedMSG.result.response.value,
                },
                clientId: request.params.clientId ?? "No ClientId",
              });
              reject(new Error(parsedMSG.result.response.value));
            } else {
              resolve(parsedMSG);
            }
          }
        } catch (error) {
          webSocket.removeEventListener("message", messageHandler);
          reject(error);
        }
      };

      const errorHandler = (error: Event) => {
        webSocket.removeEventListener("message", messageHandler);
        webSocket.removeEventListener("error", errorHandler);
        reject(new Error("WebSocket error occurred"));
      };

      webSocket.addEventListener("message", messageHandler);
      webSocket.addEventListener("error", errorHandler);

      try {
        webSocket.send(JSON.stringify(request));
      } catch (sendError) {
        webSocket.removeEventListener("message", messageHandler);
        webSocket.removeEventListener("error", errorHandler);
        reject(sendError);
      }
    });

    return response.result.response;
  } catch (error) {
    console.error("ERROR at sendRequestAndGetResponse:", error);
    Sentry.captureException(error);
    store.dispatch(setError(error as Error));
    throw error;
  }
};

export const wsListener = (webSocket: WebSocket) => {
  try {
    console.log("Setting up WebSocket listener", webSocket);
    const messageHandler = (msg: MessageEvent) => {
      console.log("Received message", msg);
      // Обробка повідомлень, які не є відповіддю на конкретні запити
    };

    webSocket.addEventListener("message", messageHandler);

    // При розмонтуванні або закритті WebSocket приберіть обробник
    const cleanup = () => {
      webSocket.removeEventListener("message", messageHandler);
    };

    return cleanup;
  } catch (e) {
    console.error("Error in wsListener:", e);
    Sentry.captureException(e);
  }
};
