import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useUtils } from "../../../../../utils/navigateTo";
import { useSoundService } from "../../../../../utils/soundService";
import { AppDispatch, RootState } from "../../../../../app/store";
import { TutorialFarmSave, TutorialFarmStage } from "../../../../../interfaces/tutorial";
import { saveFarmTutorialProgress } from "../../../../../app/features/farmTutoralSlice";
import { useTelegram } from "../../../../../hooks/useTelegram";

interface HandleBackButtonProps {
  onClick?: () => void;
}

export const HandleBackButton: React.FC<HandleBackButtonProps> = ({
  onClick,
}) => {
  const { goBack } = useUtils();
  const { playSound } = useSoundService();

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  const { navigateTo } = useUtils();

  const dispatch = useDispatch<AppDispatch>();

  const { userId } = useTelegram();

  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  }

  return (
    <div
      className="relative flex items-center justify-center w-8 h-10 cursor-pointer rounded-md z-[2]"
      onClick={() => {
        playSound("button");
        if(farmTutorialSave?.stage === TutorialFarmStage.upgradeHero){
          updateSave({
            stage: TutorialFarmStage.repair
          })
          navigateTo("/island")
          return null
        }
        if (onClick) {
          onClick();
        } else {
          goBack();
        }
      }}
    >
      <svg
        width="40"
        height="48"
        viewBox="0 0 40 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_7740_38545" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 3C40 1.34315 38.6569 0 37 0H3C1.34315 0 0 1.34314 0 3V8.76995V30.6953V37.1977C0 38.3734 0.68675 39.4407 1.75672 39.9279L18.7567 47.6692C19.5466 48.0289 20.4534 48.0289 21.2433 47.6692L38.2433 39.9279C39.3132 39.4407 40 38.3734 40 37.1977V30.6953V8.76995V3Z"
          />
        </mask>
        <g filter="url(#filter0_ii_7740_38545)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 3C40 1.34315 38.6569 0 37 0H3C1.34315 0 0 1.34314 0 3V8.76995V30.6953V37.1977C0 38.3734 0.68675 39.4407 1.75672 39.9279L18.7567 47.6692C19.5466 48.0289 20.4534 48.0289 21.2433 47.6692L38.2433 39.9279C39.3132 39.4407 40 38.3734 40 37.1977V30.6953V8.76995V3Z"
            fill="#C3B996"
          />
        </g>
        <path
          d="M1.75672 39.9279L2.17115 39.0179L1.75672 39.9279ZM38.2433 39.9279L37.8288 39.0179L38.2433 39.9279ZM18.7567 47.6692L18.3423 48.5793L18.7567 47.6692ZM21.2433 47.6692L21.6577 48.5793L21.2433 47.6692ZM3 1H37V-1H3V1ZM1 8.76995V3H-1V8.76995H1ZM1 30.6953V8.76995H-1V30.6953H1ZM1 37.1977V30.6953H-1V37.1977H1ZM19.1712 46.7592L2.17115 39.0179L1.3423 40.838L18.3423 48.5793L19.1712 46.7592ZM37.8288 39.0179L20.8288 46.7592L21.6577 48.5793L38.6577 40.838L37.8288 39.0179ZM39 30.6953V37.1977H41V30.6953H39ZM39 8.76995V30.6953H41V8.76995H39ZM39 3V8.76995H41V3H39ZM-1 37.1977C-1 38.7653 -0.0843331 40.1884 1.3423 40.838L2.17115 39.0179C1.45783 38.693 1 37.9815 1 37.1977H-1ZM38.6577 40.838C40.0843 40.1884 41 38.7653 41 37.1977H39C39 37.9815 38.5422 38.693 37.8288 39.0179L38.6577 40.838ZM37 1C38.1046 1 39 1.89543 39 3H41C41 0.790861 39.2091 -1 37 -1V1ZM18.3423 48.5793C19.3954 49.0589 20.6046 49.0589 21.6577 48.5793L20.8288 46.7592C20.3023 46.9989 19.6977 46.9989 19.1712 46.7592L18.3423 48.5793ZM3 -1C0.790862 -1 -1 0.790859 -1 3H1C1 1.89543 1.89543 1 3 1V-1Z"
          fill="black"
          mask="url(#path-1-inside-1_7740_38545)"
        />
        <mask id="path-3-inside-2_7740_38545" fill="white">
          <path d="M1.73828 3.35742C1.73828 2.56351 2.38187 1.91992 3.17578 1.91992H36.8225C37.6164 1.91992 38.26 2.56351 38.26 3.35742V33.8905C38.26 34.4522 37.9328 34.9625 37.4223 35.1969L20.599 42.9212C20.2182 43.096 19.7801 43.096 19.3993 42.9212L2.57597 35.1969C2.06548 34.9625 1.73828 34.4522 1.73828 33.8905V3.35742Z" />
        </mask>
        <path
          d="M20.599 42.9212L21.0162 43.83L20.599 42.9212ZM19.3993 42.9212L18.9821 43.83L19.3993 42.9212ZM2.57597 35.1969L2.99323 34.2881L2.57597 35.1969ZM37.4223 35.1969L37.0051 34.2881L37.4223 35.1969ZM3.17578 2.91992H36.8225V0.919922H3.17578V2.91992ZM37.26 3.35742V33.8905H39.26V3.35742H37.26ZM2.73828 33.8905V3.35742H0.738281V33.8905H2.73828ZM37.0051 34.2881L20.1817 42.0124L21.0162 43.83L37.8396 36.1057L37.0051 34.2881ZM19.8166 42.0124L2.99323 34.2881L2.15871 36.1057L18.9821 43.83L19.8166 42.0124ZM20.1817 42.0124C20.0658 42.0656 19.9325 42.0656 19.8166 42.0124L18.9821 43.83C19.6277 44.1264 20.3706 44.1264 21.0162 43.83L20.1817 42.0124ZM0.738281 33.8905C0.738281 34.843 1.29309 35.7082 2.15871 36.1057L2.99323 34.2881C2.83786 34.2168 2.73828 34.0615 2.73828 33.8905H0.738281ZM37.26 33.8905C37.26 34.0615 37.1604 34.2168 37.0051 34.2881L37.8396 36.1057C38.7052 35.7082 39.26 34.843 39.26 33.8905H37.26ZM36.8225 2.91992C37.0641 2.91992 37.26 3.1158 37.26 3.35742H39.26C39.26 2.01123 38.1687 0.919922 36.8225 0.919922V2.91992ZM3.17578 0.919922C1.82959 0.919922 0.738281 2.01123 0.738281 3.35742H2.73828C2.73828 3.1158 2.93416 2.91992 3.17578 2.91992V0.919922Z"
          fill="#FFF2C8"
          mask="url(#path-3-inside-2_7740_38545)"
        />
        <path
          d="M23.5 12L14.5 21L23.5 30"
          stroke="#534737"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_ii_7740_38545"
            x="0"
            y="0"
            width="40"
            height="50.814"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.875" />
            <feGaussianBlur stdDeviation="2.875" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_7740_38545"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-2.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_innerShadow_7740_38545"
              result="effect2_innerShadow_7740_38545"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
