import React from "react";
import { PopupButton } from "../../../../../components/PopupButton";
import { useUtils } from "../../../../../utils/navigateTo";
import { motion } from "framer-motion"; // Імпортуємо motion

export const UpgradeCard = ({ image, title, buttonText, link }: any) => {
  const { navigateTo } = useUtils();

  // Визначаємо варіанти анімацій для картки
  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1, // Збільшуємо тривалість до 1с
        ease: "easeOut", // Використовуємо м’яке прискорення
      },
    },
  };

  return (
    <motion.div
      className="flex justify-center items-center relative overflow-hidden flex-shrink-0 w-full max-w-md mx-auto"
      style={{
        background:
          "radial-gradient(circle, rgba(220, 134, 3, 0.3) 0%, rgba(220, 134, 3, 0) 35%)",
      }}
      variants={cardVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Верхня рамка */}
      <div
        className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
        style={{
          background:
            "radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)",
        }}
      ></div>

      {/* Нижня рамка */}
      <div
        className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
        style={{
          background:
            "radial-gradient(circle at center, rgba(220, 134, 3, 0.5), rgba(220, 134, 3, 0) 70%)",
        }}
      ></div>

      <div className="flex gap-5 py-2 justify-between items-center w-full px-4">
        {/* Зображення */}
        <div className="w-10 h-10 relative bg-[#18191a] flex justify-center items-center rounded-sm border border-[#01516c]">
          <div className="w-[36.25px] h-[36.25px] overflow-hidden bg-gradient-to-b from-[#5a60ff] to-[#3e43c1] rounded-sm shadow border border-[#2e78ab]">
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Текст */}
        <div className="text-center text-[#ffefcb] text-sm font-[700] leading-4 flex-1 text-stroke-small">
          {title}
        </div>

        {/* Кнопка */}
        <PopupButton type="green" onClick={() => { navigateTo(link) }}>
          <div className="text-center text-[#ffefcb] text-sm font-[700] leading-4">
            {buttonText}
          </div>
        </PopupButton>
      </div>
    </motion.div>
  );
};
