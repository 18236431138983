import { CardsConfig } from "../endpoints/mock";

interface ConfigCard {
  id: { value: number };
  cardType: { value: number };
  cost: { value: number };
  stars: {
    value: Array<{
      skills: {
        value: Array<{
          effectType: { value: number };
          value: { value: number };
          target: { value: string };
          animationCount: { value: number };
        }>;
      };
    }>;
  };
}

export const mixCards = (
  configCards: ConfigCard[],
  localCards: CardsConfig[]
): CardsConfig[] => {
  return localCards.map((localCard) => {
    const configCard = configCards.find(
      (card) => card.id.value === localCard.id
    );

    if (!configCard) {
      // console.warn(`No config found for local card ID ${localCard.id}`);
      return {
        ...localCard,
        levels: [],
      } as CardsConfig;
    }

    const cardType = configCard.cardType.value;
    return {
      ...localCard,
      data: {
        ...localCard.data,
        type: cardType,
        // priceManna: configCard.cost.value,
        stars: configCard.stars.value.length,
      },
      levels: configCard.stars.value.map((star) =>
      {
        return star.skills.value.map((effect) => ({
          value: effect.value.value,
          target: effect.target.value,
          effectType: effect.effectType.value,
          animationCount: effect.animationCount.value,
        }));
      }),
    } as unknown as CardsConfig;
  });
};
