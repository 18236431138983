import React, { useState, useEffect, useRef } from 'react';
import {useTranslation} from "react-i18next";

interface TimerComponentProps {
  startValue: number;
  onComplete: () => void;
}

export const TimerComponent: React.FC<TimerComponentProps> = ({ startValue, onComplete }) => {
  const { t } = useTranslation();
  const LOCAL_STORAGE_KEY = 'activeTimerValue';

  const [time, setTime] = useState<number>(() => {
    const savedTime = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedTime && +savedTime !== 0 ? +savedTime : startValue;
  });

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if ((!timerRef.current || time === 0) && startValue > 0) {

      setTime(startValue);

      if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
        localStorage.setItem(LOCAL_STORAGE_KEY, String(time));
      }

      timerRef.current = setInterval(() => {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timerRef.current!);
            timerRef.current = null;
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            onComplete();
            return 0;
          }
          const newTime = prevTime - 1;
          localStorage.setItem(LOCAL_STORAGE_KEY, String(newTime));
          return newTime;
        });
      }, 1000);
    }
  }, [startValue]);

  useEffect(() => {
    if (timerRef.current || time <= 0) return;

    if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
      localStorage.setItem(LOCAL_STORAGE_KEY, String(time));
    }

    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current!);
          timerRef.current = null;
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          onComplete();
          return 0;
        }
        const newTime = prevTime - 1;
        localStorage.setItem(LOCAL_STORAGE_KEY, String(newTime));
        return newTime;
      });
    }, 1000);

    return () => {
      if (timerRef.current && time <= 0) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [time, onComplete]);

  return (
    <div className="w-full text-center text-[#ffefcb] text-[18px] font-semibold leading-none">
      {t('errors.pleaseWait', {time})}
    </div>
  );
};
