import { useEffect, useState } from "react";
import { TutorialFarmSave, TutorialFarmStage, TutorialSave } from "../../../interfaces/tutorial";

const PuslAnimation = (tutorialSave: TutorialFarmSave | TutorialSave | undefined) => {
  const [shadowStyle, setShadowStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (tutorialSave?.stage === TutorialFarmStage.clickOnRewards ||
      tutorialSave?.stage === TutorialFarmStage.clickOnQuests
    ) {
      const interval = setInterval(() => {
        setShadowStyle((prev) =>
          prev.boxShadow === "0 0 15px rgba(222, 129, 24, 0.9)"
            ? { boxShadow: "0 0 35px rgba(222, 129, 24, 0.8)" }
            : { boxShadow: "0 0 15px rgba(222, 129, 24, 0.9)" }
        );
      }, 500);

      return () => clearInterval(interval);
    }
  }, [tutorialSave?.stage]);

  // console.log(tutorialSave?.stage)
  return shadowStyle
}

export default PuslAnimation
