import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoom } from "../../../../../app/features/selectedRoom";
import { PopupWindow } from "../../../../../components/PopupWindow";
import { PopupButton } from "../../../../../components/PopupButton";
import { useTranslation } from "react-i18next";
import { UpgradeArrow } from "../../../../Room";
import {
  GoldIco,
  GrayIco,
  KitsuIco,
  StoneIco,
  TimerIco,
} from "../../../../../layout/components/HeaderFarm/components/ResourceCard";
import { RootState, store } from "../../../../../app/store";
import { FarmRoom } from "../../../../../interfaces/farm";
import { upgradeIslandRoom } from "../../../../../app/features/islandsSlice";
import { setResource } from "../../../../../app/features/resourcesSlice";
import { Resources } from "../../../../../enums/resources";
import {
  PopupUpgradeTable,
  UpgradeDataRow,
} from "../../../../../components/PopupUpgradeTable";
import React, { useEffect, useState } from "react";

import { upgradeBuilding } from "../../../../../endpoints/farmMock";
import { useTelegram } from "../../../../../hooks/useTelegram";
import { defineCountString } from "../../../../../utils/farmConfigParser";
import { OfferResourceProps } from "../../../../../pages/Shop/components/OfferResources";
import { Offer, OfferProps } from "../../../../../pages/Shop/components/Offer";
import { ConfigOffer, OfferType } from "../../../../../mock/offers";
import { usePurchase } from "../../../../../hooks/usePurchase";
import { HandleBackButton } from "../../../../../layout/components/HeaderCave/components";
import { PurchaseModal } from "../../../../../components/PurchaseModal";
import { SuccessOverlay } from "../../../../../components/SuccessOverlay";
import { useSessionStorage } from "@uidotdev/usehooks";
import { AnimatePresence, motion } from "framer-motion";
import { useRoom } from "../../../Room/useRoom";
import { RoomStatus } from "../../../../../enums/buildingStatus";

export const BuildingPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const { userId } = useTelegram();
  const [requiredConditions, setRequiredConditions] = useState<
    {
      condition: string;
      met: boolean;
      buildingId?: number;
      lvl?: number;
    }[]
  >([]);

  const currentRoom = useSelector((state: RootState) => state.selectedRoom);

  const selectedIsland = useSelector(
    (state: RootState) => state.selectedIsland.selectedIsland
  );

  console.log("ISLAND", selectedIsland);

  const resources = useSelector(
    (state: RootState) => state.resources.resources
  );

  const selectedRoom = buildingConfigs!.filter(
    (v) => v.id === currentRoom?.buildingId
  )?.[0];

  let maxLvl = Math.max(
    ...(selectedRoom?.lvlInfo.map((info) => info.lvl) || [0])
  );

  if (selectedRoom?.id === 3 || selectedRoom?.id === 7) {
    maxLvl = 5;
  }

  const isMaxLevel = currentRoom.lvl === maxLvl;

  const closePopup = () => {
    dispatch(setSelectedRoom({ id: -1 }));
  };

  const upgradeAvailableInfo = () => {
    const conditions = selectedRoom?.lvlInfo.find(
      (v) => v.lvl === currentRoom.lvl!
    )?.condition;

    let requiredConditions: string[] = [];

    if (!conditions) {
      return requiredConditions;
    }

    const islandsFromRedux = store.getState().islands;
    const allBuildings: FarmRoom[] = islandsFromRedux.islands
      .map((island) => island.buildings.filter((v) => v?.buildingId))
      .flat(1);

    conditions.forEach((condition) => {
      const allBuildingsLvlForCondition = allBuildings
        .filter((v) => v.buildingId === condition.buildingId)
        .map((building) => (building.lvl ? building.lvl : 0))
        .flat(1);
      const maxLvl = Math.max(...allBuildingsLvlForCondition);

      if (!maxLvl || condition.neededLvl > maxLvl) {
        requiredConditions.push(
          "\n" +
            t(
              buildingConfigs!.find((v) => v.id === condition.buildingId)
                ?.title || "Unknown"
            ) +
            " " +
            t("farm.upgrade.requirements", { lvl: condition.neededLvl })
        );
      }
    });

    return requiredConditions;
  };

  useEffect(() => {
    const upgradeConditions = () => {
      if (!selectedRoom) {
        return [];
      }

      const levelInfo = selectedRoom.lvlInfo.find(
        (v) => v.lvl === currentRoom.lvl!
      );

      if (!levelInfo) {
        return [];
      }

      const conditions = levelInfo.condition;

      let requiredConditions: {
        condition: string;
        met: boolean;
        buildingId?: number;
        lvl?: number;
        status?: number;
      }[] = [];

      const islandsFromRedux = store.getState().islands;
      const allBuildings: FarmRoom[] = islandsFromRedux.islands
        .map((island) => island.buildings.filter((v) => v?.buildingId))
        .flat(1);

      if (conditions) {
        conditions.forEach((condition) => {
          const allBuildingsLvlForCondition = allBuildings
            .filter((v) => v.buildingId === condition.buildingId)
            .map((building) => building.lvl || 0);

          const maxLvl = Math.max(...allBuildingsLvlForCondition);

          const buildingTitle =
            buildingConfigs!.find((v) => v.id === condition.buildingId)
              ?.title || "Unknown";

          const buildingStatus = allBuildings
            .filter((v) => v.buildingId === condition.buildingId)
            .map((building) => building.status || undefined)[0];

          const conditionMet =
            maxLvl >= condition.neededLvl &&
            buildingStatus !== RoomStatus.building;

          requiredConditions.push({
            condition: ` ${t("farm.upgrade.requirements", {
              lvl: condition.neededLvl,
            })} ${t(buildingTitle)}`,
            met: conditionMet,
            buildingId: condition.buildingId,
            lvl: maxLvl,
            status: buildingStatus,
          });
        });
      }

      return requiredConditions;
    };

    const newConditions = upgradeConditions();

    setRequiredConditions(newConditions);
  }, [selectedRoom, currentRoom, resources, t, buildingConfigs]);

  const allConditionsMetNormal = requiredConditions.every((row) => row.met);

  const levelInfo = selectedRoom?.lvlInfo.find(
    (v) => v.lvl === currentRoom.lvl!
  );

  const nextLevelInfo = selectedRoom?.lvlInfo.find(
    (v) => v.lvl === currentRoom.lvl! + 1
  ); 

  const maxTimeMs = {
    nowMaxTime: levelInfo?.maxTimeMs,
    newMaxTime: nextLevelInfo?.maxTimeMs
  }

  const createCost = levelInfo?.createCost || 0;
  const goldCost = levelInfo?.kitsune || 0;

  const currentKitsu =
    resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;

  const currentStone =
    resources.find((v) => v.resourceType === Resources.stone)?.value || 0;

  const canUpgradeGold = allConditionsMetNormal && currentKitsu >= goldCost;

  const canUpgradeNormal = allConditionsMetNormal && currentStone >= createCost;

  const upgradeNormalCondition = {
    met: canUpgradeNormal,
    currentAmount: currentStone,
    costAmount: createCost,
  };

  const onClickUpgrade = async (isGoldUpgrade = false) => {
    const levelInfo = selectedRoom?.lvlInfo.find(
      (v) => v.lvl === currentRoom.lvl!
    );

    const currentKitsu =
      resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;

    const currentStone =
      resources.find((v) => v.resourceType === Resources.stone)?.value || 0;

    let requiredConditions = upgradeAvailableInfo();

    if (requiredConditions.length > 0) {
      window.alert(requiredConditions.join(" "));

      return;
    }

    if (isGoldUpgrade) {
      await upgradeBuilding({
        islandId: selectedIsland?.id!,
        buildingId: currentRoom.id,
        clientId: userId,
        instabuild: true,
      });
    } else {
      await upgradeBuilding({
        islandId: selectedIsland?.id!,
        buildingId: currentRoom.id,
        clientId: userId,
        instabuild: false,
      });
    }

    dispatch(
      setResource({
        resource: isGoldUpgrade ? Resources.kitsu : Resources.stone,
        newValue: isGoldUpgrade
          ? currentKitsu - levelInfo?.kitsune!
          : currentStone - levelInfo?.createCost!,
      })
    );

    closePopup();
  };
  

  const upgradeData = () => {
    if (selectedRoom) {
      let upgradeData: UpgradeDataRow[] = [];
      console.log('SELECTED ROOM', selectedRoom)
      const currentLvl = selectedRoom.lvlInfo.find(
        (v) => v.lvl === currentRoom.lvl
      );
      console.log('CURRENT ROOM', selectedRoom)

      const upgradedLvl = selectedRoom.lvlInfo.find(
        (v) => v.lvl === currentRoom.lvl! + 1
      )?.upgradeData!;

      currentLvl?.upgradeData?.forEach((v, i) => {
        let row = {
          title: 'ERROR IN containers/Farm/IslandWindow/components/BuildingPopup 288',
          nowValue: 0,
          newValue: 0
        }
        switch(i){
          case 0:{
            row = {
              title: v.title,
              nowValue: v.value*720,
              newValue: upgradedLvl[i].value*720
            };
            break;
          }
          case 1:{
            row = {
              title: v.title,
              nowValue: v.value,
              newValue: upgradedLvl[i].value,
            };
            break;
          }
        }
        upgradeData.push(row);
      });

      return upgradeData;
    } else {
      return [];
    }
  };

  const WindowHeader = () => {
    return (
      <div className="flex  w-full items-center justify-start gap-4 mb-2">
        <div className="flex w-[140px] h-[140px] -mt-10">
          {selectedRoom && (
            <img
              src={require(`../../../../../assets/images/buildings/build${selectedRoom?.id}.png`)}
              alt="Building"
            />
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-white text-[20px] text-stroke-small">
            {t(selectedRoom?.title).toUpperCase()}
          </p>
          <div className="flex flex-row items-center justify-center gap-1">
            <p className="text-white text-[17px] text-stroke-small">
              Lvl. {currentRoom?.lvl}
            </p>
            {!isMaxLevel && <UpgradeArrow direction="right" />}
            <p className="text-[#f6a000] text-base font-black leading-none">
              {isMaxLevel ? `MAX` : ` Lvl. ${currentRoom!.lvl! + 1}`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  function msToTime(duration: any) {
    if (!duration) {
      return;
    }
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }
  const preventTimeBug = 15000;

  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const {
    offers: { variables: allOffers },
  } = appConfig;

  const kitsuneStoreOffers = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.kitsune
  );

  const stoneStoreOffers = allOffers.filter(
    (offer: ConfigOffer) =>
      offer.offerType.value === OfferType.stoneOffer &&
      offer.offerId.value === 9
  );

  const requiredAmount = goldCost - currentKitsu;

  const selectedAmount = Math.min(
    ...kitsuneStoreOffers
      .flatMap((offer: ConfigOffer) =>
        offer.items.value.map((item) => item.amount.value)
      )
      .filter((amount: number) => amount >= requiredAmount)
  );

  const selectedKitsuneOffer: ConfigOffer = kitsuneStoreOffers.find(
    (offer: ConfigOffer) =>
      offer.items.value.some((item) => item.amount.value === selectedAmount)
  );

  const mappedKitsuneStoreOffer: OfferProps = {
    id: selectedKitsuneOffer.offerId.value,
    resourceList: selectedKitsuneOffer.items.value.map(
      (item, index): OfferResourceProps => {
        return {
          resourceType: item.rewardType.value,
          resourceId: item.rewardId.value,
          isEnabled: index === 0 ? true : false,
          layout: "vertical",
          amount: defineCountString(item.amount.value),
        };
      }
    ),
    offerType: selectedKitsuneOffer.offerType.value,
    type: "gold",
    label: "full_top",
    layout: "full",
    price: defineCountString(selectedKitsuneOffer.stars.value),
    deadline: "January 31, 2025",
  };

  const mappedStoneStoreOffer: OfferProps[] = stoneStoreOffers.map(
    (offer: ConfigOffer): OfferProps => {
      return {
        id: offer.offerId.value,
        resourceList: offer.items.value.map(
          (item, index): OfferResourceProps => {
            return {
              resourceType: item.rewardType.value,
              resourceId: item.rewardId.value,
              isEnabled: index === 0 ? true : false,
              layout: "vertical",
              amount: defineCountString(item.amount.value),
            };
          }
        ),
        offerType: offer.offerType.value,
        type: "gold",
        label: "full_top",
        layout: "full",
        price: defineCountString(offer.stars.value),
        deadline: "January 31, 2025",
      };
    }
  );
  // const selectedKitsuneOffer1 = mappedKitsuneStoreOffer.find((offer:) =>
  //   offer.items.value.some(item => item.amount.value === selectedKitsuneOffer.)
  // );

  // mappedKitsuneStoreOffer[]
  const stoneOffer = mappedStoneStoreOffer[0];
  const [showKitsuneOffer, setKitsuneShowOffer] = useState(false);
  const [showStoneOffer, setStoneShowOffer] = useState(false);

  const handleNoKitsunePromoteBuyPack = () => {
    setKitsuneShowOffer(true);
  };

  const handleNoStonePromoteBuyPack = () => {
    setStoneShowOffer(true);
  };

  const [isBuilderBusy, setIsBuilderBusy] = useSessionStorage(
    "isBuilderBusy",
    false
  );
  const [isVisible, setIsVisible] = useState(false);
  const variants = {
    hidden: { opacity: 0, y: 50 }, // Початковий стан: прозорість 0, зміщення вниз
    visible: { opacity: 1, y: 0 }, // Кінцевий стан: прозорість 1, без зміщення
    exit: { opacity: 0, y: 0 }, // Вихідний стан: прозорість 0, зміщення вниз
  };

  return (
    <div className="absolute w-full h-full flex justify-center items-center min-w-full max-w-full min-h-full max-h-full z-[999]">
      <div
        onClick={closePopup}
        className="absolute z-40 h-full w-full bg-[rgba(0,0,0,0.5)]"
      />
      <PopupWindow onClickClose={closePopup} headerElement={<WindowHeader />}>
        {isMaxLevel ? (
          <div className="w-full p-10 text-center text-[#f6a000] text-[28px] font-normal leading-7">
            You have reached the
            <br />
            highest level
          </div>
        ) : (
          <div className="w-full">
            <PopupUpgradeTable
              upgradeData={upgradeData()}
              requiredConditions={requiredConditions}
              upgradeNormalCondition={upgradeNormalCondition}
              maxTimeMs={maxTimeMs}
            />
          </div>
        )}

        {isMaxLevel ? (
          <div></div>
        ) : (
          <div className="flex justify-center items-center py-8 gap-4 w-full">
            {/* Золота Кнопка Оновлення */}
            <PopupButton
              type={allConditionsMetNormal ? "gold" : "gray"}
              disabled={!allConditionsMetNormal}
              onClick={
                allConditionsMetNormal
                  ? currentKitsu >= goldCost
                    ? () => onClickUpgrade(true)
                    : handleNoKitsunePromoteBuyPack
                  : undefined
              }
            >
              <div className="flex flex-col w-full items-center p-1 h-full gap-1">
                <div className="flex flex-row justify-center items-center gap-[2px]">
                  <p className="text-center text-white text-sm font-normal leading-[14px]">
                    {t("farm.upgrade.title")}
                  </p>
                </div>
                <div className="flex flex-row justify-center items-center gap-[2px]">
                  <div className="w-5 h-5 flex justify-center items-center">
                    <KitsuIco />
                  </div>

                  <p className="text-white text-sm font-normal leading-[14px]">
                    {goldCost}
                  </p>
                </div>
              </div>
            </PopupButton>

            <PopupButton
              type={allConditionsMetNormal ? "green" : "gray"}
              disabled={!allConditionsMetNormal}
              onClick={
                isBuilderBusy
                  ? () => {
                      setIsVisible(true);
                      setTimeout(() => {
                        setIsVisible(false);
                      }, 1000);
                    }
                  : allConditionsMetNormal
                  ? currentStone >= createCost
                    ? () => onClickUpgrade(false)
                    : handleNoStonePromoteBuyPack
                  : undefined
              }
            >
              <div className="flex  flex-col w-full items-center p-1 h-full gap-1">
                <div className="flex flex-row justify-center items-center gap-[2px]">
                  <p className="text-center text-white text-sm font-normal leading-[14px]">
                    {t("farm.upgrade.title")}
                  </p>
                </div>
                <div className="flex flex-row justify-center items-center gap-[2px]">
                  <div className="w-5 h-5 flex justify-center items-center">
                    <TimerIco />
                  </div>

                  <p className="text-white text-sm font-normal leading-[14px]">
                    {msToTime(
                      selectedRoom?.lvlInfo.find(
                        (lvl) => lvl.lvl === currentRoom?.lvl! + 1
                      )?.buildingTimeMs! + preventTimeBug
                    )}
                  </p>
                </div>
                <AnimatePresence>
                  {isVisible && (
                    <motion.div
                      className="absolute -top-14 p-2 overflow-hidden flex-shrink-0 z-[999] bg-red-700"
                      style={{
                        background:
                          "radial-gradient(circle, rgb(167 0 0 / 80%) 50%, rgba(250, 186, 60, 0) 100%)",
                      }}
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      transition={{ duration: 0.2 }}
                    >
                      {/* Верхня Рамка */}
                      <div
                        className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                        style={{
                          background:
                            "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                        }}
                      ></div>

                      {/* Нижня Рамка */}
                      <div
                        className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                        style={{
                          background:
                            "radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)",
                        }}
                      ></div>

                      {/* Вміст Елемента */}
                      <div>
                        <p className="text-white">Builders are busy</p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </PopupButton>
          </div>
        )}
      </PopupWindow>
      {(showKitsuneOffer || showStoneOffer) && (
        <div className="fixed inset-0 min-w-full min-h-full z-[55]">
          {/* Чорний фоновий шар */}

          <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

          {/* Компонент Offer, який має бути поверх чорного фону */}
          <div className="absolute inset-0 z-30 flex items-center justify-center">
            {showKitsuneOffer ? (
              <Offer
                onClose={() => {
                  setStoneShowOffer(false);
                  setKitsuneShowOffer(false);
                }}
                onClick={() => confirmPurchase(mappedKitsuneStoreOffer.id)}
                offerType={mappedKitsuneStoreOffer.offerType}
                id={mappedKitsuneStoreOffer.id}
                resourceList={mappedKitsuneStoreOffer.resourceList}
                layout={mappedKitsuneStoreOffer.layout}
                type={mappedKitsuneStoreOffer.type}
                label={mappedKitsuneStoreOffer.label}
                kitsu={mappedKitsuneStoreOffer.kitsu}
                price={mappedKitsuneStoreOffer.price}
                deadline={mappedKitsuneStoreOffer.deadline}
              />
            ) : (
              <Offer
                onClose={() => {
                  setStoneShowOffer(false);
                  setKitsuneShowOffer(false);
                }}
                onClick={() => confirmPurchase(stoneOffer.id)}
                offerType={stoneOffer.offerType}
                id={stoneOffer.id}
                resourceList={stoneOffer.resourceList}
                layout={stoneOffer.layout}
                type={stoneOffer.type}
                label={stoneOffer.label}
                kitsu={stoneOffer.kitsu}
                price={stoneOffer.price}
                deadline={stoneOffer.deadline}
              />
            )}
          </div>

          <div
            className={`absolute inset-0  ${
              selectedOffer ? "z-40" : ""
            } flex items-center justify-center`}
          ></div>
        </div>
      )}
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};
