import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { TutorialStage } from "../../../interfaces/tutorial";
import { PointerHand } from "../../PointerHand";

interface TutorialBattleProps {
    draggableCard: string,
    tutorialCardIsDropped: boolean,
    tutorialCardDroppedNotToTheEnd: boolean,
    children?: React.ReactNode,
}
const TutorialBattleAnimation: React.FC<TutorialBattleProps> = (props) => {
    const { draggableCard, tutorialCardIsDropped, tutorialCardDroppedNotToTheEnd } = props;
    const tutorialSave = useSelector(
        (state: RootState) => state.tutorialSave.tutorialSave.save
    );
    return (
        <>
            {tutorialSave?.stage === TutorialStage.battle && tutorialSave?.enemyId === 0 && draggableCard && !tutorialCardIsDropped && (
                <div className="absolute flex justify-center items-center w-full h-[30%] z-[130] animate-move-to-top pointer-events-none">
                    <div className={`absolute w-[80%]  left-[65%] z-[15] object-cover `} >
                        <PointerHand state="press" />
                    </div>
                    {!props.children && <img
                        src={require("../../../assets/images/cards/Stone-1Star.png")}
                        alt=""
                        className="absolute opacity-90 left-[5%] w-[90%] inset-0 object-cover "
                    />}
                    {props.children &&
                        <div className={`absolute opacity-90 left-[5%] w-[90%] object-cover `} >
                            {props.children}
                        </div>}
                </div>
            )}
        </>
    )
}

export default TutorialBattleAnimation