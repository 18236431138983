
const TypingFinger = () =>{
    return(
        <>
            <div className="absolute w-[65%] h-full right-0">
            <img
                src={require("../../../assets/images/tutorFinger2.png")}
                alt="Typing Finger"
                className="absolute inset-0 object-cover z-[15] w-full animate-idle"
            />
            <img
                src={require("../../../assets/images/tutorFinger.png")}
                alt="Idle Finger"
                className="absolute inset-0 object-cover z-[10] mt-[10%] w-full animate-typing"
            />
            </div>
        </>
    )
}

export default TypingFinger;