import { useLocation, useNavigate } from "react-router-dom";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useEffect } from "react";

export function useUtils() {
  const [activeDiv, setActiveDiv] = useSessionStorage("page", "/");
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (divLink: string) => {
    setActiveDiv(divLink);
    navigate(divLink);
  };

  useEffect(() => {
    setActiveDiv(location.pathname);
  }, [location.pathname]);

  const goBack = () => {
    const currentUrl = window.location.pathname;

    const lastSlashIndex = currentUrl.lastIndexOf("/");
    if (lastSlashIndex === -1 || lastSlashIndex === 0) {
      navigate("/island");
      return;
    }

    const newUrl = currentUrl.substring(0, lastSlashIndex);
    navigate(newUrl);
  };

  return {
    goBack,
    navigateTo,
    activeDiv,
  };
}
