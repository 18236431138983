import { useEffect, useState } from "react";
import {
  InviteText,
  InvitesButtons,
  FriendEarn,
  InviteBonuses,
  PremiumBonuses,
} from "../../components";
import { FriendsList } from "../../containers";
import { useTelegram } from "../../hooks/useTelegram";
import PageTransition from "../../containers/Router/components/PageTransition";
import { RefreshFriends } from "../../containers/FriendsList/components/RefreshFriends";
import {
  getInfluencerRewards,
  getInfluencerStatus,
  getRewardsToClaim,
  getUserReferrals,
} from "../../endpoints/refEndpoints";
import { motion } from "framer-motion";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { useTranslation } from "react-i18next";
import { BonusTable } from "../../components/InviteBonuses/components/InviteBonuse/BonusTable";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";

const headerVariants = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

// Варіанти для першого внутрішнього елемента (зліва)
const leftElementVariants = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, y: 0, opacity: 1 },
};

// Варіанти для другого внутрішнього елемента (справа)
const rightElementVariants = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, y: 0, opacity: 1 },
};

export const Friends = () => {
  const { tg } = useTelegram();
  const { t } = useTranslation();
  useEffect(() => {
    if (tg) {
      tg.setHeaderColor("#1f1c1a");
    }
  }, [tg]);

  const [premiumReferralTask, setPremiumReferralTask] = useState([]);
  const [premiumReferralsAmount, setPremiumReferralsAmount] = useState(0);
  const [regularReferralsAvailable, setRegularReferralsAvailable] = useState(0);
  const [referralsEarn, setReferralsEarn] = useState<number>(0);
  const [userEarnFromRefs, setUserEarnFromRefs] = useState<number>(0);
  const [userFriends, setUserFriends] = useState<any>([]);
  const [multiplier, setMultiplier] = useState(0);
  const { userId } = useTelegram();
  const [isUserInfluencer, setisUserInfluencer] = useState(false);
  const [isUserInfluencerRequestPending, setIsUserInfluencerRequestPending] =
    useState(false);
  const [revShareInfluencer, setrevShareInfluencer] = useState<
    number | undefined
  >(0);
  const [toClaimBalanceInfluencer, setToClaimBalanceInfluencer] = useState(0);
  const [totalInfluencerBalance, setTotalInfluencerBalance] = useState(0);
  const [expandPopup, setExpandPopup] = useState<boolean>(false)

  const handleExpand = () => {
    setExpandPopup(!expandPopup)
  }

  const getReward = async () => {
    const data = await getRewardsToClaim({
      clientId: userId,
    });

    const userFriendsData = await getUserReferrals({
      clientId: userId,
    });

    if (userFriendsData) {
      setUserFriends(userFriendsData);
    }

    const userInfluencer = await getInfluencerStatus({
      clientId: userId,
    });

    if (userInfluencer) {
      setisUserInfluencer(userInfluencer.isInfluencer);

      setIsUserInfluencerRequestPending(userInfluencer.isPending);
      setrevShareInfluencer(userInfluencer.revShare);

      if (userInfluencer.isInfluencer) {
        const resultReward = await getInfluencerRewards({
          clientId: userId,
        });

        if (resultReward) {
          setToClaimBalanceInfluencer(resultReward.toClaimBalance);
          setTotalInfluencerBalance(resultReward.totalBalance);
        }
      }
    }

    if (data) {
      setMultiplier(data.multiplier);
      setRegularReferralsAvailable(data.regularReferralsAvailable);
      setPremiumReferralsAmount(data.premiumReferralsAmount);
      setPremiumReferralTask(data.premiumTasks);
      setReferralsEarn(data.referralsEarnedCum);
      setUserEarnFromRefs(data.revShare);
    }
  };

  useEffect(() => {
    getReward();
  }, []);


  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      <PageTransition>
        {/* Верхній заголовок */}
        <div className="relative z-20 flex justify-start items-center w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
          <div className="absolute top-[1px] pl-3">
            <HandleBackButton />
          </div>
          <div className="text-white text-2xl font-black font-['Sofia Sans Semi Condensed'] leading-normal pl-16 text-shadow-black-sm">
            {t("rewards.mainTitle")}
          </div>
        </div>


        {expandPopup &&
          <motion.div
            className={`absolute flex justify-center items-center w-full h-full bg-[rgba(0,0,0,0.5)] z-[1000]`} >
            <BonusTable onClick={handleExpand} />
          </motion.div>}


        {/* Темний фон */}
        <div className="absolute w-full h-full bg-black"></div>{" "}
        {/* Замість fixed використовується relative */}
        {/* Основний контент */}
        <div className="relative w-full h-[calc(100%-40px)]  z-5 flex flex-col items-center justify-center pt-[50px]">
          <div
            className="absolute top-3 flex justify-center items-center z-10 "
            style={{ width: "calc(100% + 6rem)" }}
          >
            <div className="relative flex justify-center items-center w-full h-[55px]">
              <img
                src={require("../../assets/images/rewardDungeon.png")}
                className="absolute w-full h-full"
                alt="Reward Dungeon"
              />
              <div className="absolute top-3 text-center text-white text-base font-black leading-none text-shadow-black-sm">
                Invite Premium Friend to get Reward
              </div>
            </div>
          </div>

          <div className="w-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white h-full">
            <PremiumBonuses
              tasks={premiumReferralTask}
              eligibleClaim={false}
              premiumReferralsAmount={premiumReferralsAmount}
              onClaim={getReward}
            />

            <div className="relative w-full bg-[#201b18] rounded-[1px] p-3 shadow-inner-sm-black overflow-auto h-[calc(100%-157px)]">
              <InviteBonuses
                multiplier={multiplier}
                referralsEarn={referralsEarn}
                userEarnFromRefs={userEarnFromRefs}
                regularReferralsAvailable={regularReferralsAvailable}
                onClaim={getReward}
                isUserInfluencer={isUserInfluencer}
                isUserInfluencerRequestPending={isUserInfluencerRequestPending}
                revShareInfluencer={revShareInfluencer}
                toClaimBalanceInfluencer={toClaimBalanceInfluencer}
                totalInfluencerBalance={totalInfluencerBalance}
                expandPopup={handleExpand}
              />

              <FriendsList userFriends={userFriends} />

              <InvitesButtons />
            </div>
          </div>
        </div>
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
