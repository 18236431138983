import { useTranslation } from "react-i18next";
import { PopupButton } from "../../../components/PopupButton";
import { heroesMap } from "../../../utils/mapping";
import { UpgradeCard } from "./components";
import { motion } from "framer-motion";

export const LoosePage = ({
  onClickBack,
  onClickRetry,
  enemyId,
}: {
  onClickBack: () => void;
  onClickRetry: () => void;
  enemyId?: number;
}) => {
  const { t } = useTranslation();

  const upgradeCards = [
    {
      image: heroesMap[0].image,
      title: "Upgrade your hero",
      buttonText: "Upgrade",
      link: "/heroes",
    },
    {
      image: heroesMap[0].image,
      title: "Upgrade Tier",
      buttonText: "Upgrade",
      link: "/heroes",
    },
    {
      image: require("../../../assets/images/buildings/build1.png"),
      title: "Upgrade Shrine",
      buttonText: "Upgrade",
      link: "/island",
    },
    // Add more items as needed
  ];

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="fixed inset-0 z-10 flex flex-col justify-center items-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="relative flex justify-center items-center pb-4 px-2 max-w-[420px] mx-4 mt-[30px]"
        variants={itemVariants}
      >
        <div className="absolute bottom-full flex justify-center w-60 h-32 -mb-[50px]">
          <img
            className="z-[2]"
            src={require("../../../assets/images/skullLose.png")}
            alt=""
          />
        </div>

        <img
          src={require("../../../assets/images/afterBattleBar.png")}
          alt=""
        />

        <div className="absolute bottom-11 top-6 left-8 right-8 flex flex-col">
          <div className="relative w-full rounded-[3px] border border-[#18191a] bg-[#554837] p-[2px] mb-[5px] flex-1 h-0">
            <div className="rounded-[2px] border border-[#18191a] h-full overflow-hidden">
              <img
                src={require("../../../assets/images/looseBackground.jpg")}
                className="w-full h-full object-cover scale-125"
                alt=""
              />
            </div>
          </div>

          <div className="relative px-2 py-1.5 bg-[rgba(18,20,14,0.5)] rounded-[3px] border border-[#18191a]
            flex justify-start items-center gap-1 flex-wrap max-w-full">
            <div className="text-white text-[12px] break-words leading-[1.2] text-stroke-small">
              {t('battleLooseDescription')}
            </div>
          </div>
        </div>
      </motion.div>

      {enemyId && enemyId > 2 ? (
        <>
          <motion.div
            className="text-center text-white text-[20px] text-stroke-small font-[500] leading-tight pb-2"
            variants={itemVariants}
            transition={{
              delay: 1,
            }}
          >
            {t('looseALot')}
          </motion.div>

          <div className="flex flex-col gap-2">
            {upgradeCards.map((card, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                transition={{
                  delay: 1,
                }}>
                <UpgradeCard
                  image={card.image}
                  title={card.title}
                  buttonText={card.buttonText}
                  link={card.link}
                />
              </motion.div>
            ))}
          </div>
        </>
      ) : null}

      <div className="w-full px-14 flex justify-center items-center mt-4 gap-4">
        <motion.div variants={itemVariants}>
          <PopupButton type="blue" size="medium">
            <div
              onClick={onClickRetry}
              className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]"
            >
              {enemyId === 2 ? t("next") : t("retry")}
            </div>
          </PopupButton>
        </motion.div>

        {enemyId && enemyId > 2 ? (
          <motion.div variants={itemVariants}>
            <PopupButton type="green" size="medium">
              <div
                onClick={onClickBack}
                className="cursor-pointer text-center text-[#ffefcb] text-[18px] font-[800] leading-[18px] min-w-[110px]"
              >
                {t("back")}
              </div>
            </PopupButton>
          </motion.div>
        ) : null}
      </div>
    </motion.div>
  );
};
