
interface TutorialTooltipProps {
    value?: string,
    scale?: string,
    type?: 'left' | 'right' | 'top' | 'bottom' | 'bottom_left' | 'bottom_right' | 'top_left' | 'top_right',
    fontSize?: string
}

export const TutorialTooltip = (props: TutorialTooltipProps) => {

    const {
        value = "",
        scale = "100",
        type = "right",
        fontSize = "16px"
    } = props

    const types = {
        right: {
            0: "right-[-10px] top-[50%] translate-y-[-50%] rotate-90",
        },
        bottom: {
            0: "bottom-[-10px]  left-[50%] translate-x-[-50%] rotate-180",
        },
        left: {
            0: "left-[-10px] top-[50%] translate-y-[-50%] -rotate-90",
        },
        top: {
            0: "top-[-10px]  left-[50%] translate-x-[-50%] ",
        },
        bottom_left:{
            0: "bottom-[-10px]  left-0  rotate-180",
        },
        bottom_right:{
            0: "bottom-[-10px] right-0 rotate-180",
        },
        top_right:{
            0: "top-[-10px]  right-0"
        },
        top_left:{
            0: "top-[-10px]  left-0"
        }
    }    

    return (
        <>
            <div className={` flex justify-center items-center relative w-[230px] h-[50px] bg-white rounded-md scale-[${scale}] text-[${fontSize}]  `} >
                <div className={`text-center`} >
                    {value}
                </div>
                <div 
                className={`absolute w-[1.5em] h-[1.2em] bg-white z-10 ${types[type][0]} `}
                style={{clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)'}} ></div>
            </div>
        </>)
}