import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { App, FallbackComponent } from "./containers";
import "./i18n/config";
import { ScrollProvider } from "./utils/ScrollContext";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { APP_ENV } from "./config";
import {PopupButton} from "./components/PopupButton";
import {ErrorComponent} from "./components";

if (
  typeof window !== "undefined" &&
  window.matchMedia &&
  !window.matchMedia("(prefers-color-scheme: dark)").addEventListener
) {
  const matchMediaPrototype = Object.getPrototypeOf(
    window.matchMedia("(prefers-color-scheme: dark)")
  );

  matchMediaPrototype.addEventListener = function (
    event: string,
    callback: (e: Event) => void
  ) {
    if (event === "change") {
      this.addListener(callback);
    }
  };

  matchMediaPrototype.removeEventListener = function (
    event: string,
    callback: (e: Event) => void
  ) {
    if (event === "change") {
      this.removeListener(callback);
    }
  };
}

const AppWrapper = () => {
  const [renderKey, setRenderKey] = useState(0);
  const [orientation, setOrientation] = React.useState("");
  const [orientationScale, setOrientationScale] = React.useState(1);
  const [jsError, setJsError] = React.useState<any>(null);
  const globalWindow = window as Window;

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && APP_ENV && APP_ENV === "production") {
        setRenderKey((prevKey) => prevKey + 1);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      let isPortrait;
      let orientationScale;

      if ("orientation" in window.screen && window.screen.orientation) {
        isPortrait = window.screen.orientation.type.includes("portrait");
      } else if ("orientation" in window) {
        // for IOS
        const orientation = window.orientation;
        isPortrait = orientation === 0 || orientation === 180;
      } else {
        isPortrait = globalWindow.innerHeight >= globalWindow.innerWidth;
      }

      orientationScale = isPortrait
        ? 1
        : +(window.innerHeight / window.innerWidth).toFixed(2);

      setOrientation(isPortrait ? "portrait" : "landscape");
      setOrientationScale(orientationScale);

      if (APP_ENV && ["production", "stage"].includes(APP_ENV) && !window.location.hash.includes("input")) {
        setRenderKey((prevKey) => prevKey + 1);
      }
    };

    checkOrientation();

    window.addEventListener("resize", checkOrientation);
    window.addEventListener("orientationchange", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("orientationchange", checkOrientation);
    };
  }, []);

  return (
    <>
      {orientation === "landscape" && (
        <div className="absolute flex justify-center items-center w-full h-full bg-[#000000] z-[9999]">
          <img
            src={require(`./assets/images/rotate-example.jpg`)}
            className="w-full max-w-[250px] h-auto"
            alt=""
          />
        </div>
      )}
      <Sentry.ErrorBoundary fallback={({error, resetError, componentStack, eventId}) => {
        return (<ErrorComponent jsError={{error, resetError, componentStack, eventId}}/>)
      }}>
        <App key={renderKey}/>
      </Sentry.ErrorBoundary>
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <ScrollProvider>
        <AppWrapper />
      </ScrollProvider>
    </PersistGate>
  </Provider>
);
