import { useSelector } from "react-redux";
import { BattleSaves, DungeonTypeConfig, StageType } from "../endpoints/mock";
import { RootState } from "../app/store";
import { useMemo } from "react";

interface IsDungeonFinishStageArgs {
  actualSaves: BattleSaves | null;
}

export const useIsDungeonFinishStage = ({
  actualSaves,
}: IsDungeonFinishStageArgs): boolean => {
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  return useMemo(() => {
    if (!Object.keys(appConfig).length) return false;
    const dungeonConfig: DungeonTypeConfig[] = appConfig.dungeonCards.variables;

    if (!actualSaves) {
      console.warn("actualSave is null.");
      return false;
    }

    if (!dungeonConfig) {
      console.warn("Dungeon configuration is not available.");
      return false;
    }

    const dungeon = dungeonConfig[actualSaves.dungeonId - 1];
    if (!dungeon) {
      console.warn(`Dungeon with ID ${actualSaves.dungeonId} not found.`);
      return false;
    }

    if (!dungeon.stages || !dungeon.stages.value) {
      console.warn(`Stages not found for dungeon ID ${actualSaves.dungeonId}.`);
      return false;
    }

    const stagesLength = dungeon.stages.value.length;
    if (stagesLength === 0) {
      console.warn(`No stages found for dungeon ID ${actualSaves.dungeonId}.`);
      return false;
    }

    const lastStage = dungeon.stages.value[stagesLength - 1];
    if (!lastStage) {
      console.warn(
        `Last stage not found for dungeon ID ${actualSaves.dungeonId}.`
      );
      return false;
    }
    return actualSaves.enemyId === lastStage.enemyId?.value;
  }, [actualSaves, appConfig]);
};
