import { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { Task } from "./components";
import { PremiumTask } from "../../mock/offers";
import { claimPremiumReferralTask } from "../../endpoints/refEndpoints";
import { useTelegram } from "../../hooks/useTelegram";

export const PremiumBonuses = ({
  tasks,
  onClaim,
}: {
  tasks: PremiumTask[];
  taskDescription?: any;
  eligibleClaim: boolean;
  status?: number;
  claimFunc?: () => void;
  premiumReferralsAmount: number;
  onClaim: () => void;
}) => {
  const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

  const [confettiActive, setConfettiActive] = useState<boolean>(false);
  const [confettiDimensions, setConfettiDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: window.innerWidth, height: window.innerHeight });

  const { userId, tg } = useTelegram();

  const hasUserInteracted = useRef(false);

  const handleClaim = async (id: number) => {
    try {
      await claimPremiumReferralTask({
        clientId: userId,
        referralTaskId: id,
      });

      // Викликаємо функцію зворотного виклику після успішного заклеймлення
      onClaim();

      // Активуємо конфетті
      setConfettiActive(true);

      // Вимірюємо розміри вікна для конфетті
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      // Вимикаємо конфетті через 3 секунди
      setTimeout(() => {
        setConfettiActive(false);
      }, 3000);
    } catch (error) {
      console.error("Error claiming task:", error);
    }
  };

  useEffect(() => {
    // Таймер для автоматичного відкриття тултіпа через 3 секунди
    const initialTimer = setTimeout(() => {
      if (
        !hasUserInteracted.current &&
        openTooltipIndex === null &&
        tasks.length > 0
      ) {
        setOpenTooltipIndex(0); // Відкрити тултіп для першого елемента
        // Таймер для автоматичного закриття тултіпа через 5 секунд
        const closeTimer = setTimeout(() => {
          hasUserInteracted.current = true;
          setOpenTooltipIndex(null);
        }, 5000);

        // Очищення таймера закриття при зміні стану
        return () => clearTimeout(closeTimer);
      }
    }, 3000);

    return () => clearTimeout(initialTimer);
  }, [openTooltipIndex, tasks.length]);

  useEffect(() => {
    const handleResize = () => {
      setConfettiDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSetTooltipIndex = (index: number | null) => {
    if (index !== null) {
      hasUserInteracted.current = true; // Встановлюємо, що користувач взаємодіяв
    }
    setOpenTooltipIndex(index);
  };


  return (
    <div className="flex flex-col justify-center items-center pb-4">
      {confettiActive && (
        <Confetti
          className="z-[999]"
          width={confettiDimensions.width}
          height={confettiDimensions.height}
          numberOfPieces={300}
          recycle={false}
          gravity={0.3}
        />
      )}
      <div
        className="w-full overflow-x-auto"
        style={{ width: "calc(100% + 2rem)" }}
      >
        <div className="whitespace-nowrap ">
          {/* Прогрес-бар поверх тасок */}

          {tasks.map((task, index) => (
            <>
              <Task
                taskLength={tasks.length}
                index={index}
                task={task}
                isReadyToClaim={task.isReadyToClaim}
                handleClaim={handleClaim}
                isClaimed={task.isClaimed}
                isOpen={openTooltipIndex === index}
                setOpenTooltipIndex={handleSetTooltipIndex}
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
