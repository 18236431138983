import React, { useEffect, useState } from "react";
import { PopupButton } from "../../../../components/PopupButton";
import { HeaderCave } from "../../../../layout/components/HeaderCave";
import { OpenRecruitCards, RecruitCardsModal } from "./components";
import { KeysIco } from "../../../../layout/components/HeaderFarm/components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getBalance } from "../../../../endpoints/farmMock";
import { useTelegram } from "../../../../hooks/useTelegram";
import { openLootbox } from "../../../../endpoints/lootBoxEndpoints";
import { LootboxId, LootBoxOpenResult } from "../../../../interfaces/lootBotx";
import { Resources } from "../../../../enums/resources";
import {
  DisplayData,
  handleLootBoxResult,
} from "../../../../utils/lootBoxHandler";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Offer, OfferProps } from "../../../../pages/Shop/components/Offer";
import { ConfigOffer, OfferType } from "../../../../mock/offers";
import { OfferResourceProps } from "../../../../pages/Shop/components/OfferResources";
import { defineCountString } from "../../../../utils/farmConfigParser";
import { usePurchase } from "../../../../hooks/usePurchase";
import { PurchaseModal } from "../../../../components/PurchaseModal";
import { SuccessOverlay } from "../../../../components/SuccessOverlay";

export const RecruitModal = ({ close }: { close: () => void }) => {
  const [recruitCardsModalOpen, setRecruitCardsModalOpen] = useState(false);
  const [recruitHero, setRectuirHero] = useState(false);

  const [rewards, setRewards] = useState<DisplayData[]>([]);

  const { userId } = useTelegram();

  const resources = useSelector(
    (state: RootState) => state.resources.resources,
  );

  const currentKeys =
    resources.find((v) => v.resourceType === Resources.keys)?.value || 0;

  const [openLootBox, setOpenLootBox] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const handleRecruiteHero = async (amount: number) => {
    setOpenLootBox(true);
    setRectuirHero(true);

    const result = await openLootbox({
      clientId: userId,
      lootBoxId: LootboxId.Keys,
      amount: amount,
    });

    if (result) {
      const displayData: DisplayData[] = result
        .map(handleLootBoxResult)
        .filter((data): data is DisplayData => data !== null);

      await getBalance({
        clientId: userId,
      });

      setRewards(displayData);
    }
  };

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    offers: { variables: allOffers },
  } = appConfig;

  const heroStoreOffer = allOffers.filter(
    (offer: ConfigOffer) =>
      offer.offerType.value === OfferType.keysOffer &&
      offer.offerId.value === 8,
  );

  const mappedHeroStoreOffer: OfferProps[] = heroStoreOffer.map(
    (offer: ConfigOffer): OfferProps => {
      return {
        id: offer.offerId.value,
        resourceList: offer.items.value.map(
          (item, index): OfferResourceProps => {
            return {
              resourceType: item.rewardType.value,
              resourceId: item.rewardId.value,
              isEnabled: index === 0 ? true : false,
              layout: "vertical",
              amount: defineCountString(item.amount.value),
            };
          },
        ),
        offerType: offer.offerType.value,
        type: "gold",
        label: "corner",
        layout: "slim",
        price: defineCountString(offer.stars.value),
        deadline: "January 31, 2025",
      };
    },
  );

  const offer = mappedHeroStoreOffer[0];

  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  return (
    <>
      <div className="z-20 absolute w-full h-full left-0 top-0">
        <div className="absolute w-full z-[12] top-0">
          <HeaderCave keys={currentKeys} pageName="Summon" onClick={close} />
        </div>
        {!recruitHero ? (
          <div className="absolute inset-0 bg-[url('./assets/images/summon-bg.png')] bg-cover bg-center bg-no-repeat flex flex-col overflow-hidden">
            {offer && currentKeys < 10 ? (
              <div className="relative top-[76px] w-[90%] right-5 left-5 ">
                <Offer
                  onClick={() => {
                    confirmPurchase(offer.id);
                  }}
                  offerType={offer.offerType}
                  key={0}
                  id={offer.id}
                  resourceList={offer.resourceList}
                  layout={offer.layout}
                  type={offer.type}
                  label={offer.label}
                  kitsu={offer.kitsu}
                  price={offer.price}
                  deadline={offer.deadline}
                />
              </div>
            ) : null}

            {showOffer && (
              <div className="fixed inset-0 min-w-full min-h-full z-20">
                {/* Чорний фоновий шар */}

                <div className="absolute inset-0 bg-black bg-opacity-50 z-20 pointer-events-none"></div>

                <div className="absolute inset-0 z-30 flex items-center justify-center">
                  <Offer
                    onClose={() => setShowOffer(false)}
                    onClick={() => confirmPurchase(offer.id)}
                    offerType={offer.offerType}
                    id={offer.id}
                    resourceList={offer.resourceList}
                    layout={"full"}
                    type={offer.type}
                    label={"full_top"}
                    kitsu={offer.kitsu}
                    price={offer.price}
                    deadline={offer.deadline}
                  />
                </div>
              </div>
            )}

            <div
              className="absolute top-[168px] right-5 w-[40px] h-[40px] border-[4px] border-[#00141b] rounded-full flex
              items-center justify-center z-[2] bg-[rgba(1,1,1,0.2)]"
              // onClick={() => setRecruitCardsModalOpen(true)}
              data-tooltip-id="HeroSummon"
              data-tooltip-content="Summon x more time to receive a new Hero or Hero shard."
            >
              <img
                src={require("../../../../assets/images/recruit-cards-icon.png")}
                className="w-[18px] mt-[-3px]"
                alt=""
              />
              <div className="absolute -bottom-1 text-white text-center text-[12px] leading-[1] text-stroke-small">
                0/50
              </div>
              <Tooltip
                id="HeroSummon"
                place="bottom-end"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  border: "1px solid #000000",
                  borderRadius: "8px",
                  padding: "8px",
                  textAlign: "center",
                  maxWidth: "160px",
                  wordWrap: "break-word",
                }}
              />
            </div>

            <div className="absolute bottom-0 w-full h-[45vh] bg-gradient-to-b from-transparent via-[rgba(27,27,27,0.97)] to-[#201b18]"></div>
            <div className="z-[2] absolute w-full bottom-0 pb-14">
              <div className="flex justify-center">
                <div className="relative mr-3">
                  <div className="flex items-center justify-center">
                    <div
                      className={`ml-1 font-medium text-base ${currentKeys >= 1 ? "text-white" : "text-[#E44B39]"}`}
                    >
                      1
                    </div>
                    <div className="w-5 h-5">
                      <KeysIco />
                    </div>
                  </div>
                  <PopupButton
                    type={"green"}
                    onClick={
                      currentKeys >= 1
                        ? () => handleRecruiteHero(1)
                        : () => setShowOffer(true)
                    }
                    width="auto"
                  >
                    <div className="leading-[1.2] text-[18px] text-stroke-small px-4">
                      Summon
                    </div>
                    {currentKeys >= 1 && (
                      <img
                        className="absolute top-[2px] right-[2px] z-50 w-3 h-3"
                        src={require("../../../../assets/images/heroes/cards/notification.png")}
                        alt="Notification"
                      />
                    )}
                  </PopupButton>
                  {/* <div className="absolute text-white text-[12px] w-full text-center text-nowrap leading-[1] mt-2.5">
                    Free in: 2d 14:56:12
                  </div> */}
                </div>
                <div>
                  <div className="flex items-center justify-center">
                    <div
                      className={`ml-1 font-medium text-base ${currentKeys >= 10 ? "text-white" : "text-[#E44B39]"}`}
                    >
                      10
                    </div>
                    <div className="w-5 h-5">
                      <KeysIco />
                    </div>
                  </div>
                  <PopupButton
                    type={"gold"}
                    onClick={
                      currentKeys >= 10
                        ? () => handleRecruiteHero(10)
                        : () => setShowOffer(true)
                    }
                    width="auto"
                  >
                    <div className="leading-[1.2] text-[18px] text-stroke-small px-4">
                      Summon
                    </div>
                    {currentKeys >= 10 && (
                      <img
                        className="absolute top-[2px] right-[2px] z-50 w-3 h-3"
                        src={require("../../../../assets/images/heroes/cards/notification.png")}
                        alt="Notification"
                      />
                    )}
                  </PopupButton>
                </div>
              </div>
            </div>
          </div>
        ) : openLootBox ? (
          <OpenRecruitCards
            cardData={rewards}
            keys={currentKeys}
            onRecruit={handleRecruiteHero}
            offer={{
              ...mappedHeroStoreOffer[0],
              label: "full_top",
              layout: "full",
            }}
          />
        ) : null}
      </div>
      {recruitCardsModalOpen && (
        <RecruitCardsModal close={() => setRecruitCardsModalOpen(false)} />
      )}
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </>
  );
};
