import React, { useState, useEffect, useRef } from "react";
import { CardProps } from "../../interfaces/card";
import { CardsConfig, CardType, EffectType, level } from "../../endpoints/mock";
import useFitText from "use-fit-text";
import { useTranslation } from "react-i18next";
import CardBuilderComponent from "./CardComponentBuilder";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import i18next from "i18next";

export const Card: React.FC<CardProps> = (props) => {
  //console.log('INIT CARD COMPONENT->->', props);
  const {t} = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cards = useSelector((state: RootState) => state.appConfig.cards);
  const [card, setCard] = useState<CardsConfig & { lvl: number }>(
    {} as unknown as CardsConfig & { lvl: number }
  );

  type ModifiedLevel = {
    animationCount?: number;
    value: number;
    target: "enemy" | "self";
    effectType: EffectType;
  };

  interface EffectDetails {
    whiteColor: "white";
    brownColor: "brown";
    greenColor: "green";
    redColor: "red";
    color: string;
    // name: string;
    enemy?: { value: number; animationCount?: number };
    self?: { value: number };
  }

  const getEffectDetails = (effect: ModifiedLevel): EffectDetails => {
    const effectMap: { [key in EffectType]: { color: string; name: string } } =
      {
        [EffectType.defense]: {color: "green", name: "Defense"},
        [EffectType.damage]: {color: "red", name: "Damage"},
        [EffectType.gainMaxHp]: {color: "#3ADC96", name: "Health"},
        [EffectType.doubleDamage]: {color: "orange", name: "Double Damage"},
        [EffectType.heal]: {color: "blue", name: "Heal"},
        [EffectType.triggeredByEnemiesAttackCard]: {
          color: "purple",
          name: "Triggered by Enemy Attack",
        },
        [EffectType.returnDamage]: {color: "pink", name: "Return Damage"},
        [EffectType.repeatableDamage]: {
          color: "red",
          name: "Repeatable Damage",
        },
        [EffectType.getCard]: {color: "yellow", name: "Get Card"},
        [EffectType.shuffle]: {color: "cyan", name: "Shuffle"},
        [EffectType.discard]: {color: "black", name: "Discard"},
        [EffectType.lessCardPerRound]: {
          color: "lightgrey",
          name: "Less Card Per Round",
        },
        [EffectType.piercingDamage]: {
          color: "red",
          name: "Piercing Damage",
        },
        [EffectType.negateAttack]: {
          color: "darkgreen",
          name: "Negate Attack",
        },
        [EffectType.damageEqualPercentCurrentHealth]: {
          color: "maroon",
          name: "Damage Equal Percent Current Health",
        },
        [EffectType.exiled]: {color: "darkpurple", name: "Exiled"},
        [EffectType.addActionPoint]: {
          color: "green",
          name: "Add Action Point",
        },
        [EffectType.copyAllFromHandByTypeAndShuffle]: {
          color: "silver",
          name: "Copy All From Hand By Type And Shuffle",
        },
        [EffectType.lessDamage]: {color: "lightred", name: "Less Damage"},
        [EffectType.additionalDamage]: {
          color: "darkorange",
          name: "Additional Damage",
        },
        [EffectType.additionalDefense]: {
          color: "lightgrey",
          name: "Additional Defense",
        },
        [EffectType.returnDamageByHandSize]: {
          color: "brown",
          name: "Return Damage By Hand Size",
        },
        [EffectType.damagePerLostHP]: {
          color: "darkred",
          name: "Damage Per Lost HP",
        },
        [EffectType.damagePerEquiped]: {
          color: "darkred",
          name: "Damage Per Equipped",
        },
        [EffectType.copyPreviousEffects]: {
          color: "grey",
          name: "Copy Previous Effects",
        },
        [EffectType.viewCardsandKeep]: {
          color: "blue",
          name: "View Cards and Keep",
        },
        [EffectType.drawCardPerUseATK]: {
          color: "green",
          name: "Draw Card Per Use ATK",
        },
        [EffectType.transformLeftmostAttackToCopy]: {
          color: "purple",
          name: "Transform Leftmost Attack To Copy",
        },
        [EffectType.shuffleTempCopiesOfAttack]: {
          color: "orange",
          name: "Shuffle Temp Copies Of Attack",
        },
        [EffectType.reduceCost]: {color: "teal", name: "Reduce Cost"},
        [EffectType.priceManna]: {color: "magenta", name: "Price Manna"},
        [EffectType.getRandomCardFromDeck]: {color: "white", name: "Random Card From Deck"},
        [EffectType.gainRandomTempAttack]: {color: "white", name: "Gain Random Temp Attack"},
        [EffectType.getRandomConfigCard]: {color: "white", name: "Random Config Card"},
        [EffectType.damagePerArtefact]: {color: "white", name: "Damage Per Artefact"},
        [EffectType.viewCard]: {color: "white", name: "View Card"},
      };

    const mappedEffect = effectMap[effect.effectType];

    if (!mappedEffect) {
      return {
        whiteColor: "white",
        brownColor: "brown",
        greenColor: "green",
        redColor: "red",
        color: "unknown color",
      };
    }

    const returnObject: EffectDetails = {
      whiteColor: "white",
      brownColor: "brown",
      greenColor: "green",
      redColor: "red",
      color: mappedEffect.color,
    };

    if (effect.target === "enemy") {
      returnObject[effect.target] = {
        value: effect.value,
        animationCount: effect.animationCount,
      };
    } else {
      returnObject[effect.target] = {value: effect.value};
    }

    return returnObject;
  };

  const renderDescription = ({
     id,
     lvl,
     levelData,
   }: {
    id: number;
    lvl?: number;
    levelData: level[];
  }): any => {
    if (!levelData || Object.keys(levelData).length === 0) {
      return t(`cards.${id}`, {
        whiteColor: "white",
        brownColor: "brown",
        greenColor: "green",
        redColor: "red",
      });
    }

    const translationData: { [key: string]: any } = {};

    levelData.forEach((levelArray) => {
      const key = EffectType[levelArray.effectType];
      const effectDetails = getEffectDetails(levelArray);

      if (!translationData[key]) {
        translationData[key] = {};
      }

      (["enemy", "self", "both"] as Array<keyof EffectDetails>).forEach(
        (target) => {
          if (effectDetails[target]) {
            const targetDetails = effectDetails[target] ?? {};
            const combinedDetails = {
              ...effectDetails,
              ...targetDetails,
            };

            if (!translationData[key][target]) {
              translationData[key] = {
                ...translationData[key],
                ...effectDetails,
              };
            } else if (Array.isArray(translationData[key][target])) {
              translationData[key][target].push(combinedDetails);
            } else {
              translationData[key][target] = [
                translationData[key][target],
                combinedDetails,
              ];
            }
          }
        }
      );
    });

    // DESPERATION STRIKE (6) card description condition handler
    if (id === 6) {
      const inputString = i18next.t(`cards.${id}`, translationData);
      const pattern = /\|white:\{\{.*?\}\} self-pierce\|/;
      let resultString;

      if (typeof inputString === "string") {
        if (pattern.test(inputString)) {
          resultString = inputString.replace(/\|white:Repeats .*?\|$/, "");
        } else {
          resultString = inputString;
        }
        return resultString;
      }
    }

    if (lvl === 2 && i18next.exists(`cards.${id}_3`)) {
      return i18next.t(`cards.${id}_3`, translationData);
    }

    return i18next.t(`cards.${id}`, translationData);
  };

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    skills: {variables: configCards},
  } = appConfig;

  useEffect(() => {
    // console.log(configCards);
    // console.log(cards);
    const cardInfo: CardsConfig = cards.find((card) => card.id === props.id)!;

    // const priceManna = cardInfo.data.priceManna;

    const levelData = cardInfo.levels?.[props.lvl - 1];
    if (levelData) {
      const renderedDescription = renderDescription({
        id: cardInfo.id,
        lvl: props.lvl - 1,
        levelData,
      });

      const cardDataWithDescription = {
        ...cardInfo,
        data: {
          ...cardInfo.data,
          description: renderedDescription,
          // priceManna,
        },
        lvl: props.lvl,
      };

      setCard({...cardDataWithDescription});
    }
  }, [props, props.lvl]);

  useEffect(() => {}, [props, card]);

  return card?.data && (
    <>
      {props?.showId && (
        <div className="absolute bg-[#ffecc1] right-full top-2 text-[12px] p-1 leading-[1] rounded-[3px] -mr-1">
          {card.id}
        </div>
      )}
      <button
        ref={buttonRef}
        className={`relative scale-0.5 w-full 
          ${card.data.type === CardType.equip ? "rounded-card" : "rounded-md"}
          ${props.IsDraggable && props?.glow ? props?.card?.isBacklight ? "yellow-glow" : "blue-glow" : ""}
        `}>
        <CardBuilderComponent
          id={card.id}
          type={card.data.type}
          image={card.data.image}
          title={card.data.name}
          description={card.data.description}
          stars={card.lvl}
          maxStars={card.data.stars}
          priceMana={card.data.priceManna}
          width={500}
          height={700}
          className='w-full h-full'
        />
      </button>
    </>
  );
}
