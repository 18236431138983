// store/questsSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Resources } from "../../enums/resources";
import { number } from "yargs";
import { setTaskCompleted } from "../../endpoints/userQuests";

export enum TaskStatus {
  NotDone,
  Done,
  Claimed,
}

interface QuestProgress {
  amount: number;
  resource: Resources;
}
export interface QuestTask {
  taskId: number;
  currentProgress: QuestProgress[];
  status: TaskStatus;
}
export interface SocialTaskReward {
  amount: number;
  rewardId: number;
  rewardType: number;
}

export interface SocialTaskCategory {
  id: number;
  isAvailable: boolean;
  name: string;
}
export interface SocialTask {
  category: SocialTaskCategory;
  channelId: string;
  description: string;
  icon?: string;
  id: number;
  isFriends: boolean;
  link: string;
  name: string;
  rewards: SocialTaskReward[];
  status: number;
}

interface QuestsState {
  quests: QuestTask[];
  socialTask: SocialTask[];
  specialTask: SocialTask | null;
}

const initialState: QuestsState = {
  quests: [],
  socialTask: [],
  specialTask: null,
};

export const completeTask = createAsyncThunk(
  "tasks/completeTask",
  async (
    { clientId, taskId }: { clientId: string; taskId: number },
    thunkAPI
  ) => {
    const response = await setTaskCompleted({ clientId, taskId });
    return { taskId, ...response };
  }
);

const questsSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setQuests(state, action: PayloadAction<QuestTask[]>) {
      state.quests = action.payload;
    },
    setSocialTask(state, action: PayloadAction<SocialTask[]>) {
      state.socialTask = action.payload;
    },
    setSpecialTask(state, action: PayloadAction<SocialTask | null>) {
      state.specialTask = action.payload;
    },
    clearSpecialTask(state) {
      state.specialTask = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      completeTask.fulfilled,
      (state, action: PayloadAction<{ taskId: number }>) => {
        const { taskId } = action.payload;
        const task = state.socialTask.find((t) => t.id === taskId);
        if (task) {
          task.status = TaskStatus.Done;
        }
        // Якщо це `specialTask`, оновіть його
        if (state.specialTask && state.specialTask.id === taskId) {
          state.specialTask.status = TaskStatus.Done;
        }
      }
    );
  },
});

export const { setQuests, setSocialTask, setSpecialTask, clearSpecialTask } =
  questsSlice.actions;
export default questsSlice.reducer;
