// lootBoxHandler.ts

import { Resources } from "../enums/resources";
import {
  HeroShard,
  ItemType,
  LootboxId,
  TimeBoosts,
  UniversalShard,
} from "../interfaces/lootBotx";
import {
  resourcesMap,
  universalShardMap,
  timeBoostsMap,
  heroShardMap,
  lootBoxMap,
} from "./mapping";

const handleRewardResult = ({
  rewardType,
  rewardId,
}: {
  rewardType: number;
  rewardId: number;
}): { name: string; image: string } | null => {
  switch (rewardType) {
    case ItemType.farmResource:
      const resource = resourcesMap[rewardId as Resources];
      return resource ? resource : null;

    case ItemType.universalShard:
      const shard = universalShardMap[rewardId as UniversalShard];
      return shard ? shard : null;

    case ItemType.timeBoosts:
      const timeBoost = timeBoostsMap[rewardId as TimeBoosts];
      return timeBoost ? timeBoost : null;

    case ItemType.heroShard:
      const heroShard = heroShardMap[rewardId as HeroShard];
      return heroShard ? heroShard : null;

    case ItemType.lootBox:
      const lootBox = lootBoxMap[rewardId as LootboxId];
      return lootBox ? lootBox : null;

    default:
      return null;
  }
};

export { handleRewardResult };
