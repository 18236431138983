import React, { useEffect } from "react";
import { tutorialStyles } from "./TutorialStyle";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useTutorial } from "./useTutorial";
import { useTranslation } from "react-i18next";
import useFitText from "use-fit-text";
import { Outlet, useLocation } from "react-router-dom";
import PageTransition from "../../containers/Router/components/PageTransition";
import { useDispatch, useSelector } from "react-redux";
import { setSave } from "../../app/features/tutorialSaveSlice";
import {
  getTutorialProgress,
  updateTutorialProgress,
} from "../../endpoints/tutorialProgress";
import { useTelegram } from "../../hooks/useTelegram";
import { TutorialSave, TutorialStage } from "../../interfaces/tutorial";
import FarmEffect from "../../containers/Router/components/FarmEffect";
import { PopupButton } from "../../components/PopupButton";
import { APP_ENV } from "../../config";
import { AppDispatch, RootState } from "../../app/store";
import { openWebSocket } from "../../Websocket/websocketInit";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";

export const Tutorial = () => {
  const { t } = useTranslation();
  const { fontSize, ref } = useFitText({ maxFontSize: 220 });

  const webSocketStatus = useSelector(
    (state: RootState) => state.webSocket.status
  );
  const webSocketErrorMessage = useSelector(
    (state: RootState) => state.webSocket.error
  );

  const {
    stage,
    getBackgroundOpacity,
    getNegativeOpacity,
    counter,
    maxCounter,
    isClicked,
    navigateTo,
    handleButtonClick,
    handleTouchStart,
    animDir,
    showImage,
    clicks,
    cards,
    handleBack,
  } = useTutorial();

  const location = useLocation();

  const PAGES = ["/tutorial/dialogue", "/tutorial/dungeon"];

  const isCurrentPageInArray = PAGES.some((page) =>
    location.pathname.includes(page)
  );

  const { userId } = useTelegram();
  const dispatch = useDispatch<AppDispatch>();
  const updateSave = ({ save }: { save: TutorialSave }) => {
    // const save = { stage: TutorialStage.stone }
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  // const retryCheckTutorialProgressWithFallback = async (retries = 3) => {

  //   let attempt = 0;

  //   while (attempt < retries) {
  //     try {
  //       const save = await getTutorialProgress({ clientId: userId });
  //       if(!save) {
  //         throw Error()
  //       }
  //       if (save) {
  //         // Зберігаємо прогрес у Redux
  //         dispatch(setSave(save));

  //         // Зберігаємо локально для резервної перевірки
  //         localStorage.setItem("tutorialSave", JSON.stringify(save));

  //         // Перевіряємо завершення
  //         if (save.completed || save.stage) {
  //           navigateTo("/island");
  //         }
  //       }

  //       return;
  //     } catch (error) {
  //       attempt++;
  //       console.warn(`Retrying check (${attempt}/${retries})`, error);
  //       alert(
  //         `Retrying check (${attempt}/${retries}) ${error}`
  //       );
  //       // Якщо це остання спроба, використовуємо локальні дані
  //       if (attempt >= retries) {
  //           window.location.reload()
  //       }
  //       alert(
  //         "Failed to check tutorial progress. Please check your internet connection."
  //       );

  //       // Затримка перед повторною спробою
  //       await new Promise((resolve) => setTimeout(resolve, 1000));
  //     }
  //   }
  // };

  // // Використовуємо нову функцію в `useEffect`
  // useEffect(() => {
  //   retryCheckTutorialProgressWithFallback(); // Викликаємо з локальною підтримкою
  // }, []);

  // alert(`webSocketStatus tutor ${webSocketStatus}`)
  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      {webSocketStatus === "failed" ? (
        <div className="error-container">
          <h1>Помилка з'єднання</h1>
          <p>{webSocketErrorMessage}</p>
          <button onClick={() => dispatch(openWebSocket())}>
            Спробувати знову
          </button>
        </div>
      ) : (
        <PageTransition>
          {!isCurrentPageInArray && (
            <div>
              {APP_ENV !== "production" ? (
                <div className="absolute top-14 left-3 z-[100]">
                  <PopupButton
                    type="blue"
                    onClick={() => {
                      updateSave({
                        save: { stage: TutorialStage.battle, enemyId: 1 },
                      });
                      navigateTo("/battle");
                    }}
                  >
                    Skip
                  </PopupButton>
                </div>
              ) : null}
              <div className="no-scroll overflow-hidden left-0 w-full h-full select-none">
                <div style={tutorialStyles.TutorialContainer} className="relative">
                  {["start", "stone", "stone2", "torch"].includes(stage) && (
                    <div>
                      {stage === "torch" && (
                        <div>
                          <div
                            className="h-full w-full absolute bg-[url('./assets/images/cave-2.jpg')] bg-no-repeat bg-center bg-cover"
                            style={{ opacity: getBackgroundOpacity(counter) }}
                          ></div>
                          <div className="h-full w-full absolute bg-gradient-to-b from-[#000] from-60% to-transparent"></div>
                          {/*<img*/}
                          {/*  src={require("../../assets/images/skeleton-eyes.png")}*/}
                          {/*  className="absolute top-[30%] ml-[12px]"*/}
                          {/*  style={{ opacity: getBackgroundOpacity(counter), width: "138px" }}*/}
                          {/*  alt=""*/}
                          {/*/>*/}
                        </div>
                      )}
                      <div className="absolute flex items-center justify-center h-full w-full">
                        <div>
                          <div
                            className="absolute top-8 text-white w-4/5 inset-x-0 m-auto text-center bg-yellow-950 h-5
                        text-xl rounded leading-3 border border-amber-300"
                          >
                            <div
                              className="absolute h-full bg-lime-400 ease-linear duration-300"
                              style={{ width: (counter / maxCounter) * 100 + "%" }}
                            ></div>
                            <div
                              className="absolute w-full text-center select-none"
                              style={{ WebkitTextStroke: "1px black" }}
                            >
                              {counter}/{maxCounter}
                            </div>
                          </div>
                          {counter !== maxCounter && (
                            <button
                              id="btn"
                              className={`p-4 w-[250px] h-[250px] bg-[url('./assets/images/button.png')] bg-no-repeat bg-center rounded-[110px]
                        bg-contain text-[11.5vw] uppercase text-[#814D3C] leading-tight transition-transform transform break-normal ${
                          isClicked ? `${animDir}` : ""
                        }`}
                              style={{ WebkitTextStroke: "1px black", fontSize }}
                              onTouchStart={handleTouchStart}
                              onClick={(e) => handleButtonClick(e)}
                            >
                              {["start", "stone", "stone2"].includes(stage) &&
                                t("tutorial.btn1")}
                              {["amulet"].includes(stage) && t("tutorial.btn4")}
                              {["torch"].includes(stage) && t("tutorial.btn2")}
                              {clicks.length > 0 &&
                                clicks.map(
                                  (click) =>
                                    click.id &&
                                    click.x !== undefined &&
                                    click.y !== undefined && (
                                      <span
                                        key={click.id}
                                        className="absolute text-[#814d3c] text-3xl"
                                        style={{
                                          animation: "fadeOutUp .7s forwards",
                                          left: `${click.x}px`,
                                          top: `${click.y}px`,
                                        }}
                                      >
                                        +1
                                      </span>
                                    )
                                )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {["start", "stone"].includes(stage) && showImage && (
                    <div className="absolute flex items-center justify-center h-full w-full z-50">
                      <div className="absolute top-0 flex items-center justify-center h-full w-full bg-[#0a0a0a]"></div>
                      <img
                        className="absolute z-10 animate-spin"
                        style={{ animationDuration: "3s" }}
                        src={require("../../assets/images/glow.png")}
                        alt=""
                      />
                      <img
                        className="z-20 w-40"
                        src={require("../../assets/images/stone.png")}
                        alt=""
                      />
                      <div className="absolute top-12 text-2xl text-white text-center">
                        {t("tutorial.gotItem")}
                      </div>
                      <div className="absolute bottom-12 text-3xl text-white text-center">
                        {t("tutorial.item1")}
                      </div>
                    </div>
                  )}
                  {["stone2"].includes(stage) && showImage && (
                    <div className="absolute flex items-center justify-center h-full w-full z-50">
                      <div className="absolute top-0 flex items-center justify-center h-full w-full bg-[#0a0a0a]"></div>
                      <img
                        className="absolute z-10 animate-spin"
                        style={{ animationDuration: "3s" }}
                        src={require("../../assets/images/glow.png")}
                        alt=""
                      />
                      <img
                        className="z-20 w-40"
                        src={require("../../assets/images/torch.png")}
                        alt=""
                      />
                      <div className="absolute top-12 text-2xl text-white text-center">
                        {t("tutorial.gotItem")}
                      </div>
                      <div className="absolute bottom-12 text-3xl text-white text-center">
                        {t("tutorial.item2")}
                      </div>
                    </div>
                  )}
                  {["torch"].includes(stage) && showImage && (
                    <div className="absolute flex items-center justify-center h-full w-full z-50">
                      <div className="absolute top-0 flex items-center justify-center h-full w-full bg-[#0a0a0a]"></div>
                      <img
                        className="absolute z-10 animate-spin"
                        style={{ animationDuration: "3s" }}
                        src={require("../../assets/images/glow.png")}
                        alt=""
                      />
                      <img
                        className="z-20 w-40"
                        src={require("../../assets/images/fire.png")}
                        alt=""
                      />
                      <div className="absolute top-12 text-2xl text-white text-center">
                        {t("tutorial.gotItem")}
                      </div>
                      <div className="absolute bottom-12 text-3xl text-white text-center">
                        {t("tutorial.item3")}
                      </div>
                    </div>
                  )}
                  {["fire"].includes(stage) && (
                    <div className="absolute flex items-center justify-center h-full w-full">
                      <div className="h-full w-full absolute bg-[url('./assets/images/cave-2.jpg')] bg-no-repeat bg-center bg-cover"></div>
                      <img
                        className="absolute top-[30%] ml-[12px]"
                        src={require("../../assets/images/skeleton.png")}
                        style={{ width: "138px" }}
                        alt=""
                      />
                      <div
                        className="h-full w-full absolute bg-gradient-to-b from-[#000] from-60% to-transparent"
                        style={{ opacity: getNegativeOpacity(counter) }}
                      ></div>
                      {/*<div*/}
                      {/*  className="absolute top-8 text-white w-4/5 inset-x-0 z-10 m-auto text-center bg-yellow-950 h-5 text-xl*/}
                      {/*rounded leading-3 border border-amber-300"*/}
                      {/*>*/}
                      {/*  <div*/}
                      {/*    className="absolute h-full bg-lime-400 ease-linear duration-300"*/}
                      {/*    style={{ width: (counter / maxCounter) * 100 + "%" }}*/}
                      {/*  ></div>*/}
                      {/*  <div*/}
                      {/*    className="absolute w-full text-center"*/}
                      {/*    style={{ WebkitTextStroke: "1px black" }}*/}
                      {/*  >*/}
                      {/*    {counter}/{maxCounter}*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <img
                        src={require("../../assets/images/skeleton-eyes.png")}
                        className="absolute top-[30%] ml-[12px]"
                        style={{
                          opacity: getNegativeOpacity(counter),
                          width: "138px",
                        }}
                        alt=""
                      />
                      {counter <= 5 && (
                        <div>
                          <img
                            src={require("../../assets/images/dialog-cloud.png")}
                            className="absolute top-[15%] right-5"
                            style={{ width: "220px" }}
                            alt=""
                          />
                          <div
                            className="absolute flex items-center justify-center z-10 right-5 text-center top-[15%]"
                            style={{ width: "220px", height: "110px" }}
                          >
                            <p className="w-full px-[25px] text-wrap">
                              {t("tutorial.enemyLines")}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="absolute flex items-center justify-center h-full w-full">
                        {counter !== maxCounter && (
                          <button
                            className={`p-4 w-[250px] h-[250px] bg-[url('./assets/images/button.png')] bg-no-repeat bg-center rounded-[110px]
                        bg-contain text-5xl uppercase text-[#814D3C] leading-tight transition-transform transform mt-[80px]
                        ${isClicked ? `${animDir}` : ""}`}
                            style={{ WebkitTextStroke: "1px black", fontSize }}
                            onClick={(e) => handleButtonClick(e)}
                            onTouchStart={handleTouchStart}
                          >
                            {t("tutorial.btn3")}
                            {clicks.length > 0 &&
                              clicks.map(
                                (click) =>
                                  click.id &&
                                  click.x !== undefined &&
                                  click.y !== undefined && (
                                    <span
                                      key={click.id}
                                      className="absolute text-[#814d3c] text-3xl animate-fadeOutUp"
                                      style={{
                                        left: `${click.x}px`,
                                        top: `${click.y}px`,
                                      }}
                                    >
                                      +1
                                    </span>
                                  )
                              )}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  {["start", "stone", "stone2", "torch", "fire"].includes(
                    stage
                  ) && (
                    <div className="absolute left-8 bottom-8 flex">
                      {cards &&
                        cards.map((card, index) => (
                          <div
                            className="relative w-14 mr-2"
                            key={index}
                            style={{
                              marginLeft:
                                index !== 0 && card.title === cards[index - 1].title
                                  ? "-50px"
                                  : "",
                            }}
                          >
                            {card.image === "stone" ? (
                              <img
                                className="relative w-full h-full"
                                src={require("../../assets/images/cards/Stone-1Star.png")}
                                alt=""
                              />
                            ) : (
                              <>
                                <img
                                  className="relative w-full h-full"
                                  src={require("../../assets/images/mini-card.png")}
                                  alt=""
                                />
                                <div className="absolute w-full h-full top-0 text-center">
                                  <img
                                    className="relative mx-auto top-3 w-10"
                                    src={require("../../assets/images/" +
                                      card.image +
                                      ".png")}
                                    alt=""
                                  />
                                  <div className="absolute text-xs text-white h-5 bottom-0 w-full text-center">
                                    {card.title}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                  {["amulet"].includes(stage) && (
                    <div className="absolute flex items-center justify-center h-full w-full">
                      <div className="h-full w-full absolute bg-[#0c0c0c] bg-no-repeat bg-center bg-cover"></div>
                      {/*<div className="absolute top-8 text-white w-4/5 inset-x-0 m-auto text-center bg-yellow-950 h-5 text-xl rounded leading-3 border border-amber-300">*/}
                      {/*  <div*/}
                      {/*    className="absolute h-full bg-lime-400 ease-linear duration-300"*/}
                      {/*    style={{ width: (counter / maxCounter) * 100 + "%" }}*/}
                      {/*  ></div>*/}
                      {/*  <div*/}
                      {/*    className="absolute w-full text-center select-none"*/}
                      {/*    style={{ WebkitTextStroke: "1px black" }}*/}
                      {/*  >*/}
                      {/*    {counter}/{maxCounter}*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <button
                        className={`absolute w-[300px] h-[300px] transition-transform transform rounded-[110px] overflow-hidden amulet-appear ${
                          isClicked && animDir
                        }`}
                        onClick={(e) => handleButtonClick(e)}
                        onTouchStart={handleTouchStart}
                      >
                        <img
                          className="w-full h-auto"
                          src={require("../../assets/images/amulet.png")}
                          alt=""
                        />
                        {clicks.length > 0 &&
                          clicks.map(
                            (click) =>
                              click.id &&
                              click.x !== undefined &&
                              click.y !== undefined && (
                                <span
                                  key={click.id}
                                  className="absolute text-[#fff] text-3xl animate-fadeOutUp"
                                  style={{
                                    left: `${click.x}px`,
                                    top: `${click.y}px`,
                                  }}
                                >
                                  +1
                                </span>
                              )
                          )}
                      </button>
                    </div>
                  )}

                  {["battle"].includes(stage) && (
                    <div className="absolute flex items-center justify-center h-full w-full">
                      <div className="h-full w-full absolute bg-[url('./assets/images/cave-2.jpg')] bg-no-repeat bg-center bg-cover"></div>
                      <img
                        className="absolute top-[30%] ml-[12px]"
                        src={require("../../assets/images/skeleton.png")}
                        style={{ width: "138px" }}
                        alt=""
                      />
                    </div>
                  )}
                  {/* <button
                    className="absolute z-20 right-2 bottom-2"
                    onClick={() => navigateTo("/")}
                    title="Go to Home"
                  >
                    <img src={require("../../assets/images/home_ico.png")} alt="" />
                  </button> */}
                </div>
              </div>
            </div>
          )}
          {isCurrentPageInArray && <Outlet />}
        </PageTransition>
      )}
    </Sentry.ErrorBoundary>
  );
};
