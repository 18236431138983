import { useEffect } from "react";
import { TELEGRAM_CLIENT_ID } from "../config";

const tg = window.Telegram?.WebApp;

const ConfigUser = {
  id: 1,
  first_name: "",
};

export function useTelegram() {
  useEffect(() => {
    if (tg) {
      tg.ready();
      tg.expand();
      tg.disableVerticalSwipes();
      tg.enableClosingConfirmation();
      // tg.requestFullscreen()
    }
  }, []);

  return {
    tg,
    user: tg?.initDataUnsafe?.user ?? ConfigUser,
    isExpanded: tg?.isExpanded,
    userId: tg?.initDataUnsafe?.user?.id?.toString() ?? TELEGRAM_CLIENT_ID,
    startParam: tg.initDataUnsafe?.start_param,
  };
}
