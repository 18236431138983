import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTelegram } from "./useTelegram";
import { useSoundService } from "../utils/soundService";
import { RootState } from "../app/store";

import { ConfigOffer } from "../mock/offers";
import { getBalance } from "../endpoints/farmMock";
import { useTranslation } from "react-i18next";
import { purchaseTelegram } from "../endpoints/botEndpoints";

export const usePurchase = () => {
  const { tg, userId } = useTelegram();
  const { t } = useTranslation();
  const { playSound } = useSoundService();
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<ConfigOffer | null>(null);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    offers: { variables: allOffers },
  } = appConfig;

  const getOfferById = (offerId: number) => {
    playSound("notification");
    const offer = allOffers.find(
      (offer: ConfigOffer) => offer.offerId.value === offerId,
    );
    if (offer) {
      setSelectedOffer(offer);
      return offer;
    }
  };

  const confirmPurchase = async (offerId: number) => {
    const selectedOffer: ConfigOffer | null = getOfferById(offerId);
    if (!selectedOffer) return;
    setIsPurchaseLoading(true);
    playSound("button");
    try {
      const invoiceLink = await purchaseTelegram({
        offerId: selectedOffer.offerId.value,
        offerType: selectedOffer.offerType.value,
        title: t(`shop.offers.${selectedOffer.offerType.value}.title`),
        description: t(`shop.offers.${selectedOffer.offerType.value}.title`),
        label: t(`shop.offers.${selectedOffer.offerType.value}.title`),
      });
      if (invoiceLink) {
        tg.openInvoice(invoiceLink, (status: string | undefined) => {
          if (status === "paid") {
            setIsSuccessPurchase(true);
            setSelectedOffer(null);
          }
          setIsPurchaseLoading(false);
          setTimeout(async () => {
            await getBalance({ clientId: `${userId}` });
            resetPurchase();
          }, 3000);
        });
      }
    } catch (error) {
      console.error("Purchase failed:", error);
      setIsPurchaseLoading(false);
    }
  };

  const resetPurchase = () => {
    setSelectedOffer(null);
    setIsSuccessPurchase(false);
  };

  return {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  };
};
