import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../../hooks/useTelegram";
import { AppDispatch } from "../../../../app/store";
import { useDispatch } from "react-redux";
import { PopupWindow } from "../../../PopupWindow";
import { useState } from "react";
import { PopupButton } from "../../../PopupButton";

const InfluencersApplicationHeader = ({}: {}) => {

  window.location.hash = 'input';

  return (
    <>
      <div className="absolute top-[-15px] left-0 right-0 flex justify-self-center z-20">
        <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
          <div
            className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
              border-[#18191a] shadow-inner-sm-black"
          >
            Influencers Application
          </div>
        </div>
      </div>
    </>
  );
};

export const InfluencersApplication = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: ({ link, about }: { link: string; about: string }) => void;
  onCancel: () => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { userId } = useTelegram();

  const [link, setLink] = useState("");
  const [about, setAbout] = useState("");
  const [errors, setErrors] = useState({ link: "", about: "" });

  const validate = () => {
    let valid = true;
    let errors = { link: "", about: "" };

    // Простий варіант валідації URL
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    if (!link) {
      errors.link = "Link is required.";
      valid = false;
    } else if (!urlPattern.test(link)) {
      errors.link = "Please enter a valid URL.";
      valid = false;
    }

    if (!about) {
      errors.about = "About is required.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit({ link, about });
      // Можна очистити форму після відправки
    setLink("");
    setAbout("");
  };

  return (
    <div className="fixed inset-0 z-[100] w-full h-full flex justify-center items-center min-w-full max-w-full min-h-full max-h-full">
      <div
        onClick={onCancel}
        className="absolute z-40 h-full w-full bg-[rgba(0,0,0,0.5)]"
      />
      <PopupWindow
        onClickClose={onCancel}
        headerElement={<InfluencersApplicationHeader />}
      >
        <form
          onSubmit={handleSubmit}
          className="relative flex flex-col gap-2 justify-center items-center w-[calc(100%-40px)] py-4 mt-5"
        >
          {/* Поле для Link */}
          {/* <div>
            <label
              htmlFor="link"
              className="block text-sm font-medium text-white"
            >
              Link
            </label>
            <input
              type="url"
              id="link"
              name="link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className={`mt-1 block w-full px-3 py-2 bg-[#242520] border shadow-inner-sm-black ${
                errors.link ? "border-red-500" : " border-[#554837] "
              } rounded-md shadow-sm placeholder-[#e1c78c] text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              placeholder="https://example.com"
            />
            {errors.link && (
              <p className="mt-1 text-sm text-red-500">{errors.link}</p>
            )}
          </div> */}

          {/* Поле для About */}
          <div className="w-full mb-[40px]" >
            <p className="w-full text-center text-[#F6A000] text-[13px] mb-[10px] " >Get your share of USDT from user purpurchases</p>
            <label
              htmlFor="about"
              className="block text-sm font-medium text-white w-full text-center"
            >
              About your blog
            </label>
            <textarea
              id="about"
              name="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              className={`mt-1 block w-full px-3 py-2 bg-[#242520] border shadow-inner-sm-black ${
                errors.about ? "border-red-500" : "border-[#554837]"
              } rounded-md shadow-sm placeholder-[#6e675e] text-[12px] text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
              placeholder="Share the link to your blog and few words about it"
              rows={4}
            ></textarea>
            {errors.about && (
              <p className="mt-1 text-sm text-red-500">{errors.about}</p>
            )}
          </div>

          {/* Кнопки відправки та скасування */}
          <div className="flex absolute bottom-0 translate-y-[70%]  justify-end gap-2">
            <button type="submit">
              <PopupButton type="blue" size="medium" width="200px" >
                Apply
              </PopupButton>
            </button>
          </div>
        </form>
      </PopupWindow>
    </div>
  );
};
