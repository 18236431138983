import { Outlet, useLocation } from "react-router-dom";
import { Balance, Setting } from "../../containers";
import { Background } from "../../layout/components/Background";
import { TitleField } from "../../containers/Room";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { useTranslation } from "react-i18next";
import PageTransition from "../../containers/Router/components/PageTransition";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";
import {useTelegram} from "../../hooks/useTelegram";

export const Profile = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userId } = useTelegram();

  const PAGES = [
    "/profile/language",
    "/profile/exchange",
    "/profile/delete-account",
  ];

  const isCurrentPageInArray = PAGES.some((page) =>
    location.pathname.includes(page),
  );

  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      <PageTransition>
        {!isCurrentPageInArray && (
          <>
            <div className="relative z-20 flex justify-start items-center w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
              <div className="absolute top-[1px] pl-3">
                <HandleBackButton />
              </div>

              <div className="text-white text-2xl font-black font-['Sofia Sans Semi Condensed'] leading-normal pl-16 text-shadow-black-sm">
                {t("profile.name")}
              </div>
            </div>
            <div className="p-4 bg-[#201B18] h-full">
              <div className="overflow-auto h-full pb-10">
                <Balance />
                <Setting />
                <Outlet />
              </div>
            </div>
          </>
        )}
        {isCurrentPageInArray && <Outlet />}
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
