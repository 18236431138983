import { useEffect } from "react";
import {useSoundService} from "../../../utils/soundService";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";

export const VictoryPage = ({
 className,
 coinNum,
 oldHpNum,
 newHpNum,
 expBarNum,
 lvlNum,
}: {
  className?: string;
  coinNum?: number;
  oldHpNum: number;
  newHpNum?: number;
  expBarNum: number;
  lvlNum: number;
}) => {
  const { playSound, pausedMusic } = useSoundService();
  const {t} = useTranslation();

  useEffect(() => {
    pausedMusic();
    playSound('victory');
  }, []);

  const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="fixed inset-0 z-10 flex flex-col justify-center items-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="relative flex justify-center items-center pb-4 px-2 max-w-[420px] mx-4"
        variants={itemVariants}
      >
        <div className="absolute bottom-full flex justify-center w-[198px] h-[85px] -mb-[47px]">
          <img className="z-[2]" src={require("../../../assets/images/victory.png")} alt=""/>
        </div>

        <img src={require("../../../assets/images/afterBattleBar.png")} alt=""/>

        <div className="absolute bottom-11 top-6 left-8 right-8 flex flex-col">
          <div
            className="relative w-full rounded-[3px] border border-[#18191a] bg-[#554837] p-[2px] mb-[5px] flex-1 h-0">
            <div className="rounded-[2px] border border-[#18191a] h-full">
              <img
                src={require("../../../assets/images/victoryBackground.jpg")}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
          </div>

          <div className="relative px-2 py-1.5 bg-[rgba(18,20,14,0.5)] rounded-[3px] border border-[#18191a]
            flex justify-start items-center gap-1 flex-wrap max-w-full">
            <div className="text-white text-[16px] leading-[1.2] text-stroke-small flex items-center flex-wrap justify-center">
              <span className="pr-1.5">{t('tutorial.reward')}:</span>
              {newHpNum && oldHpNum ? (
                <span className="text-[#FF7F7F] text-nowrap flex items-center pr-2">
                  Max HP
                  <img
                    src={require('../../../assets/images/hpIcon.png')}
                    className="w-[22px] h-[22px] inline-block mx-1" alt=""
                  />
                  +{newHpNum-oldHpNum}
                </span>
              ) : null}
              {coinNum ? (
                <span className=" text-[#F6A000] text-nowrap flex items-center">
                Gold
                <img
                  src={require('../../../assets/images/goldIcon.png')}
                  className="w-[20px] h-[20px] inline-block mx-1" alt=""
                />
                +{coinNum}
              </span>
              ) : null}
            </div>
          </div>
        </div>
      </motion.div>

      <div className="text-white absolute bottom-16 text-[20px] font-[900] w-full left-0 text-center">{t('next')}</div>
    </motion.div>
  );
};
