import { useTranslation } from "react-i18next";
import { UpgradeArrow } from "../../containers/Room";
import { PopupButton } from "../PopupButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoom } from "../../app/features/selectedRoom";
import { StoneIco } from "../../layout/components/HeaderFarm/components";
import { Offer } from "../../pages/Shop/components";
import { usePurchase } from "../../hooks/usePurchase";
import { RootState } from "../../app/store";
import { ConfigOffer, OfferType } from "../../mock/offers";
import { OfferProps } from "../../pages/Shop/components/Offer";
import { OfferResourceProps } from "../../pages/Shop/components/OfferResources";
import { defineCountString } from "../../utils/farmConfigParser";
import { PurchaseModal } from "../PurchaseModal";
import { SuccessOverlay } from "../SuccessOverlay";
import { RoomStatus } from "../../enums/buildingStatus";
import { updateBattleSave } from "../../utils/updateBattleSave";
import { useUtils } from "../../utils/navigateTo";
import { useLocalStorage, useSessionStorage } from "@uidotdev/usehooks";
import { useTelegram } from "../../hooks/useTelegram";
import { getDungeonProgressByDungeonId } from "../../endpoints/dungeonEndpoints";

export interface UpgradeDataRow { 
  title: string;
  nowValue: number;
  newValue: number;
}

export const PopupUpgradeTable = (props: {
  upgradeData?: UpgradeDataRow[];
  requiredConditions?: {
    condition: string;
    met: boolean;
    buildingId?: number;
    lvl?: number;
    status?: number;
  }[];
  upgradeNormalCondition: {
    met: boolean;
    currentAmount: number;
    costAmount: number;
  };
  maxTimeMs?: {
    nowMaxTime: number | undefined;
    newMaxTime: number | undefined;
  };
}) => {
  const { upgradeData, requiredConditions, upgradeNormalCondition } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  console.log('UPGRADEDATA', props)

  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const {
    offers: { variables: allOffers },
  } = appConfig;

  const stoneOffer = allOffers.filter(
    (offer: ConfigOffer) =>
      offer.offerType.value === OfferType.stoneOffer &&
      offer.offerId.value === 9
  );
  

  const mappedStoneStoreOffer: OfferProps[] = stoneOffer.map(
    (offer: ConfigOffer): OfferProps => {
      return {
        id: offer.offerId.value,
        resourceList: offer.items.value.map(
          (item, index): OfferResourceProps => {
            return {
              resourceType: item.rewardType.value,
              resourceId: item.rewardId.value,
              isEnabled: index === 0 ? true : false,
              layout: "vertical",
              amount: defineCountString(item.amount.value),
            };
          }
        ),
        offerType: offer.offerType.value,
        type: "gold",
        label: "corner",
        layout: "slim",
        price: defineCountString(offer.stars.value),
        deadline: "January 31, 2025",
      };
    }
  );

  const offer = mappedStoneStoreOffer[0];

  function msToTime(duration: any) {
    if (!duration) {
      return;
    }
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }
  
  const parseUpgradeData = () => {

  }

  return (
    <div className="w-full">
      {/* Upgrade Data Section */}

      {offer &&
      upgradeNormalCondition.currentAmount <
        upgradeNormalCondition.costAmount ? (
        <div className="flex justify-center items-center p-2 w-full">
          <Offer
            onClick={() => {
              confirmPurchase(offer.id);
            }}
            offerType={offer.offerType}
            key={0}
            id={offer.id}
            resourceList={offer.resourceList}
            layout={offer.layout}
            type={offer.type}
            label={offer.label}
            kitsu={offer.kitsu}
            price={offer.price}
            deadline={offer.deadline}
          />
        </div>
      ) : null}

      <div className="w-full">
        {upgradeData?.map((row, i) => (
          row.nowValue != row.newValue &&
          <div key={i} className={`w-full flex flex-col ${i%2?'bg-[#362d28]':'bg-transparent'} `}>
            <div className="flex flex-row items-center justify-between py-4 px-6">
              {/* Title */}
              <p className="text-[18px] text-white">{t(row.title)}</p>

              {/* Values with Arrow */}
              <div className="flex flex-row items-center justify-end gap-2">
                <p className="text-[13px] text-white">{i===1?msToTime(row.nowValue):row.nowValue}</p>  
                <UpgradeArrow direction="right" />
                <p className="text-[13px] text-[#F6A000]">{i===1?msToTime(row.newValue):row.newValue}</p>
              </div>
            </div>
          
            {/* Separator */}
            {i < upgradeData.length - 1 && (
              <div className="h-px bg-[#584d3c] mx-6"></div>
            )}
          </div>
        ))}
      </div>

      {/* Divider Between Sections */}
      <div className="w-full px-4 mt-4">
        <div className="w-full h-[3px] bg-[#181615] mb-1"></div>
        <div className="w-full h-[3px] bg-[#3b332e]"></div>
      </div>

      {/* Required Conditions Section */}
      <div className="w-full">
        {requiredConditions?.map((row, i) => (
          <React.Fragment key={i}>
            <div className="w-full flex flex-col bg-transparent">
              <div className="flex flex-row items-center justify-between py-4 px-6">
                {/* Condition Text */}
                <p
                  className={`text-[18px] ${
                    row.met ? "text-white" : "text-red-600"
                  }`}
                >
                  {row.condition}
                </p>

                {/* Status or Action */}
                <div
                  className={`flex flex-row items-center justify-end gap-1.25 ${
                    row.met ? "pr-6" : ""
                  }`}
                >
                  {row.met ? (
                    <div className="w-6 h-6 ">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.35355 9.64645L6 9.29289L5.64645 9.64645L3.64645 11.6464L3.29289 12L3.64645 12.3536L9.64645 18.3536L10 18.7071L10.3536 18.3536L20.3536 8.35355L20.7071 8L20.3536 7.64645L18.3536 5.64645L18 5.29289L17.6464 5.64645L10 13.2929L6.35355 9.64645Z"
                          fill="#45B684"
                          stroke="#19191B"
                        />
                      </svg>
                    </div>
                  ) : row.buildingId ? (
                    row.status === RoomStatus.building ? (
                      <div className="text-white">
                        You need to finish building
                      </div>
                    ) : row.lvl !== -Infinity && row.lvl! > 0 ? (
                      <PopupButton type="green">
                        <div className="flex justify-center items-center w-full ">
                          <div
                            className="flex justify-center items-center w-[58px] h-[22px] text-center text-white text-base font-normal leading-none cursor-pointer"
                            onClick={() => {
                              dispatch(
                                setSelectedRoom({
                                  id: row.buildingId ?? 1,
                                  buildingId: row.buildingId,
                                  lvl: row.lvl,
                                  type: 1,
                                })
                              );
                            }}
                          >
                            Build
                          </div>
                        </div>
                      </PopupButton>
                    ) : row.status === RoomStatus.battle ? (
                      // <div>hello</div>
                      <FightButton />
                    ) : (
                      <div className="text-white">You need to repair it</div>
                    )
                  ) : row.lvl !== -Infinity ? (
                    <div className="text-white">You need to repair it</div>
                  ) : (
                    <div className="w-6 h-6">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.9268 6.99662L19.1036 6.81984L18.9268 6.64307L17.3569 5.07322L17.1802 4.89645L17.0034 5.07322L12 10.0766L6.99662 5.07322L6.81984 4.89645L6.64307 5.07322L5.07322 6.64307L4.89645 6.81984L5.07322 6.99662L10.0766 12L5.07322 17.0034L4.89645 17.1802L5.07322 17.3569L6.64307 18.9268L6.81984 19.1036L6.99662 18.9268L12 13.9234L17.0034 18.9268L17.1802 19.1036L17.3569 18.9268L18.9268 17.3569L19.1036 17.1802L18.9268 17.0034L13.9234 12L18.9268 6.99662Z"
                          fill="#F04444"
                          stroke="black"
                          strokeWidth="0.5"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              {/* Separator */}
              {i < requiredConditions.length - 1 && (
                <div className="h-px bg-[#584d3c] mx-6"></div>
              )}
            </div>
          </React.Fragment>
        ))}
        {
          <div className="flex flex-row items-center justify-between py-4 px-6">
            <div className="flex gap-2 justify-center items-center">
              <div className="w-5 h-5">
                <StoneIco />
              </div>
              <div className="text-[18px] text-white">
                {upgradeNormalCondition.currentAmount}/
                {upgradeNormalCondition.costAmount}
              </div>
            </div>

            {upgradeNormalCondition.met}

            <div className="flex flex-row items-center justify-end gap-1.25 pr-6">
              {upgradeNormalCondition.currentAmount >
              upgradeNormalCondition.costAmount ? (
                <div className="w-6 h-6">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.35355 9.64645L6 9.29289L5.64645 9.64645L3.64645 11.6464L3.29289 12L3.64645 12.3536L9.64645 18.3536L10 18.7071L10.3536 18.3536L20.3536 8.35355L20.7071 8L20.3536 7.64645L18.3536 5.64645L18 5.29289L17.6464 5.64645L10 13.2929L6.35355 9.64645Z"
                      fill="#45B684"
                      stroke="#19191B"
                    />
                  </svg>
                </div>
              ) : (
                <div className="w-6 h-6">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.9268 6.99662L19.1036 6.81984L18.9268 6.64307L17.3569 5.07322L17.1802 4.89645L17.0034 5.07322L12 10.0766L6.99662 5.07322L6.81984 4.89645L6.64307 5.07322L5.07322 6.64307L4.89645 6.81984L5.07322 6.99662L10.0766 12L5.07322 17.0034L4.89645 17.1802L5.07322 17.3569L6.64307 18.9268L6.81984 19.1036L6.99662 18.9268L12 13.9234L17.0034 18.9268L17.1802 19.1036L17.3569 18.9268L18.9268 17.3569L19.1036 17.1802L18.9268 17.0034L13.9234 12L18.9268 6.99662Z"
                      fill="#F04444"
                      stroke="black"
                      strokeWidth="0.5"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        }
      </div>
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};

const FightButton = () => {
  const { navigateTo } = useUtils();

  const { userId } = useTelegram();
  const dispatch = useDispatch();

  const selectedHero = useSelector((state: RootState) => state.selectedHero);

  const heroId = Number(selectedHero.uid);
  const [currentBattleBuildingId, setCurrentBattleBuildingId] = useLocalStorage(
    "currentBattleBuildingId",
    0
  );

  const [isDungeonStart, setIsDungeonStart] = useSessionStorage(
    "isDungeonStart",
    false
  );
  const [currentDungeonId, setCurrentDungeonId] = useSessionStorage(
    "currentDungeonId",
    0
  );

  const getDungeonSaveResult = async () => {
    const result = await getDungeonSave();
    setIsDungeonStart(result);
    return result;
  };

  const getDungeonSave = async () => {
    try {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: currentBattleBuildingId,
        heroId: heroId,
      });

      if (progress) {
        setCurrentDungeonId(progress.currentDungeonId);
        const maxStageId = Math.max(
          ...progress.stages.map((stage) => stage.stageId)
        );

        // Знаходимо об'єкт з цим stageId
        const maxStage = progress.stages.find(
          (stage) => stage.stageId === maxStageId
        );
        // Перевіряємо, чи id цього об'єкта більше за 3
        return maxStage ? maxStage.stageId > 3 : false;
      }
      return false;
    } catch (error) {
      console.error("Error fetching dungeon progress:", error);
      return false;
    }
  };

  const onClickBattle = () => {
    console.log("curr build id", currentBattleBuildingId);

    const getDungeonSaveOrStartDungeon = async () => {
      //@ts-ignore
      const progress = await getDungeonProgressByDungeonId({
        clientId: userId,
        buildingId: currentBattleBuildingId,
        heroId: heroId,
      });

      if (progress?.stages?.length) {
        if (progress.currentDungeonId && currentBattleBuildingId) {
          updateBattleSave({
            save: {
              gold: progress.gold,
              currentHp: progress.currentHp,
              currentMana: progress.currentMana,
              stages: progress.stages,
              dungeonId: progress.currentDungeonId,
              buildingId: currentBattleBuildingId,
            },
            clientId: userId,
          });
          navigateTo("/dungeon");
        }
      }
    };
    // @ts-ignore
    getDungeonSaveOrStartDungeon();
    setTimeout(() => {
      setIsDungeonStart(false);
    }, 500);
  };

  const onClick = async () => {
    dispatch(setSelectedRoom({ id: currentBattleBuildingId }));
    const dungeonStarted = await getDungeonSaveResult();

    if (dungeonStarted) {
      return null;
    }

    return onClickBattle();
  };

  return (
    <PopupButton type="green">
      <div className="flex justify-center items-center w-full ">
        <div
          className="flex justify-center items-center w-[58px] h-[22px] text-center text-white text-base font-normal leading-none cursor-pointer"
          onClick={onClick}
        >
          Fight
        </div>
      </div>
    </PopupButton>
  );
};
