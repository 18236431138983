import { motion } from "framer-motion"


export const BonusTable = ({ onClick }: { onClick: () => void }) => {

    const friendsList = ['1-9', '10-49', '50-99', '100-499', '500-999', '1000+',]
    const bonusList = ['10%', '11%', '12%', '15%', '17%', '20%',]

    return (
        <motion.div
            initial={{ transform: 'translateY(110%)' }}
            animate={{ transform: 'translateY(0)' }}
            className={`absolute w-[calc(100%-32px)] left-[16px] right-[16px] h-[340px] bg-[#574b3a] border-solid border-[2px] border-black `} >


            {/* Corners of the modal */}
            <img
                src={require("../../../../../assets/images/cards-modal-border.png")}
                className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
                alt=""
            />
            <img
                src={require("../../../../../assets/images/cards-modal-border.png")}
                className="absolute w-[17.5px] h-[21px] -bottom-[6.5px] -left-[4.5px] -rotate-90"
                alt=""
            />
            <img
                src={require("../../../../../assets/images/cards-modal-border.png")}
                className="absolute w-[17.5px] h-[21px] -bottom-[5.5px] -right-[4.5px] -rotate-180"
                alt=""
            />

            <div className={`absolute flex justify-center items-center w-[calc(100%-6px)] h-[calc(100%-6px)] left-[3px] top-[3px] bg-[#242520] border-solid border-[2px] border-black shadow-[inset_0_0_2px_rgba(255,255,255,0.5)] `} >
                <div className={`absolute w-[calc(100%-24px)] h-[calc(100%-24px)] left-[12px] top-[12px] bg-[#221d1a] shadow-[0_0_1px_white,inset_0_0_1em_rgba(0,0,0,0.5)] `} >

                    <div
                        className={`absolute right-[-30px] top-[-35px] w-[3em] h-[3em] z-[100] p-2`}
                        onClick={onClick}
                    >
                        <img
                            src={require("../../../../../assets/images/shop-modal-close.png")}
                            className="w-full h-full"
                            alt=""
                        />
                    </div>

                    <div className={`absolute top-[-10%] left-[50%] translate-x-[-50%] bg-[#847a70] w-[152px] h-[40px] text-center text-white  border-solid border-2 border-black rounded-[2px] `} >
                        <div className={`absolute text-[20px] w-[calc(100%-2px)] h-[calc(100%-2px)] top-[1px] left-[1px] bg-[#351d1d] rounded-[2px] border-solid border-2 border-black shadow-[inset_0_0_3px_black] `} >
                            Details
                        </div>
                    </div>
                    <div className={`flex justify-center items-center mt-[28px]`} >
                        <div className={`flex flex-col w-[calc(50%-1px)] text-center text-[#e1c78b] `} >
                            <p className={`text-white bg-[#362d28] h-[50px] mb-[8px] `} >Frens</p>
                            {friendsList.map((f, index) =>
                                <div className={`${index % 2 ? 'bg-[#2f2824]' : 'bg-[#201b18]'} h-[36px] `} >
                                    {f}
                                </div>)}
                        </div>
                        <div className={`w-[1px] bg-[#453e38]`} ></div>
                        <div className={`flex flex-col w-[calc(50%-1px)] text-center text-[#e1c78b] `} >
                            <p className={`text-white bg-[#362d28] h-[50px] mb-[8px] `} >$KITSU referal bonus</p>
                            {bonusList.map((b, index) =>
                                <div className={`${index % 2 ? 'bg-[#2f2824]' : 'bg-[#201b18]'} h-[36px] `} >
                                    {b}
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}