import { useTranslation } from "react-i18next";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { useUtils } from "../../utils/navigateTo";
import PageTransition from "../../containers/Router/components/PageTransition";
import { useEffect, useMemo, useState } from "react";
import { clearUserRank, fetchUserRank } from "../../app/features/userRankSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { useTelegram } from "../../hooks/useTelegram";
import { PopupButton } from "../../components/PopupButton";
import { BonusType } from "../../interfaces/hero";
import {
  ConfigBonusType,
  RankBonuses,
  RankConfig,
} from "../../interfaces/rank";
import { BonusRankType, claimOneTime } from "../../endpoints/rankEndpoints";
import { Offer } from "../Shop/components";
import { OfferResourceProps } from "../Shop/components/OfferResources";
import { defineCountString } from "../../utils/farmConfigParser";
import { ConfigOffer, OfferType } from "../../mock/offers";
import { OfferProps } from "../Shop/components/Offer";
import { usePurchase } from "../../hooks/usePurchase";
import { SuccessOverlay } from "../../components/SuccessOverlay";
import { useSessionStorage } from "@uidotdev/usehooks";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";

const ProgressBar = ({ current, max }: any) => {
  const percentage = Math.min((current / max) * 100, 100);

  return (
    <div className="w-full bg-[#312d2a]  h-5 relative rounded-[3px] shadow-inner-sm-black border border-[#18191a]">
      <div
        className="bg-gradient-to-b from-[#fffe2e] to-[#fe8315] h-full p rounded-[3px] transition-all duration-500 flex items-center justify-center"
        style={{ width: `${percentage}%` }}
      />
      <div className="absolute text-center top-0 text-white text-[15px] font-extrabold font-['Sofia Sans Semi Condensed'] leading-[15px] w-full h-full flex justify-center items-center">
        {`${current} / ${max}`}
      </div>
    </div>
  );
};

export const RankPage = () => {
  const { t } = useTranslation();
  const { goBack, navigateTo } = useUtils();
  const dispatch = useDispatch<AppDispatch>();
  const { tg, userId } = useTelegram();
  const [isShopOpen, setShopOpen] = useSessionStorage("isShopOpen", false);
  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  useEffect(() => {
    dispatch(fetchUserRank({ clientId: userId }));

    return () => {
      dispatch(clearUserRank());
    };
  }, [dispatch]);

  const { userRank, loading, error } = useSelector(
    (state: RootState) => state.userRank
  );

  const sortedRanks = useMemo(() => {
    if (!appConfig) return [];
    return [...appConfig.ranks.variables].sort(
      (a, b) => a.lvl.value - b.lvl.value
    );
  }, [appConfig]);

  const currentRankIndex = useMemo(() => {
    if (!userRank) return undefined;
    return sortedRanks.findIndex(
      (rank) => rank.lvl.value === userRank.myRankLvl
    );
  }, [sortedRanks, userRank]);

  const [selectedRankIndex, setSelectedRankIndex] = useState(
    currentRankIndex ?? 1
  );

  // const configRankData = useMemo(() => {
  //   if (!appConfig || !userRank) return undefined;

  //   const configRanks: RankConfig[] = appConfig.ranks.variables;

  //   // Створюємо копію масиву перед сортуванням
  //   const sortedRanks = [...configRanks].sort(
  //     (a, b) => a.lvl.value - b.lvl.value
  //   );

  //   // Знаходимо індекс поточного рівня
  //   const currentRankIndex = sortedRanks.findIndex(
  //     (rank: RankConfig) => rank.lvl.value === userRank.myRankLvl
  //   );

  //   // Якщо поточного рівня немає в конфігурації, повертаємо undefined
  //   if (currentRankIndex === -1) return undefined;

  //   // Поточний ранг
  //   const currentRank = sortedRanks[currentRankIndex];

  //   // Наступний ранг (якщо існує)
  //   const nextRank = sortedRanks[currentRankIndex + 1] || null;

  //   return {
  //     currentRank,
  //     nextRank,
  //   };
  // }, [appConfig, userRank]);
  // console.log("configRankData", configRankData);

  const currentRank: RankConfig = sortedRanks[userRank?.myRankLvl!];
  const selectedRank: RankConfig = sortedRanks[selectedRankIndex!];
  const nextRank: RankConfig = sortedRanks[selectedRankIndex! + 1] || null;
  const prevRank: RankConfig = sortedRanks[selectedRankIndex! - 1] || null;
  const activeBonuses = useMemo(() => {
    if (!selectedRank || !userRank || sortedRanks.length === 0)
      return undefined;

    // Створюємо мапу для накопичення бонусів за типом
    const bonusMap: any = {};

    // Ітерація по всіх рангам до обраного включно
    for (let i = 0; i <= selectedRankIndex; i++) {
      const rank = sortedRanks[i];
      const bonuses = rank.bonuses.value;

      bonuses.forEach((bonus: ConfigBonusType) => {
        const type = bonus.type.value;
        const amount = bonus.amount.value;

        // Сумуємо бонуси за типом
        if (bonusMap[type]) {
          bonusMap[type] += amount;
        } else {
          bonusMap[type] = amount;
        }
      });
    }

    // Конвертуємо мапу в масив
    const accumulatedBonuses = Object.entries(bonusMap).map(
      ([type, amount]) => ({ type, amount })
    );

    const values = Object.values(userRank.bonuses) as Array<
      RankBonuses[keyof RankBonuses]
    >;

    function getValueByIndex(index: number): number | undefined {
      return values[index];
    }
    // Знаходимо індекс останнього відкритого бонусу
    let lastOpenIndex = -1;
    for (let i = accumulatedBonuses.length - 1; i >= 0; i--) {
      const bonus = accumulatedBonuses[i];
      const bonusData = getValueByIndex(Number(bonus.type));

      if (bonusData !== undefined && bonusData > 0) {
        lastOpenIndex = i;
        break;
      }
    }

    // Мапінг бонусів з встановленням isOpen та isNew
    const mappedBonuses = accumulatedBonuses.map((bonus, index) => {
      const bonusData = getValueByIndex(Number(bonus.type));
      return {
        amount: bonus.amount,
        type: bonus.type,
        isOpen: bonusData !== undefined && bonusData > 0,
        isNew: index === lastOpenIndex,
      };
    });

    return mappedBonuses;
  }, [selectedRank, userRank, sortedRanks, selectedRankIndex]);

  const rankOffer = useMemo(() => {
    if (!appConfig || !userRank) return undefined;

    const {
      offers: { variables: allOffers },
    } = appConfig;

    const rankOffer = allOffers.filter(
      (offer: ConfigOffer) =>
        offer.offerType.value === OfferType.rankOffer &&
        // TODO add new id
        offer.offerId.value === 10
    );
    const mappedRankOffer: OfferProps[] = rankOffer.map(
      (offer: ConfigOffer): OfferProps => {
        return {
          id: offer.offerId.value,
          resourceList: offer.items.value.map(
            (item, index): OfferResourceProps => {
              return {
                resourceType: item.rewardType.value,
                resourceId: item.rewardId.value,
                isEnabled: index === 0 ? true : false,
                layout: "vertical",
                amount: defineCountString(item.amount.value),
              };
            }
          ),
          offerType: offer.offerType.value,
          type: "gold",
          label: "corner",
          layout: "slim",
          price: defineCountString(offer.stars.value),
          deadline: "January 31, 2025",
        };
      }
    );

    return mappedRankOffer[0];
  }, [appConfig]);

  const currentOffer = useMemo(() => {
    if (!userRank || !selectedRank) return undefined;

    const filteredRanks = Object.values(userRank.rewardsToClaim)
      .filter(
        (reward) => reward.status === 1 && reward.lvl <= userRank.myRankLvl
      )
      .sort((a, b) => a.lvl - b.lvl);
    console.log("filteredRanks", filteredRanks);
    // Знайдемо ранг з потрібним lvl
    const foundRank = filteredRanks.find(
      (reward) => reward.lvl === selectedRank.lvl.value
    );

    return foundRank;
  }, [userRank, selectedRank]);

  const handleLevelChange = (delta: any) => {
    setSelectedRankIndex((prevIndex) => {
      const newIndex = prevIndex + delta;
      if (newIndex < 0) return 0;
      if (newIndex >= sortedRanks.length) return sortedRanks.length - 1;
      return newIndex;
    });
  };

  if (loading || !appConfig || !userRank) {
    return <div>Loading...</div>;
  }
  if (currentRankIndex === -1) return <div>Loading...</div>;

  if (error) return <div>Error: {error}</div>;

  if (!selectedRank) {
    return <div>No data for the next rank.</div>;
  }

  if (!activeBonuses) {
    return <div>No data for the activeBonuses.</div>;
  }

  const handleClaimFreePack = async (lvl: number) => {
    const data = await claimOneTime({
      clientId: userId,
      lvl: lvl,
    });
    if (data) dispatch(fetchUserRank({ clientId: userId }));
  };
  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      <PageTransition>
        {userRank ? (
          <div
            className="w-full h-full fixed top-0 left-0 z-[56]  bg-[#351c1d] shadow-inner-sm-black "
            style={{
              background:
                "radial-gradient(circle at center, #351c1d 40%, rgba(0,0,0,0.5) 100%)",
            }}
          >
            <div className="relative flex justify-start items-center mb-[15px] w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
              {/* Заголовок Inventory */}
              <div className="absolute top-[1px] pl-3">
                <HandleBackButton onClick={goBack} />
              </div>
              <div className="text-white text-2xl font-black font-['Sofia Sans Semi Condensed'] leading-normal pl-16 text-shadow-black-sm">
                Rank
              </div>
            </div>
            <div className="flex justify-between items-center p-4">
              <div className="relative w-[84px] h-[84px]">
                <img
                  className="w-full h-full"
                  src={require(`../../assets/images/ranks/${userRank.myRankLvl}.png`)}
                />
                <div className="flex justify-center items-center absolute w-full bottom-0">
                  <div className="w-16 h-6">
                    <img
                      className="w-full h-full"
                      src={require("../../assets/images/ranks/titlePlace.png")}
                    />
                  </div>
                  <div className="absolute text-center text-white text-[15px] font-black  leading-[15px] text-shadow-black-sm">
                    {userRank.myRankLvl} Rank
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-white text-xl font-black leading-tight text-shadow-black-sm">
                  {userRank.myRankLvl} Rank
                </div>
                <div className="text-white text-xs font-medium  leading-3">
                  {t(`ranks.purchase`)}
                </div>
              </div>
              <div className="w-14 h-14">
                <img
                  className="w-full h-full transform scale-x-[-1]"
                  src={require(`../../assets/images/chests/close/${userRank.myRankLvl}.png`)}
                />
              </div>
            </div>

            <div className="flex justify-between items-center p-4 gap-2">
              <div className="flex justify-center items-center w-full ">
                {nextRank ? (
                  <div className="w-full relative ml-5">
                    <ProgressBar
                      current={userRank.currentRankpoints}
                      max={nextRank.rankPoints!.value}
                    />
                    <div className="absolute w-12 h-12 -left-6 -top-[65%]">
                      <img
                        className="w-full h-full"
                        src={require("../../assets/images/ranks/rankProgress.png")}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-[#fffe2e] ">Max Level</div>
                )}
              </div>
              <PopupButton
                type="blue"
                onClick={() => {
                  navigateTo("/island");
                  setTimeout(() => {
                    setShopOpen(true);
                  }, 500);
                }}
              >
                <div className="text-center text-[#ffefcb] text-base font-black  leading-none px-5 w-full">
                  Get Point
                </div>
              </PopupButton>
            </div>
            <div className="h-[3px] bg-[#1b1514] mx-4 "></div>
            <div className="h-[3px] bg-[#3f2626] mx-4 "></div>

            {userRank.myRankLvl < selectedRank.lvl.value ? (
              <div className="flex justify-center items-center p-4">
                <div className="relative">
                  <div className="w-20 h-5">
                    <img
                      src={require("../../assets/images/ranks/lockedRank.png")}
                      alt="Locked Rank"
                    />
                  </div>
                  <div className="absolute top-[5px] left-4 text-shadow-black-sm text-white text-[13px] font-normal  uppercase leading-[13px] tracking-wide">
                    {t(`ranks.locked`)}
                  </div>
                  <div className="absolute -top-3 -left-9">
                    <div className="relative w-12 h-12 ">
                      <img
                        className="w-full h-full"
                        src={require(`../../assets/images/ranks/${selectedRank.lvl.value}.png`)}
                      />
                      <div className="flex justify-center items-center absolute w-full bottom-0">
                        <div className="w-9 h-3">
                          <img
                            className="w-full h-full"
                            src={require("../../assets/images/ranks/titlePlace.png")}
                          />
                        </div>
                        <div className="absolute text-center text-white text-[8.57px] font-black  leading-[8.57px] text-shadow-black-sm">
                          {selectedRank.lvl.value} Rank
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="py-4 px-10">
              <div className="flex flex-col gap-4 overflow-y-auto max-h-[40vh]">
                {activeBonuses.map((bonus) => (
                  <div className="flex justify-between items-center">
                    {/* Внутрішній контейнер для зображення та тексту */}
                    <div className="flex items-center">
                      {/* Умовне відображення зображення */}
                      {!bonus.isOpen ? (
                        <div className="relative">
                          <div className="w-20 h-5">
                            <img
                              src={require("../../assets/images/ranks/lockedRank.png")}
                              alt="Locked Rank"
                            />
                          </div>
                          <div className="absolute top-[5px] left-4 text-shadow-black-sm text-white text-[13px] font-normal  uppercase leading-[13px] tracking-wide">
                            {t(`ranks.locked`)}
                          </div>
                        </div>
                      ) : bonus.isOpen && bonus.isNew ? (
                        <div className="relative">
                          <div className="w-12 h-5">
                            <img
                              src={require("../../assets/images/ranks/newRank.png")}
                              alt="New Rank"
                            />
                          </div>
                          <div className="absolute top-[4px] left-2 text-shadow-black-sm text-white text-[13px] font-normal  uppercase leading-[13px] tracking-wide">
                            {t(`ranks.new`)}
                          </div>
                        </div>
                      ) : null}

                      {/* Текст з умовним відступом */}
                      <div
                        className={`${
                          !bonus.isOpen || (bonus.isOpen && bonus.isNew)
                            ? "ml-2"
                            : ""
                        } ${
                          bonus.isOpen ? "" : "opacity-30"
                        } text-white text-sm font-black leading-[14px]`}
                      >
                        {t(`ranks.${bonus.type}`)}
                      </div>
                    </div>

                    {/* Відображення суми */}
                    <div
                      className={`${
                        bonus.isOpen
                          ? "text-[#47c77a]"
                          : "opacity-30 text-[#e1c78c]"
                      } text-sm font-black leading-[14px] pl-2`}
                    >
                      {bonus.amount as number}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-col p-4 gap-4 z-10">
              {currentOffer ? (
                <Offer
                  key={currentOffer.lvl}
                  onClick={() => handleClaimFreePack(currentOffer.lvl)}
                  id={currentOffer.lvl}
                  offerType={OfferType.freeRankOffer}
                  resourceList={selectedRank.oneTimeRewards.value.map(
                    (item, index) => ({
                      resourceType: item.rewardType.value,
                      resourceId: item.rewardId.value,
                      isEnabled: index === 0,
                      layout: "vertical",
                      amount: defineCountString(item.amount.value),
                    })
                  )}
                  layout="slim"
                  type="blue"
                  label="corner"
                />
              ) : null}
              {rankOffer ? (
                <Offer
                  onClick={() => {
                    confirmPurchase(rankOffer.id);
                  }}
                  offerType={rankOffer.offerType}
                  key={0}
                  id={rankOffer.id}
                  resourceList={rankOffer.resourceList}
                  layout={rankOffer.layout}
                  type={rankOffer.type}
                  label={rankOffer.label}
                  kitsu={rankOffer.kitsu}
                  price={rankOffer.price}
                  deadline={rankOffer.deadline}
                />
              ) : null}
            </div>
            <SuccessOverlay isSuccess={isSuccessPurchase} />
            <div className="z-10 absolute top-1/2 left-1/2 w-full transform -translate-x-1/2 -translate-y-1/2 flex justify-between items-center space-x-2">
              {prevRank ? (
                <button
                  onClick={() => handleLevelChange(-1)}
                  disabled={!prevRank}
                  className="h-[100px] pl-2 pr-1 bg-[#452b31] rounded-tr-[63px] rounded-br-[67px] justify-end items-center gap-2.5 inline-flex"
                >
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.0823 1.07009L1.04416 12L21.0823 22.9299L17.1997 12.1697L17.1385 12L17.1997 11.8303L21.0823 1.07009Z"
                      fill="white"
                      stroke="#452B31"
                    />
                  </svg>
                </button>
              ) : (
                <div></div>
              )}
              {nextRank && (
                <button
                  onClick={() => handleLevelChange(1)}
                  disabled={!nextRank}
                  className="h-[100px] pl-2 pr-1 bg-[#452b31] rounded-tl-[63px] rounded-bl-[67px] justify-end items-center gap-2.5 inline-flex"
                >
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.917673 1.07009L20.9558 12L0.917672 22.9299L4.80026 12.1697L4.86149 12L4.80026 11.8303L0.917673 1.07009Z"
                      fill="white"
                      stroke="#452B31"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>No data</div>
        )}
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
