import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card } from "../../components/Card";
import { CardProps } from "../../interfaces/card";
import { useScroll } from "../../utils/ScrollContext";
import { PopupButton } from "../../components/PopupButton";
import { GoldIco } from "../../layout/components/HeaderFarm/components/ResourceCard";
import { useTelegram } from "../../hooks/useTelegram";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import { TitleField } from "../../containers/Room";
import { useSoundService } from "../../utils/soundService";
import { getBalance } from "../../endpoints/farmMock";
import { Offer } from "./components";
import { OfferProps } from "./components/Offer";
import { motion } from "framer-motion";
import { ConfigOffer, OfferType } from "../../mock/offers";
import { OfferResourceProps } from "./components/OfferResources";
import { usePurchase } from "../../hooks/usePurchase";
import { SuccessOverlay } from "../../components/SuccessOverlay";
import {
  getAvailableOffers,
  getUserOffers,
} from "../../endpoints/getProfileInfo";

enum ActiveTab {
  kitsune,
  dailySale,
  packStore,
  GrowthFund,
}

export const Shop = ({ gold, close }: { gold: number; close: () => void }) => {
  const { t } = useTranslation();
  const { tg, userId } = useTelegram();
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.kitsune);
  const [buyModalOpen, setBuyModal] = useState(false);
  const { setScrollable } = useScroll();
  const scrollableElRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [purchasedOfferIds, setPurchasedOfferIds] =
    useState<Set<number> | null>(null);
  const [availableOfferIds, setAvailableOfferIds] =
    useState<Set<number> | null>(null);
  // const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  // const [isSuccessPurchase, setIsSuccessPurchase] = useState(false);
  // const [selectedOffer, setSelectedOffer] = useState({} as any);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { playSound } = useSoundService();
  const {
    offers: { variables: allOffers },
  } = appConfig;

  const {
    selectedOffer,
    isPurchaseLoading,
    isSuccessPurchase,
    confirmPurchase,
    resetPurchase,
  } = usePurchase();

  useEffect(() => {
    setScrollable(true, scrollableElRef);

    return () => {
      setScrollable(false);
    };
  }, [setScrollable]);

  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (userOffersData) {
        setPurchasedOfferIds(
          new Set(
            userOffersData.map(
              (o: { offerId: number; offerType: number; quantity: number }) =>
                o.offerId
            )
          )
        );
      }

      const userAvailableOffersData = await getAvailableOffers({
        clientId: userId,
      });

      if (userAvailableOffersData) {
        setAvailableOfferIds(
          new Set(
            userAvailableOffersData.map(
              (o: { offerId: number; offerType: number }) => o.offerId
            )
          )
        );
      }
    };
    getUserOffersData();
  }, []);

  // const buyModalHandler = (offerId: number) => {
  //   const offer = allOffers.find((offer: ConfigOffer) => offer.offerId.value === offerId);
  //   if (offer) {
  //     initiatePurchase(offerId);
  //   }
  // };

  // const telegramPurchaseHandler = async () => {
  //   console.log("selectedOffer", selectedOffer);
  //   setIsPurchaseLoading(true);
  //   playSound("button");
  //   const invoiceLink = await purchaseTelegram({
  //     offerId: selectedOffer.offerId.value,
  //     offerType: selectedOffer.offerType.value,
  //     title:
  //       selectedOffer?.title ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //     description:
  //       selectedOffer?.description ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //     label:
  //       selectedOffer?.title ??
  //       selectedOffer.items.value[0].amount.value.toLocaleString() + " $KITSU",
  //   });

  //   tg.openInvoice(invoiceLink, (status: string | undefined) => {
  //     if (status === "paid") {
  //       setIsSuccessPurchase(true);
  //       setBuyModal(false);
  //     }

  //     setIsPurchaseLoading(false);
  //     setTimeout(async () => {
  //       await getBalance({ clientId: `${userId}` });
  //       setIsSuccessPurchase(false);
  //     }, 1500);
  //   });
  // };

  useEffect(() => {}, [isLoading]);

  const headerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  // Варіанти для першого внутрішнього елемента (зліва)
  const leftElementVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: { x: 0, y: 0, opacity: 1 },
  };

  // Варіанти для другого внутрішнього елемента (справа)
  const rightElementVariants = {
    hidden: { x: 100, opacity: 0 },
    visible: { x: 0, y: 0, opacity: 1 },
  };

  const shopVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const containerVariants = {
    hidden: {}, // Не змінюємо нічого
    visible: {
      transition: {
        staggerChildren: 0.2, // Затримка 0.2 секунд між анімаціями дочірніх елементів
      },
    },
  };

  // Варіанти для дочірніх елементів
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const modalVariants = {
    hidden: { y: 282, opacity: 0 }, // Початковий стан - нижче екрану
    visible: { y: 0, opacity: 1 }, // Кінцевий стан - на місці
  };

  const updatedKitsuneOffers = useMemo(() => {
    const allKitsuneOffers = allOffers.filter(
      (offer: ConfigOffer) => offer.offerType.value == OfferType.kitsune
    );
    if (purchasedOfferIds) {
      const processedOffers = allKitsuneOffers.map((offer: ConfigOffer) => {
        const isPurchased = purchasedOfferIds.has(offer.offerId.value);
        return {
          ...offer,
          firstBuy: !isPurchased,
        };
      });
      return processedOffers.sort((a: ConfigOffer, b: ConfigOffer) => {
        // Перевіряємо, чи `stars.value` існує і є числом
        const starsA = typeof a.stars.value === "number" ? a.stars.value : 0;
        const starsB = typeof b.stars.value === "number" ? b.stars.value : 0;
        return starsB - starsA; // Спадання: більше зірок першими
      });
    }

    return allKitsuneOffers;
  }, [purchasedOfferIds, allOffers]);

  const updatedallPackStoreOffers = useMemo(() => {
    const allPackStoreOffers = allOffers.filter(
      (offer: ConfigOffer) =>
        offer.offerType.value !== OfferType.kitsune &&
        offer.offerType.value !== OfferType.rankOffer &&
        offer.offerType.value !== OfferType.freeRankOffer
    );

    if (availableOfferIds) {
      return allPackStoreOffers.map((offer: ConfigOffer) => {
        const isAvailable = availableOfferIds.has(offer.offerId.value);
        return {
          ...offer,
          isClaimed: !isAvailable,
        };
      });
    }

    return allPackStoreOffers;
  }, [availableOfferIds, allOffers]);

  const mappedAllPackStoreOffers = updatedallPackStoreOffers.map(
    (offer: ConfigOffer): OfferProps => {
      const currentTime = new Date();

      return {
        id: offer.offerId.value,
        resourceList: offer.items.value.map(
          (item, index): OfferResourceProps => {
            return {
              resourceType: item.rewardType.value,
              resourceId: item.rewardId.value,
              isEnabled: index === 0 ? true : false,
              layout: "vertical",
              amount: defineCountString(item.amount.value),
            };
          }
        ),
        offerType: offer.offerType.value,
        layout: "wide",
        type: "gold",
        label: "top",
        price: defineCountString(offer.stars.value),
        deadline: new Date(
          currentTime.getTime() + 16 * 60 * 60 * 1000
        ).toISOString(),
        isClaimed: offer.isClaimed,
      };
    }
  );

  function defineCountString(count: number) {
    if (Math.floor(count / 1000000) > 0) {
      return Math.round(count / 100000) / 10 + "M";
    } else if (Math.floor(count / 1000) > 0) {
      return Math.round(count / 100) / 10 + "K";
    } else {
      return count.toFixed(0);
    }
  }

  const containerVariantsPacks = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Затримка між появою елементів
      },
    },
  };

  const itemVariantsPacks = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {isLoading && (
        <div className="z-40">
          <motion.div
            variants={headerVariants}
            initial="hidden"
            animate="visible"
            transition={{ type: "spring", stiffness: 50, duration: 0.5 }}
            className="absolute top-0 left-0 right-0 z-10 flex justify-center"
          >
            <img
              src={require("../../assets/images/shopHeader.png")}
              alt="Shop Header"
            />
            <motion.div
              initial="hidden"
              animate="visible"
              variants={headerVariants}
              transition={{
                type: "spring",
                stiffness: 50,
                duration: 0.5,
                delay: 0.2,
              }}
              className="top-2 right-0 left-0 px-4 text-white absolute w-full text-end flex justify-between items-center z-[11]"
            >
              {/* Лівий елемент: Кнопка назад та назва магазину */}
              <motion.div
                variants={leftElementVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: "spring",
                  stiffness: 50,
                  duration: 0.5,
                  delay: 0.3,
                }}
                className="flex justify-center items-center gap-2"
              >
                <div className="z-[2]">
                  <HandleBackButton onClick={close} />
                </div>
                <div className="text-center text-white text-[28px] font-black leading-7 text-shadow">
                  {t("shop.name")}
                </div>
              </motion.div>

              {/* Правий елемент: Баланс */}
              <motion.div
                variants={rightElementVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  type: "spring",
                  stiffness: 50,
                  duration: 0.5,
                  delay: 0.5,
                }}
                className="bg-[#312d27] border border-[#18191a] px-3 py-1 rounded-[6px] flex justify-center items-center"
              >
                <div className="mr-2 inline-block text-[16px] text-white text-sm font-medium leading-[14px]">
                  Balance:
                </div>
                <div className="flex">
                  <div className="flex justify-center items-center text-center mr-[4px]">
                    <img
                      src={require("../../assets/images/kitsu-icon.png")}
                      className="w-[20px] h-[20px]"
                      alt="Kitsu Icon"
                    />
                  </div>
                  <div className="flex flex-col justify-end text-right text-white text-lg font-black leading-[18px]">
                    {gold}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </motion.div>
          <div className="fixed top-0 left-0 w-full h-full bg-black"></div>

          <motion.div
            className="w-full fixed top-[120px] left-0 bottom-0 right-0 z-5 flex items-center justify-center"
            variants={shopVariants}
            initial="hidden"
            animate="visible"
            transition={{
              type: "spring",
              stiffness: 50,
              duration: 0.7,
              delay: 0.3,
            }}
          >
            <div className="w-full absolute -top-11 flex justify-center gap-x-4 mb-4">
              {/* Кнопки магазину */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px]
              ${activeTab !== ActiveTab.kitsune ? "opacity-40" : ""}`}
                onClick={() => {
                  setActiveTab(ActiveTab.kitsune);
                  playSound("button");
                }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4 }}
              >
                {activeTab === ActiveTab.kitsune ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-selected-card.png")}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      KITSU
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-no-selected-card.png")}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      KITSU
                    </div>
                  </div>
                )}
              </motion.button>
              {/*pack store */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px]
              `}
                onClick={() => {
                  setActiveTab(ActiveTab.packStore);
                  playSound("button");
                }}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.6 }}
              >
                {activeTab === ActiveTab.packStore ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-selected-card.png")}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Pack Store
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-no-selected-card.png")}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full  z-10 text-center text-white text-sm font-black leading-[14px]">
                      Pack Store
                    </div>
                  </div>
                )}
              </motion.button>
              {/*daily */}
              <motion.button
                className={`w-20 h-12 border border-[#18191a] bg-[#4c3f2f] shadow-inner-sm-white rounded-[2px] p-[1px]
              `}
                // onClick={() => {
                //   setActiveTab(ActiveTab.dailySale);
                //   playSound("button");
                // }}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.5 }}
              >
                {activeTab === ActiveTab.dailySale ? (
                  <div className="w-18 h-11 relative">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-selected-card.png")}
                      alt="Selected Card"
                    />
                    <div className="absolute -top-2 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Daily Sale
                    </div>
                  </div>
                ) : (
                  <div className="w-18 h-11 relative opacity-40">
                    <img
                      className="w-full h-full absolute -top-1"
                      src={require("../../assets/images/shop-no-selected-card.png")}
                      alt="No Selected Card"
                    />
                    <div className="absolute -top-1 flex justify-center items-center w-full h-full z-10 text-center text-white text-sm font-black leading-[14px]">
                      Daily Sale
                    </div>
                  </div>
                )}
              </motion.button>
            </div>

            {/* Вміст магазину */}
            <div className="relative w-full h-full bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
              <div className="w-full h-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
                <div className="relative w-full h-full bg-[#201b18] rounded-[1px] p-3 shadow-inner-sm-black overflow-hidden">
                  {activeTab === ActiveTab.dailySale && (
                    <div
                      className={`absolute h-[400px] w-full bg-no-repeat top-0 left-0 bg-cover bg-top 
                  bg-[url('./assets/images/daily-sale-background.jpg')]`}
                    ></div>
                  )}

                  {activeTab === ActiveTab.kitsune && (
                    <>
                      <motion.div
                        className="grid grid-cols-3 gap-3 mb-3 text-white overflow-y-auto pt-3 pl-3 pr-3 max-h-[calc(100%-20px)]"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                      >
                        {updatedKitsuneOffers.map((offer: ConfigOffer, index: number) => {
                          return (
                            <motion.div
                              key={index}
                              className="relative bg-gradient-to-r from-[#eba36c] to-[#fecb54] rounded shadow-inner border-2 border-[#fffe2e] cursor-pointer aspect-square"
                              onClick={() =>
                                confirmPurchase(offer.offerId.value)
                              }
                              variants={itemVariants}
                              transition={{
                                type: "spring",
                                stiffness: 50,
                                duration: 0.5,
                              }}
                            >
                              {offer.firstBuy ? (
                                <>
                                  {/* Магазинний квиток */}
                                  <div className="absolute -top-2 -right-2 w-6 h-6 z-20">
                                    <img
                                      className="w-full h-full"
                                      src={require("../../assets/images/shopTicket.png")}
                                      alt="Shop Ticket"
                                    />
                                  </div>

                                  {/* Позначка x2 */}

                                  <div className="z-20 absolute top-1 right-0 origin-top-left rotate-[-35deg] text-center text-white text-xs font-extrabold leading-3">
                                    x2
                                  </div>
                                </>
                              ) : null}

                              {/* Основний контент */}
                              <div className="flex flex-col justify-center items-center relative p-2">
                                {/* Фон з градієнтом */}
                                <div
                                  className="flex z-10 justify-center items-center absolute top-1 overflow-hidden flex-shrink-0 min-h-[2em] w-full"
                                  style={{
                                    background:
                                      "radial-gradient(circle, rgba(50, 50, 50, 0.9) 0%, rgba(50, 50, 50, 0) 90%)",
                                  }}
                                >
                                  {/* Верхня рамка */}
                                  <div
                                    className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                                    style={{
                                      background:
                                        "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
                                    }}
                                  ></div>

                                  {/* Нижня рамка */}
                                  <div
                                    className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                                    style={{
                                      background:
                                        "linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
                                    }}
                                  ></div>

                                  {/* Вміст елемента */}
                                  <div className="flex items-center justify-center gap-1">
                                    <img
                                      src={require("../../assets/images/kitsu-icon.png")}
                                      className="w-5 h-5"
                                      alt="Kitsu Icon"
                                    />
                                    <div className="text-center text-white text-sm font-black leading-3">
                                      {offer?.items.value[0].amount?.value.toLocaleString()}
                                    </div>
                                  </div>
                                </div>
                                {/* Зображення пропозиції */}
                                <img
                                  src={require(`../../assets/images/offers/offer-${offer.offerId.value}.png`)}
                                  className="transform scale-80 pt-3 object-contain h-16 w-16"
                                  alt={`Offer ${offer.offerId.value}`}
                                />
                                {/* Кнопка Popup */}
                                <PopupButton type="gold">
                                  <div className="flex items-center gap-1">
                                    <img
                                      className="w-[16px] h-[16px]"
                                      src={require("../../assets/images/shop/offer/offer-star.png")}
                                      alt=""
                                    />
                                    <div className="text-white text-stroke-regular text-[14px] leading-[16px] font-[800]">
                                      {defineCountString(offer?.stars?.value)}
                                    </div>
                                  </div>
                                </PopupButton>
                              </div>
                            </motion.div>
                          );
                        })}
                      </motion.div>
                    </>
                  )}
                  {activeTab === ActiveTab.dailySale && (
                    <div className="mb-3 text-white -mx-2 px-2 overflow-auto h-full">
                      <div className="relative h-[230px] mb-6">
                        <div className="flex items-center text-2xl z-[2] relative">
                          <img
                            src={require("../../assets/images/info-icon.png")}
                            className="w-6 h-6 mr-2"
                            alt="Info Icon"
                          />
                          Daily Sale
                        </div>
                        <div className="flex items-center ml-8 text-base z-[2] relative">
                          <img
                            src={require("../../assets/images/times-icon.png")}
                            className="w-4 h-4 mr-1"
                            alt="Times Icon"
                          />
                          16:06:12
                        </div>
                        <div className="absolute ml-8 bottom-[76px] left-0">
                          <img
                            src={require("../../assets/images/daily-freebie-icon.png")}
                            className="w-[66px] h-auto"
                            alt="Daily Freebie Icon"
                          />
                          <div className="absolute whitespace-nowrap text-[14px] text-stroke-regular text-[#FFF3DB] -bottom-1">
                            Daily Freebie
                          </div>
                        </div>
                        <img
                          src={require("../../assets/images/shop-hero-buy.png")}
                          className="absolute top-2 right-0 w-[190px] h-auto"
                          alt="Shop Hero Buy"
                        />

                        <div className="absolute right-1 bottom-[62px] text-[28px] text-stroke-regular uppercase font-[600]">
                          Hero name
                        </div>
                        <div className="absolute bottom-0 h-16 -left-3 -right-3 bg-gradient-to-r from-[#e4e081] to-[#fd9051] border-[1px] border-l-0 border-r-0 border-[#534037]">
                          <div className="flex h-full items-center justify-between">
                            <div className="w-[84px] min-w-[84px] text-center">
                              <img
                                src={require("../../assets/images/shop-discount-flag.png")}
                                className="absolute w-[96px] h-[46px] top-2"
                                alt="Discount Flag"
                              />
                              <div className="text-stroke-regular z-[2] relative text-2xl uppercase">
                                5765%
                              </div>
                            </div>
                            <div className="ml-4 text-[#372C25] text-base leading-[1.2]">
                              Bundle all packs for a discount
                            </div>
                            <div className="mx-2 whitespace-nowrap">
                              <PopupButton
                                type="gold"
                                onClick={() => confirmPurchase(111)}
                                width="auto"
                              >
                                <div className="text-stroke-regular relative text-[18px] text-[#54320E] mx-auto leading-[1]">
                                  <div className="relative z-[1] text-[13px]">
                                    7 USD
                                  </div>
                                  <div
                                    className="absolute top-0.5 mx-auto leading-[1] h-full w-full bg-[length:50px]
                                bg-[url('./assets/images/crossed-out-old-price.png')] z-[2] bg-no-repeat bg-center"
                                  ></div>
                                </div>
                                <div className="text-stroke-regular text-[18px] leading-[1]">
                                  4,99 USD
                                </div>
                              </PopupButton>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4 mb-3 text-white overflow-auto h-[calc(100%-166px)]">
                        <div className="w-full h-[200px] bg-[#50493f] border-[1px] border-black p-1 rounded-[2px] relative">
                          <div
                            className="absolute top-0 right-0 bg-[url('./assets/images/daily-sale-label-sale.png')]
                        bg-no-repeat bg-contain bg-right-top w-[40px] h-[36px]"
                          ></div>
                          <div
                            className="absolute top-[6px] right-[6px] text-[10px] font-[300] text-white rotate-[38deg]
                        text-center pl-[4px] leading-[1] z-[2]"
                          >
                            55%
                          </div>
                          <div className="rounded-[1px] bg-[#c3b996] w-full h-full border-[1px] border-black p-2">
                            <div className="leading-[1] text-[14px] text-stroke-regular font-[600]">
                              <PopupButton
                                type="gold"
                                height="34px"
                                onClick={() => confirmPurchase(111)}
                              >
                                0,99 USD
                              </PopupButton>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-[200px] bg-[#50493f] border-[1px] border-black p-1 rounded-[2px] relative">
                          <div
                            className="absolute top-0 right-0 bg-[url('./assets/images/daily-sale-label-sale.png')]
                        bg-no-repeat bg-contain bg-right-top w-[40px] h-[36px]"
                          ></div>
                          <div
                            className="absolute top-[6px] right-[6px] text-[10px] font-[300] text-white rotate-[38deg]
                          text-center pl-[4px] leading-[1] z-[2]"
                          >
                            55%
                          </div>
                          <div className="rounded-[1px] bg-[#a3886a] w-full h-full border-[1px] border-black p-2">
                            <div className="leading-[1] text-[14px] text-stroke-regular font-[600]">
                              <PopupButton
                                type="gold"
                                height="34px"
                                onClick={() => confirmPurchase(111)}
                              >
                                0,99 USD
                              </PopupButton>
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-[200px] bg-[#50493f] border-[1px] border-black p-1 rounded-[2px] relative">
                          <div
                            className="absolute top-0 right-0 bg-[url('./assets/images/daily-sale-label-sale.png')]
                        bg-no-repeat bg-contain bg-right-top w-[40px] h-[36px]"
                          ></div>
                          <div
                            className="absolute top-[6px] right-[6px] text-[10px] font-[300] text-white rotate-[38deg]
                          text-center pl-[4px] leading-[1] z-[2]"
                          >
                            55%
                          </div>
                          <div className="rounded-[1px] bg-[#a3886a] w-full h-full border-[1px] border-black p-2">
                            <div className="leading-[1] text-[14px] text-stroke-regular font-[600]">
                              <PopupButton
                                type="gold"
                                height="34px"
                                onClick={() => confirmPurchase(111)}
                              >
                                0,99 USD
                              </PopupButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === ActiveTab.packStore && (
                    <motion.div
                      className="flex flex-col gap-4 overflow-x-auto h-[calc(100%-20px)] pt-2"
                      variants={containerVariantsPacks}
                      initial="hidden"
                      animate="visible"
                    >
                      {mappedAllPackStoreOffers.map(
                        (offer: OfferProps, index: number) => (
                          <motion.div
                            key={offer.id}
                            variants={itemVariantsPacks}
                          >
                            <Offer
                              onClick={() => confirmPurchase(offer.id)}
                              offerType={offer.offerType}
                              id={offer.id}
                              resourceList={offer.resourceList}
                              layout={offer.layout}
                              type={offer.type}
                              label={offer.label}
                              kitsu={offer.kitsu}
                              price={offer.price}
                              deadline={offer.deadline}
                              isClaimed={offer.isClaimed}
                            />
                          </motion.div>
                        )
                      )}
                    </motion.div>
                  )}
                </div>
              </div>
              <SuccessOverlay isSuccess={isSuccessPurchase} />
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};
