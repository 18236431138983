import { useTranslation } from "react-i18next";
import { useUtils } from "../../utils/navigateTo";
import { useTonAddress } from "@tonconnect/ui-react";
import { Tooltip } from "react-tooltip";
import { PopupButton } from "../PopupButton";

import ReactDOMServer from "react-dom/server";

export const ConnectWalletIcon = ({
  link,
  onClick,
  onClickToolTip,
  isToolTipOpen,
}: {
  onClick: () => void;
  isToolTipOpen: boolean;
  link?: string;
  onClickToolTip: () => void;
}) => {
  const { t } = useTranslation();
  const { navigateTo } = useUtils();

  const handleClick = () => {
    if (link) {
      navigateTo(link);
    }
  };

  return (
    <div
      className="relative w-12 h-12"
      data-tooltip-id="ConnectWalletTip"
      onClick={onClick}
      //   data-tooltip-content="Summon x more time to receive a new Hero or Hero shard."
    >
      {/* SVG елемент */}
      <div className="absolute flex justify-center items-center top-0 left-0 w-full h-full z-10">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="24"
            cy="24"
            r="23.5625"
            fill="url(#paint0_linear_6841_13837)"
            stroke="black"
            strokeWidth="0.875"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6841_13837"
              x1="24"
              y1="0"
              x2="24"
              y2="48"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFB74B" />
              <stop offset="1" stop-color="#DE7300" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {/* <div className="absolute flex justify-center items-center w-full h-full z-[15] ">
        <div className="w-12 h-12">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full "
          >
            <g filter="url(#filter0_i_2037_9987)">
              <circle cx="24" cy="24" r="22.75" fill="#1A273C" />
            </g>
            <circle
              cx="24"
              cy="24"
              r="23.1875"
              stroke="black"
              strokeWidth="0.875"
            />
            <defs>
              <filter
                id="filter0_i_2037_9987"
                x="0.375"
                y="0.375"
                width="47.25"
                height="47.25"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="2.1875" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_2037_9987"
                />
              </filter>
            </defs>
          </svg>
        </div>
      </div> */}
      <div className="absolute flex justify-center items-center w-full h-full z-[15] ">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_i_6841_13838)">
            <circle cx="20" cy="20" r="19" fill="#30A9D8" />
          </g>
          <circle
            cx="20"
            cy="20"
            r="19.4375"
            stroke="black"
            strokeWidth="0.875"
          />
          <defs>
            <filter
              id="filter0_i_6841_13838"
              x="0.125"
              y="0.125"
              width="39.75"
              height="39.75"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="2.1875" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_6841_13838"
              />
            </filter>
          </defs>
        </svg>
      </div>
      {/* Картинка */}
      <div className="absolute -top-1 left-0 w-full h-full z-20 flex justify-center items-center">
        <img
          src={require("../../assets/images/connectWalletIco.png")}
          alt="Connect Wallet Icon"
          className="w-[46px] h-[46px] object-contain"
        />
      </div>
      <div className="absolute bottom-0 z-30 flex justify-center items-center w-full text-outline-black text-center text-white text-[14px] font-black  leading-[12px]">
        Connect Wallet
      </div>

      <Tooltip
        isOpen={isToolTipOpen}
        id="ConnectWalletTip"
        place="left-end"
        style={{
          backgroundColor: "#ffffff",
          color: "#000000",
          border: "1px solid #000000",
          borderRadius: "8px",
          padding: "8px",
          textAlign: "center",
          maxWidth: "160px",
          wordWrap: "break-word",
          opacity: 1,
        }}
        clickable={true}
      >
        <div
          className="flex flex-col gap-2"
          onClick={() => console.log("Tooltip clicked!")}
        >
          <div className="w-[124px] text-center text-black text-xs font-black  leading-3">
            Earn rewards <br />
            connect your Wallet
          </div>

          <PopupButton type={"blue"} onClick={onClickToolTip}>
            GO
          </PopupButton>
        </div>
      </Tooltip>
    </div>
  );
};
