export enum QuestsEvents {
    open_rare_magic_scroll,
    open_magic_scroll,
    earn_stone,
    spend_stone,
    collect_resources,
    use_hero_key,
    earn_kitsu,
    spend_kitsu,
    upgrade_tier,
    upgrade_lvl,
    win_boss,
    play_cards,
    open_legendary_magic_scroll,
    use_5_min_speed_up,
    tutorial
}