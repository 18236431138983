import React, { useState, useEffect } from "react";
import { useUtils } from "../../utils/navigateTo";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";
import * as Sentry from "@sentry/react";
import {useTelegram} from "../../hooks/useTelegram";

export const Home = () => {
  const { navigateTo } = useUtils();
  const { userId } = useTelegram();

  useEffect(() => {
    navigateTo("/island");
  }, []);

  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
        const errorData: RuntimeErrorData = { message: "" };

        if (error instanceof Error) {
          errorData.message = error.message;
          errorData.stack = componentStack;
        }

        if (componentStack) { errorData.stack = componentStack }

        return ErrorReportingService.reportError({
          type: ErrorType.runtime,
          errorData,
          clientId: userId,
        });
      }}
      fallback={({ error, resetError, componentStack, eventId }) => {
        return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
      }}
    >
      <></>
    </Sentry.ErrorBoundary>
  );
};
