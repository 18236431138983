import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTelegram } from "../../hooks/useTelegram";
import {
  claimQuestReward,
  claimTaskReward,
  getQuests,
  getSocialTasks,
  setTaskCompleted,
  SocialTaskCategory,
} from "../../endpoints/userQuests";
import {
  clearSpecialTask,
  completeTask,
  QuestTask,
  setSpecialTask,
  SocialTask,
  TaskStatus,
} from "../../app/features/questsSlice";
import { useUtils } from "../../utils/navigateTo";
import { QuestType } from "../../containers/QuestsList";
import { QuestsList } from "../../containers";
import { AppDispatch, RootState } from "../../app/store";
import { HandleBackButton } from "../../layout/components/HeaderCave/components";
import PageTransition from "../../containers/Router/components/PageTransition";
import { PopupButton } from "../../components/PopupButton";
import { TitleField } from "../../containers/Room";
import { useTranslation } from "react-i18next";
import { QuestBlockInnerSkeleton, QuestBlockSkeleton } from "../../components";
import { subscriptionCheck } from "../../endpoints/botEndpoints";
import { Resources } from "../../enums/resources";
import { handleRewardResult } from "../../containers/QuestsList/components/SocialBlock/rewardParse";
import { motion } from "framer-motion";
import { useSessionStorage, useWindowSize } from "@uidotdev/usehooks";
import Confetti from "react-confetti";
import { saveFarmTutorialProgress } from "../../app/features/farmTutoralSlice";
import { TutorialFarmSave, TutorialFarmStage } from "../../interfaces/tutorial";
import { PointerHand } from "../../components/PointerHand";
import { TutorialTooltip } from "../../components/TutorialTooltip";
import { APP_ENV } from "../../config";
import ReactGA from "react-ga4";
import * as amplitude from "@amplitude/analytics-browser";
import { PopupWindow } from "../../components/PopupWindow";
import * as Sentry from "@sentry/react";
import {ErrorType, RuntimeErrorData} from "../../interfaces/error";
import {ErrorReportingService} from "../../services/errorReportingService";
import {ErrorComponent} from "../../components";

export const Spinner = () => {
  return (
    <div className="flex flex-col items-center justify-center p-2 h-full w-full">
      <svg
        className="animate-spin h-48 w-48 text-[#332b26] "
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8v8H4z"
        ></path>
      </svg>
      <span className="pt-2 text-gray-700">Checking progress</span>
    </div>
  );
};

export const SuccessAnimation: React.FC<{ text: string }> = ({ text }) => {
  const { width, height } = useWindowSize();
  const [confettiPieces, setConfettiPieces] = useState(200); // Кількість конфетті

  useEffect(() => {
    // Налаштування кількості конфетті залежно від ширини екрану
    if (width && width < 300) {
      setConfettiPieces(100);
    } else {
      setConfettiPieces(200);
    }
  }, [width]);

  return (
    <motion.div
      className="flex flex-col justify-center items-center relative p-4"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Конфетті */}
      <Confetti
        width={width ? width : 100}
        height={height ? height : 100}
        numberOfPieces={confettiPieces}
        recycle={false}
        gravity={0.3}
        run={true}
        colors={["#fde047", "#f87171", "#34d399", "#60a5fa", "#f472b6"]} // Додаткові кольори
      />

      <motion.div
        className="w-full flex flex-col justify-center items-center bg-[#8e855e] bg-opacity-80 backdrop-filter backdrop-blur-lg m-4 p-4 rounded-lg shadow-lg"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Анімація чек-маркера */}
        <svg
          className="w-16 h-16 text-[#ffc923] mb-4 drop-shadow-lg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-label="Успішно"
        >
          <motion.path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1 }}
          />
        </svg>
        {/* Текстове підтвердження */}
        <motion.p
          className="text-xl drop-shadow text-center text-[#ffc923] text-[25px] font-black font-['Sofia Sans Semi Condensed'] uppercase leading-[25px] tracking-wide"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          {text}
        </motion.p>
      </motion.div>
    </motion.div>
  );
};

export const Quests = () => {
  const { t } = useTranslation();
  const { navigateTo } = useUtils();

  const dispatch = useDispatch<AppDispatch>();

  const updateSave = (save: TutorialFarmSave) => {
    //@ts-ignore
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };

  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const {
    quests: { variables: allQuests },
  } = appConfig;

  const dailyQuests = useSelector((state: RootState) => state.quests.quests);

  const socialTask = useSelector((state: RootState) => state.quests.socialTask);

  const specialTask = useSelector(
    (state: RootState) => state.quests.specialTask
  );

  const allQuestsById =
    Array.isArray(allQuests) && allQuests.length > 0
      ? allQuests.reduce((acc: any, quest: any) => {
          acc[quest.questId.value] = quest;
          return acc;
        }, {})
      : {};

  const allSocialTaskById =
    Array.isArray(socialTask) && socialTask.length > 0
      ? socialTask.reduce((acc: any, task: any) => {
          acc[task.id] = task;
          return acc;
        }, {})
      : {};

  const onClickHandlers = {
    [TaskStatus.NotDone]: (quest: any) => {
      console.log(`Task ${quest.questId.value} is not done yet.`);
      if (quest.conditions.value[0].event.value === "upgrade_tier") {
        updateSave({
          stage: TutorialFarmStage.clickOnHero2,
        });
        navigateTo("/heroes");
      } else {
        navigateTo("/island");
      }
    },
    [TaskStatus.Done]: async (quest: any) => {
      console.log(`QEST IN DONE HANDLER`, quest);
      console.log(`Task ${quest.questId.value} is done! Claim your reward.`);

      if (APP_ENV === "production") {
        ReactGA.event({
          category: "Quests",
          action: "Quest task #" + quest?.id + ": " + quest?.name,
        });
        amplitude.track("Quest task #" + quest?.id + ": " + quest?.name, {
          group: "Quests",
        });
      }

      const claim = await claimQuestReward({
        clientId: userId,
        questId: quest.questId.value,
      });

      if (claim) {
        setShowSuccess(true);

        setTimeout(() => {
          setShowSuccess(false);

          setTimeout(() => {
            dispatch(clearSpecialTask());
          }, 1000);
        }, 3000); // Анімація триватиме 2 секунди
      }
      if (quest.conditions.value[0].event.value === "tutorial") {
        updateSave({
          stage: TutorialFarmStage.finishQuestTutor,
        });
      } else if (quest.conditions.value[0].event.value === "upgrade_tier") {
        updateSave({
          stage: TutorialFarmStage.finishTierTutorial,
        });
      }
    },
    [TaskStatus.Claimed]: (quest: any) => {
      console.log(`Reward for task ${quest.questId.value} already claimed.`);
    },
  };

  const [openModal, setOpenModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // Новий стан для анімації успіху

  const handleClaimSpecialTaskBonuses = async () => {
    if (specialTask) {
      const claim = await claimTaskReward({
        clientId: userId,
        taskId: specialTask.id,
      });

      if (claim) {
        if (APP_ENV === "production") {
          ReactGA.event({
            category: "Quests",
            action: "Quest task #" + specialTask?.id + ": " + specialTask?.name,
          });
          amplitude.track(
            "Quest task #" + specialTask?.id + ": " + specialTask?.name,
            { group: "Quests" }
          );
        }
        setOpenModal(false);
        setTimeout(() => {
          setShowSuccess(true);
          dispatch(clearSpecialTask());

          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        }, 250);
      }
    }
  };
  const onClickHandlersSocialTask = {
    [TaskStatus.NotDone]: async (quest: SocialTask) => {
      console.log(`Task ${quest} is not done yet.`);
      console.log(`Task ${quest.link} is not done yet.`);

      if (quest.link) {
        dispatch(setSpecialTask(quest));
        setOpenModal(true);
      } else {
        console.warn("Link is not provided for this quest.");
      }
    },
    [TaskStatus.Done]: async (quest: SocialTask) => {
      console.log(`Task ${quest.id} is done! Claim your reward.`);

      if (APP_ENV === "production") {
        ReactGA.event({
          category: "Quests",
          action: "Quest task #" + quest?.id + ": " + quest?.name,
        });
        amplitude.track("Quest task #" + quest?.id + ": " + quest?.name, {
          group: "Quests",
        });
      }

      const claim = await claimTaskReward({
        clientId: userId,
        taskId: quest.id,
      });

      if (claim) {
        setShowSuccess(true);

        setTimeout(() => {
          setShowSuccess(false);

          setTimeout(() => {
            dispatch(clearSpecialTask());
          }, 1000);
        }, 3000); // Анімація триватиме 2 секунди
      }
    },
    [TaskStatus.Claimed]: (quest: SocialTask) => {
      console.log(`Reward for task ${quest.id} already claimed.`);
    },
  };

  const combinedQuests = (dailyQuests ?? [])
    .map((dq) => {
      const correspondingQuest = allQuestsById[dq.taskId];
      if (correspondingQuest) {
        console.log("corresponding quest", correspondingQuest);
        const onClick = onClickHandlers[dq.status]
          ? () => onClickHandlers[dq.status](correspondingQuest)
          : () => {
              console.log(`Unknown status for task ${dq.taskId}.`);
            };

        return {
          ...correspondingQuest,
          status: dq.status,
          currentProgress: dq.currentProgress,
          onClick,
        };
      } else if (dq.taskId === 999) {
        console.log("MY QUEST", dq);
        const onClick = onClickHandlers[dq.status]
          ? () => onClickHandlers[dq.status](tutorQuest)
          : () => {
              console.log(`Unknown status for task ${dq.taskId}.`);
            };
        const tutorQuest = {
          conditions: {
            description: "",
            value: [
              {
                event: {
                  value: "tutorial",
                  description: "",
                },
                amount: {
                  value: "",
                  description: "",
                },
              },
            ],
          },
          isVIP: {
            value: false,
            description: "",
          },
          questId: {
            description: "",
            value: 999,
          },
          rewards: {
            description: "",
            value: [
              {
                amount: {
                  value: 6500,
                  description: "",
                },
                multiplyByShirine: {
                  value: false,
                  description: "",
                },
                rewardId: {
                  value: 2,
                  description: "",
                },
                rewardType: {
                  value: 2,
                  description: "",
                },
              },
              {
                amount: {
                  value: 1,
                  description: "",
                },
                multiplyByShirine: {
                  value: false,
                  description: "",
                },
                rewardId: {
                  value: 1,
                  description: "",
                },
                rewardType: {
                  value: 0,
                  description: "",
                },
              },
            ],
          },
        };
        return {
          ...tutorQuest,
          status: dq.status,
          currentProgress: dq.currentProgress,
          onClick,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const combinedTasks = (socialTask ?? [])
    .map((dq: any) => {
      const correspondingTask = allSocialTaskById[dq.id];
      if (correspondingTask) {
        const onClick = onClickHandlersSocialTask[dq.status as TaskStatus]
          ? () =>
              onClickHandlersSocialTask[dq.status as TaskStatus](
                correspondingTask
              )
          : () => {
              console.log(`Unknown status for task ${dq.id}.`);
            };

        return {
          ...correspondingTask,
          onClick,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const [activeTab, setActiveTab] = useState<QuestType>(QuestType.quests);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { userId } = useTelegram();

  useEffect(() => {
    if (activeTab === QuestType.quests) {
      const fetchQuests = async () => {
        setLoading(true);
        setError(null);
        try {
          const fetchedQuests = await getQuests({
            clientId: userId,
          });
          console.log("Fetched Quests:", fetchedQuests);
        } catch (err) {
          setError("Failed to load quests.");
        } finally {
          setLoading(false);
        }
      };
      const fetchSocialTasks = async () => {
        setLoading(true);
        setError(null);
        try {
          const fetchedSocialTasks = await getSocialTasks({
            clientId: userId,
          });

          console.log("Fetched SocialTasks:", fetchedSocialTasks);
        } catch (err) {
          setError("Failed to load social tasks.");
        } finally {
          setLoading(false);
        }
      };

      fetchSocialTasks();
      fetchQuests();
    }

    if (activeTab === QuestType.explore) {
    }
  }, [activeTab, dispatch]);

  const buttonBaseClass =
    "bg-[#242520] rounded-t-sm w-full p-[1px] transition-all duration-300";
  const buttonInnerClass =
    "bg-[#242520] rounded-t-sm w-full h-full text-white text-sm leading-none p-3";

  const [checkingProgress, setCheckingProgress] = useState(false);

  const [clickOnLink, setClickOnLink] = useSessionStorage<
    Record<number, boolean>
  >("clickOnLink", {});

  useEffect(() => {
    const initialClickState: Record<number, boolean> = {};
    combinedTasks.forEach((task) => {
      if (!(task.id in clickOnLink)) {
        initialClickState[task.id] = false;
      }
    });
    setClickOnLink((prevState) => ({ ...initialClickState, ...prevState }));
  }, [combinedTasks, setClickOnLink, clickOnLink]);

  const handleLinkClick = (taskId: number) => {
    setClickOnLink((prevState) => ({
      ...prevState,
      [taskId]: true, // Перемикаємо стан
    }));
  };

  const handleCheckUserSubscribe = async () => {
    if (specialTask) {
      if (specialTask.channelId) {
        setCheckingProgress(true);
        const data = await subscriptionCheck({
          clientId: userId,
          channelName: specialTask.channelId,
        });

        if (data) {
          dispatch(
            completeTask({
              clientId: userId,
              taskId: specialTask.id,
            })
          );
        }

        setCheckingProgress(false);
      } else {
        setCheckingProgress(true);
        setTimeout(async () => {
          if (clickOnLink[specialTask.id]) {
            await dispatch(
              completeTask({
                clientId: userId,
                taskId: specialTask.id,
              })
            );
            setClickOnLink((prevState) => ({
              ...prevState,
              [specialTask.id]: false, // Перемикаємо стан
            }));
          }
          setCheckingProgress(false);
        }, 5000);
      }
    }
  };

  const Reward = () => {
    const rewardParts = specialTask?.rewards.map(
      (reward: any, index: number) => {
        const { amount, rewardId, rewardType } = reward;

        const resource = handleRewardResult({
          rewardId: rewardId,
          rewardType: rewardType,
        });
        console.log("amount", amount);
        console.log("resource", resource);
        // Отримуємо назву ресурсу

        // Отримуємо іконку ресурсу
        const icon = resource?.image;

        // Формуємо частину повідомлення з кількістю і ресурсом
        console.log("icon", icon);
        // Повертаємо JSX для кожного елемента з текстом і іконкою
        const resourceName = resource?.name as keyof typeof Resources;
        return (
          <div
            key={index}
            className={`flex items-center justify-center flex-col min-w-[40px] max-w-[40px] h-[40px] p-1 rounded-md border border-black bg-gradient-to-b
            ${
              rewardId === Resources.kitsu
                ? "from-[#D158D8] to-[#9444DD]"
                : "from-[#484CAA] to-[#4247C9]"
            } pb-[1px]`}
          >
            {Resources[resourceName] === Resources["5m"] && (
              <div className="w-[38px] h-[10px] mt-[2px] flex items-center justify-center border-b border-black bg-[#333693] rounded-t-md">
                <span className="w-full text-[8px] text-white text-center">
                  5m
                </span>
              </div>
            )}
            <div
              className={`relative w-full h-[30px] flex items-center justify-center ${
                Resources[resourceName] === Resources["5m"] && "mb-[5px]"
              }`}
            >
              <img
                src={icon}
                alt="Icon"
                className="w-[28px] h-[28px] object-cover rounded-lg"
              />
              <span
                className={`absolute text-[8px] text-white right-0 ${
                  Resources[resourceName] === Resources["5m"]
                    ? "mt-[20px]"
                    : "mt-[25px]"
                }`}
                style={
                  Resources[resourceName] !== Resources["5m"]
                    ? {
                        filter:
                          "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
                      }
                    : {}
                }
              >
                {Resources[resourceName] === Resources["5m"] ? 1 : amount}
              </span>
            </div>
          </div>
        );
      }
    );

    return (
      <div className="flex  gap-1 w-full min-w-[40%] overflow-scroll">
        {rewardParts}
      </div>
    );
  };

  function decodeHTMLEntities(text: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  function stripHTMLTags(str: string): string {
    // Видаляємо всі HTML-теги з рядка
    const withoutTags = str.replace(/<\/?[^>]+(>|$)/g, "");

    // Декодуємо HTML-сутності (наприклад, &nbsp;)
    const decoded = decodeHTMLEntities(withoutTags);

    // Обрізаємо пробіли з обох кінців рядка
    const trimmed = decoded.trim();

    // Якщо після всіх операцій рядок порожній, повертаємо порожній рядок
    return trimmed === "" ? "" : trimmed;
  }

  let tutorialTip = "";
  let fontSize: string | undefined;
  let tooltipType: "bottom" | "top" | "right" | "left" | undefined;
  switch (farmTutorialSave?.stage) {
    case TutorialFarmStage.clickOnQuestList: {
      tutorialTip = "This is your task list";
      tooltipType = "bottom";
      break;
    }
    case TutorialFarmStage.clickOnCompletedQuest: {
      tutorialTip = "You have completed one task";
      tooltipType = "right";
      break;
    }
    case TutorialFarmStage.claimQuest: {
      tutorialTip = "Claim Reward";
      tooltipType = "right";
      break;
    }
    case TutorialFarmStage.clickOnUncompletedQuest: {
      tutorialTip = "Lets complete one more task";
      break;
    }
    case TutorialFarmStage.clickToNavigate: {
      tutorialTip =
        "Click the button to go to the place where you can complete the task";
      fontSize = "11px";
      break;
    }
    default: {
      tutorialTip = "Broken stage";
    }
  }

  const getImageSrc = (category: SocialTaskCategory) => {
    console.log("category", category);
    switch (category) {
      case SocialTaskCategory.Telegram:
        return require("../../assets/images/quests/tg.png");
      case SocialTaskCategory.Twitter:
        return require("../../assets/images/quests/x.png");
      case SocialTaskCategory.TelegramPremium:
        return require("../../assets/images/quests/tg.png");
      default:
        return require("../../assets/images/quests/tg.png");
    }
  };

  return (
    <Sentry.ErrorBoundary onError={(error, componentStack, eventId) => {
      const errorData: RuntimeErrorData = { message: "" };

      if (error instanceof Error) {
        errorData.message = error.message;
        errorData.stack = componentStack;
      }

      if (componentStack) { errorData.stack = componentStack }

      return ErrorReportingService.reportError({
        type: ErrorType.runtime,
        errorData,
        clientId: userId,
      });
    }}
    fallback={({ error, resetError, componentStack, eventId }) => {
      return (<ErrorComponent jsError={{ error, resetError, componentStack, eventId }}/>);
    }}
    >
      <PageTransition>
        <>
          {/* Tips for tutorial */}
          {farmTutorialSave?.stage! >= TutorialFarmStage.clickOnQuestList &&
            farmTutorialSave?.stage! < TutorialFarmStage.finishQuestTutor && (
              <div
                className={`absolute top-[60px] z-[9999] ${
                  tooltipType! === "bottom"
                    ? "left-[50%] translate-x-[-50%] "
                    : "right-[14px]"
                }`}
              >
                <TutorialTooltip
                  value={tutorialTip}
                  type={tooltipType}
                  scale="125"
                  fontSize={fontSize ? fontSize : "16px"}
                />
              </div>
            )}

          {/* Dark bg for tutorial stages */}
          {(farmTutorialSave?.stage ===
            TutorialFarmStage.clickOnUncompletedQuest ||
            farmTutorialSave?.stage === TutorialFarmStage.clickToNavigate ||
            farmTutorialSave?.stage === TutorialFarmStage.claimQuest ||
            farmTutorialSave?.stage ===
              TutorialFarmStage.clickOnCompletedQuest ||
            farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList) && (
            <div
              className={`absolute w-full h-full bg-[rgba(0,0,0,0.5)] z-[20]`}
              onClick={() => {
                switch (farmTutorialSave?.stage) {
                  case TutorialFarmStage.clickOnUncompletedQuest: {
                    updateSave({
                      stage: TutorialFarmStage.clickToNavigate,
                    });
                    break;
                  }
                  case TutorialFarmStage.clickToNavigate: {
                    updateSave({
                      stage: TutorialFarmStage.clickOnRankUpgrade,
                    });
                    break;
                  }
                  case TutorialFarmStage.clickOnCompletedQuest: {
                    updateSave({
                      stage: TutorialFarmStage.claimQuest,
                    });
                    break;
                  }
                  case TutorialFarmStage.clickOnQuestList: {
                    updateSave({
                      stage: TutorialFarmStage.clickOnCompletedQuest,
                    });
                    break;
                  }
                  default: {
                  }
                }
              }}
            ></div>
          )}

          <div className="relative flex justify-start items-center w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
            <div className="absolute top-[1px] pl-3">
              <HandleBackButton />
            </div>

            <div className="text-white text-2xl font-black font-['Sofia Sans Semi Condensed'] leading-normal pl-16 text-shadow-black-sm">
              {t("quests.name")}
            </div>
          </div>
          <div
            className={`w-full fixed top-[100px] left-0 bottom-0 right-0 flex items-center justify-center 
             ${
               farmTutorialSave?.stage === TutorialFarmStage.clickOnQuestList ||
               farmTutorialSave?.stage ===
                 TutorialFarmStage.clickOnCompletedQuest ||
               farmTutorialSave?.stage === TutorialFarmStage.claimQuest
                 ? "z-[21]"
                 : "z-5"
             }`}
          >
            <div
              className={`w-full absolute -top-10 flex justify-center gap-x-2 
              ${
                farmTutorialSave?.stage ===
                  TutorialFarmStage.clickOnCompletedQuest ||
                farmTutorialSave?.stage === TutorialFarmStage.claimQuest
                  ? "brightness-50 pointer-events-none"
                  : ""
              } `}
            >
              <button
                onClick={() => setActiveTab(QuestType.quests)}
                className={`${buttonBaseClass} ${
                  activeTab === QuestType.quests ? "" : "opacity-60"
                }`}
              >
                <div className={buttonInnerClass}>{t("quests.name")}</div>
              </button>
              {/* <button
                onClick={() => setActiveTab(QuestType.explore)}
                className={`${buttonBaseClass} ${
                  activeTab === QuestType.explore ? "" : "opacity-60"
                }`}
              >
                <div className={buttonInnerClass}>
                  {t("exploreQuests.name")}
                </div>
              </button> */}
              <button
                onClick={() => setActiveTab(QuestType.achievements)}
                className={`${buttonBaseClass} ${
                  activeTab === QuestType.achievements ? "" : "opacity-60"
                }`}
              >
                <div className={buttonInnerClass}>{t("achievements.name")}</div>
              </button>
            </div>
            {showSuccess ? (
              <>
                <div className="absolute inset-0 bg-black opacity-60 z-10"></div>
                <div className="absolute w-full h-full z-20 ">
                  <SuccessAnimation text="The award has been successfully received!" />
                </div>
              </>
            ) : null}
            <div
              className={`p-3 bg-[#242520] shadow-inner-sm-white border-t border-[#18191a] h-full pb-[45px] w-full`}
            >
              {/* Dark bg for tutorial stages */}
              {(farmTutorialSave?.stage ===
                TutorialFarmStage.clickOnCompletedQuest ||
                farmTutorialSave?.stage === TutorialFarmStage.claimQuest) && (
                <div
                  className={`absolute w-full h-full bg-[rgba(0,0,0,0.5)] z-[20]`}
                ></div>
              )}

              <div className="bg-[#332b26] p-4 rounded-[1px] shadow-inner-sm-black h-full flex flex-col ">
                {loading ? (
                  <div className="flex flex-col gap-4">
                    {activeTab === QuestType.quests && (
                      <QuestBlockInnerSkeleton />
                    )}
                    {Array.from({ length: 3 }).map((_, index) => (
                      <QuestBlockSkeleton key={index} />
                    ))}
                  </div>
                ) : error ? (
                  <div className="text-center text-red-500">{error}</div>
                ) : (
                  <QuestsList
                    quests={combinedQuests}
                    socialTask={combinedTasks}
                    activeTab={activeTab}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="absolute bottom-0  w-full z-[45]">
            <div className="w-full h-24 bg-gradient-to-t from-[#201B18] via-[#201B18]/100 via-70% to-transparent "></div>
          </div>
          {/* {openModal && (
            <div
              className="absolute inset-0 bg-black opacity-60 z-[55]"
              onClick={() => setOpenModal(false)}
            ></div>
          )} */}

          {openModal && (
            <div className="fixed inset-0 z-[100] w-full h-full flex justify-center items-center min-w-full max-w-full min-h-full max-h-full">
              <div
                onClick={() => setOpenModal(false)}
                className="absolute z-40 h-full w-full bg-[rgba(0,0,0,0.5)]"
              />
              <PopupWindow onClickClose={() => setOpenModal(false)}>
                {/* CHANGES HERE */}

                {showSuccess ? (
                  <SuccessAnimation text="The award has been successfully received!" />
                ) : checkingProgress ? (
                  <Spinner />
                ) : (
                  <div className="flex flex-col justify-center items-center p-4">
                    {specialTask ? (
                      <div className="w-[72px] h-[72px] p-1 flex-shrink-0 border border-black rounded-md bg-[#362D28] shadow-inner-sm-black">
                        <img
                          src={getImageSrc(specialTask.category.id)}
                          className="w-full h-full object-contain"
                        />
                      </div>
                    ) : null}
                    <div className="flex justify-center items-center p-2">
                      {<Reward />}
                    </div>
                    {specialTask?.status === TaskStatus.Done ? (
                      <div>
                        <PopupButton
                          type="green"
                          onClick={handleClaimSpecialTaskBonuses}
                          size="big"
                        >
                          Claim your reward
                        </PopupButton>
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center items-center text-center text-white text-xl font-black leading-tight pb-2">
                          {specialTask && stripHTMLTags(specialTask?.name)}
                        </div>
                        <div className="flex justify-center items-center text-center text-[#C3B996] text-sm font-medium leading-[14px] pb-4">
                          {specialTask &&
                            stripHTMLTags(specialTask?.description)}
                        </div>
                        <div className="grid grid-cols-4 grid-rows-2 justify-center items-center w-full">
                          {specialTask ? (
                            <>
                              <div className="flex justify-center items-center text-center text-[#FFEFCB] text-xs font-light uppercase leading-[14px]">
                                Step 1
                              </div>
                              <PopupButton
                                className="col-span-2"
                                size="big"
                                type="blue"
                                onClick={() => {
                                  handleLinkClick(specialTask?.id);
                                  window.open(specialTask?.link, "_blank");
                                }}
                              >
                                Subscribe
                              </PopupButton>
                              <div />
                            </>
                          ) : null}

                          <div className="flex justify-center items-center text-center text-[#FFEFCB] text-xs font-light uppercase leading-[14px]">
                            Step 2
                          </div>
                          <PopupButton
                            className="col-span-2"
                            type="green"
                            onClick={handleCheckUserSubscribe}
                            size="big"
                          >
                            Check Task
                          </PopupButton>
                          <div />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </PopupWindow>
            </div>
          )}
        </>
      </PageTransition>
    </Sentry.ErrorBoundary>
  );
};
