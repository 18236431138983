import { Tooltip } from "react-tooltip";

import { useState } from "react";
import { Resources } from "../../../../enums/resources";
import { ItemType } from "../../../../interfaces/lootBotx";
import { PremiumTask, RewardsPremium } from "../../../../mock/offers";
import { defineCountString } from "../../../../utils/farmConfigParser";
import { resourcesMap } from "../../../../utils/mapping";
import { handleRewardResult } from "../../../../utils/rewardParse";
import { motion } from "framer-motion";

export const Task = ({
  taskLength,
  index,
  task,
  isOpen,
  isClaimed,
  isReadyToClaim,
  handleClaim,
  setOpenTooltipIndex,
}: {
  taskLength: number;
  index: number;
  task: PremiumTask;
  isOpen: boolean;
  isClaimed: boolean;
  isReadyToClaim: boolean;
  handleClaim: (index: number) => void;
  setOpenTooltipIndex: (index: number | null) => void;
}) => {
  const Reward = () => {
    const rewardParts = task.configTask.rewards
      .filter(
        (reward) =>
          !(reward.itemType === ItemType.farmResource && reward.itemId === 0)
      )
      .map((reward: RewardsPremium, index: number) => {
        const resource = handleRewardResult({
          rewardId: reward.itemId,
          rewardType: reward.itemType,
        });

        // Отримуємо назву ресурсу

        // Отримуємо іконку ресурсу
        const icon = resource?.image;

        // Формуємо частину повідомлення з кількістю і ресурсом
        console.log("icon", icon);
        // Повертаємо JSX для кожного елемента з текстом і іконкою
        const resourceName = resource?.name as keyof typeof Resources;
        return (
          <div
            key={index}
            className={`flex items-center justify-center flex-col min-w-[45px] max-w-[45px] h-[45px] p-1 rounded-md border-2 border-black bg-gradient-to-b 
                ${
                  Resources[resourceName] === Resources.kitsu
                    ? "from-[#D158D8] to-[#9444DD]"
                    : "from-[#484CAA] to-[#4247C9]"
                } pb-[1px]`}
          >
            {Resources[resourceName] === Resources["5m"] && (
              <div className="w-[43px] h-[11px] mt-[1px] flex items-center justify-center border-b-2 border-black bg-[#333693] rounded-t-md">
                <span className="w-full text-xs text-white text-center">
                  5m
                </span>
              </div>
            )}
            <div
              className={`relative w-full h-[30px] flex items-center justify-center ${
                Resources[resourceName] === Resources["5m"] && "mb-[5px]"
              }`}
            >
              <img
                src={icon}
                alt="Icon"
                className="w-[32px] h-[32px] object-cover rounded-lg"
              />
              <span
                className={`absolute text-xs text-white right-0 ${
                  Resources[resourceName] === Resources["5m"]
                    ? "mt-[20px]"
                    : "mt-[25px]"
                }`}
              >
                {Resources[resourceName] === Resources["5m"]
                  ? 1
                  : defineCountString(reward.amount)}
              </span>
            </div>
          </div>
        );
      });

    return (
      <div className="flex  gap-1 w-full min-w-[40%] overflow-scroll">
        {rewardParts}
      </div>
    );
  };
  const variants = {
    ready: {
      y: [0, -20, 0], // Підскокує на 20px вгору і назад
      filter: [
        "drop-shadow(0 0 10px rgba(255, 215, 0, 0.5))", // Початковий ореол
        "drop-shadow(0 0 20px rgba(255, 215, 0, 1))", // Яскравіший ореол
        "drop-shadow(0 0 10px rgba(255, 215, 0, 0.5))", // Повернення до початкового стану
      ],
      transition: {
        y: {
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut",
        },
        filter: {
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        },
      },
    },
    default: {
      y: 0,
      filter: "drop-shadow(0 0 0px rgba(0,0,0,0))", // Вимикаємо ореол
    },
  };
  function getSpecificRewards(premiumTask: PremiumTask): RewardsPremium[] {
    return premiumTask.configTask.rewards.filter(
      (reward) =>
        reward.itemType === ItemType.farmResource && reward.itemId === 0
    );
  }
  const data = getSpecificRewards(task);

  const handleToolTipClick = () => {
    if (isOpen) {
      setOpenTooltipIndex(null); // Закрити тултіп, якщо він вже відкритий
    } else {
      setOpenTooltipIndex(index); // Відкрити цей тултіп і закрити інші
    }
  };

  return (
    <div className="inline-block mr-1">
      <div
        onClick={() =>
          !isClaimed && isReadyToClaim
            ? handleClaim(task.configTask.id)
            : handleToolTipClick()
        }
        key={index}
        className={`relative  flex flex-col bg-[#f6f6d5] rounded-xl shadow-inner-sm-black border-2 border-[#ffb74b] min-w-[130px] flex-shrink-0 ${
          index === 0 ? "ml-4" : ""
        } ${index === taskLength - 1 ? "mr-3" : ""}`}
      >
        <Tooltip
          isOpen={isOpen}
          id={`show-rewards-tooltip-for${index}`}
          place="left-start"
          className="border-2 border-black"
          border='1px solid #000000'
          style={{
            backgroundColor: "#ffffff",
            color: "#000000",
            borderRadius: "8px",
            padding: "8px",
            textAlign: "center",
            maxWidth: "160px",
            wordWrap: "break-word",
            opacity: 1,
            zIndex: 100,
          }}
          clickable={true}
        >
          <Reward />
        </Tooltip>

        <div className="absolute bottom-11 w-full h-[45px] bg-gradient-to-b from-transparent  to-[#7a6e55] "></div>

        <div className="px-5 pt-2 z-10">
          <motion.img
            data-tooltip-id={`show-rewards-tooltip-for${index}`}
            className="w-[85px] h-[85px]"
            src={require(`../../../../assets/images/chests/${
              isClaimed ? "open" : "close"
            }/${index}.png`)}
            variants={variants}
            animate={!isClaimed && isReadyToClaim ? "ready" : "default"}
          />
        </div>

        <div
          className={`absolute top-[64%] left-0 w-full h-2 z-20  bg-[#242520]   border-y border-black justify-center items-center   ${
            index === 0 ? "ml-4 rounded-l-sm" : ""
          }
          ${index === taskLength - 1 ? "rounded-r-sm" : ""}`}
          style={{ width: "calc(100% + 0.5rem)" }}
        >
          {isReadyToClaim && (
            <div
              className={`h-full bg-[#4edd4f] transition-width duration-500 w-full ${
                index === 0 ? "rounded-l-sm ml-[1px]" : ""
              } ${index === taskLength - 1 ? "rounded-r-sm" : ""}`}
            ></div>
          )}
        </div>

        <div
          className={`absolute top-[66%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 ${
            isReadyToClaim ? "bg-[#4edd4f]" : "bg-[#242520]"
          }  flex justify-center items-center gap-1 px-2 py-0.5 rounded-sm shadow-inner-sm-black border border-black`}
        >
          <svg
            className="h-4 w-4 flex-shrink-0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.0391 12.7916H14.4557V12.3749V11.1249H14.0391C14.4557 11.1249 14.4557 11.1246 14.4557 11.1243L14.4557 11.1236L14.4557 11.122L14.4557 11.1182L14.4555 11.1077C14.4553 11.0995 14.4549 11.0889 14.4543 11.0761C14.453 11.0504 14.4506 11.0156 14.446 10.9731C14.4369 10.8882 14.4192 10.7714 14.3847 10.6333C14.3157 10.3574 14.1782 9.99105 13.9036 9.62494C13.3401 8.87348 12.2657 8.20827 10.2891 8.20827C8.3124 8.20827 7.23807 8.87348 6.67448 9.62494C6.3999 9.99105 6.2624 10.3574 6.19343 10.6333C6.15889 10.7714 6.14119 10.8882 6.13209 10.9731C6.12754 11.0156 6.12511 11.0504 6.12383 11.0761C6.12319 11.0889 6.12283 11.0995 6.12263 11.1077L6.12244 11.1182L6.1224 11.122L6.1224 11.1236L6.1224 11.1243C6.1224 11.1246 6.1224 11.1249 6.53906 11.1249H6.1224V12.3749V12.7916H6.53906H14.0391ZM8.66861 3.87949L8.96324 4.17411L8.66861 3.87949C8.34811 4.19998 8.12985 4.60832 8.04143 5.05286C7.953 5.4974 7.99839 5.95818 8.17184 6.37692C8.34529 6.79567 8.63902 7.15358 9.01588 7.40539C9.39274 7.6572 9.83581 7.79161 10.2891 7.79161C10.8969 7.79161 11.4797 7.55016 11.9095 7.12039C12.3393 6.69062 12.5807 6.10773 12.5807 5.49994C12.5807 5.04669 12.4463 4.60362 12.1945 4.22676L11.8552 4.45346L12.1945 4.22676C11.9427 3.84989 11.5848 3.55617 11.166 3.38272C10.7473 3.20927 10.2865 3.16388 9.84198 3.25231C9.39744 3.34073 8.9891 3.55899 8.66861 3.87949ZM7.58073 6.74994V6.33327H7.16406H5.70573V4.87494V4.45827H5.28906H4.03906H3.6224V4.87494V6.33327H2.16406H1.7474V6.74994V7.99994V8.41661H2.16406H3.6224V9.87494V10.2916H4.03906H5.28906H5.70573V9.87494V8.41661H7.16406H7.58073V7.99994V6.74994Z"
              fill="white"
              stroke="black"
              strokeWidth="0.833333"
            />
          </svg>
          <div className="text-white text-sm font-black">
            {task.configTask.amount}
          </div>
        </div>

        <div className="w-full bg-[#f6dba6] rounded-bl-[10px] rounded-br-[10px] shadow-inner z-10">
          <div className="flex justify-center items-center p-2 gap-2 pt-3">
            {data.length ? (
              <>
                <img className="w-6 h-6" src={resourcesMap[0].image} />
                <div className="text-right text-white text-xl font-black  leading-[18px] text-shadow-black-sm">
                  {data[0].amount}
                </div>
              </>
            ) : (
              <div className="w-6 h-6" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
