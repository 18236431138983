// components/ActiveCards.tsx
import React, { useState, useRef, useEffect } from "react";
import { Card } from "../../components/Card";
import { CardProps } from "../../interfaces/card";
import { DraggableCore } from "react-draggable";
import { motion, AnimatePresence } from "framer-motion";
import { CardType, mockCards } from "../../endpoints/mock";
import { useTelegram } from "../../hooks/useTelegram";
import { preloadImages } from "../../utils/preloadImages"; // Імпортуємо утиліту
import { useSoundService } from "../../utils/soundService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { TutorialSave, TutorialStage } from "../../interfaces/tutorial";
import { setSave } from "../../app/features/tutorialSaveSlice";
import { updateTutorialProgress } from "../../endpoints/tutorialProgress";
import TutorialBattleAnimation from "../../components/animation/TutorialBattleAnimation";
import CardCanvas from "./CardCanvas";
import { TutorialTooltip } from "../../components/TutorialTooltip";
import { PointerHand } from "../../components/PointerHand";

export const ActiveCards = ({
  cards,
  makeCardAction,
  usedCards,
  setUsedCards,
  cardStrategy,
  removeCard,
  expandTiers,
  isDraggingCards,
}: {
  cards: any[];
  makeCardAction: any;
  usedCards: CardProps[];
  setUsedCards: any;
  cardStrategy: any;
  removeCard: any;
  expandTiers: any;
  isDraggingCards: boolean;
}) => {
  // console.log('we are here ', cards)
  const activeCardsRef = useRef<HTMLDivElement>(null);
  const [activeCardsHeight, setActiveCardsHeight] = useState<number>(0);
  const allowedDropAreaRef = useRef<HTMLDivElement>(null);
  const [draggableCard, setDraggableCard] = useState<string>("");
  const [cardsAtHand, setCardsAtHand] = useState<CardProps[]>([]);
  const [cardTypes, setCardTypes] = useState<{ [uid: string]: CardType }>({});
  const [cardUsedTypes, setUsedCardTypes] = useState<{
    [uid: string]: CardType;
  }>({});
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([]);
  const [dragOffsets, setDragOffsets] = useState<{ x: number; y: number }[]>(
    []
  );
  const [usedCardsPosition, setUsedCardsPosition] = useState<
    { x: number; y: number }[]
  >([]);
  const [tutorialCardIsDropped, setTutorialCardIsDropped] =
    useState<boolean>(false);
  const [tutorialCardDroppedNotToTheEnd, setTutorialCardDroppedNotToTheEnd] =
    useState<boolean>(false);
  const { userId } = useTelegram();

  const [discardingCardIndex, setDiscardingCardIndex] = useState<number | null>(
    null
  );
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false); // Стан для відстеження завантаження зображень

  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [cardIsClicked, setCardIsClicked] = useState<boolean>(false);

  useEffect(() => {
    cardsAtHand.map((c) => {
      if (draggableCard === c.uid) {
        expandTiers()
      }
    })
  }, [draggableCard, cardsAtHand])

  const tutorialSave = useSelector(
    (state: RootState) => state.tutorialSave.tutorialSave.save
  );

  const dispatch = useDispatch<AppDispatch>();

  const updateSave = ({ save }: { save: TutorialSave }) => {
    // const save = { stage: TutorialStage.stone }
    dispatch(setSave(save));
    const updatingSave = async () => {
      await updateTutorialProgress({
        clientId: userId,
        save: JSON.stringify(save),
      });
    };
    updatingSave();
  };

  // console.log('CARDS AT HAND', cardsAtHand);

  useEffect(() => {
    if (activeCardsRef.current) {
      const currentHeight = activeCardsRef.current.offsetHeight;
      if (currentHeight > activeCardsHeight) {
        //console.log(currentHeight);
        setActiveCardsHeight(currentHeight);
      }
    }
  }, [cardsAtHand]);

  useEffect(() => {
    //console.log('USE EFFECT CARDS AT HAND', cardsAtHand);
    playSound("cardPlay");
  }, [cardsAtHand]);

  // Синхронізуємо positions та dragOffsets з cardsAtHand
  useEffect(() => {
    setPositions(cardsAtHand.map(() => ({ x: 0, y: 0 })));
    setDragOffsets(cardsAtHand.map(() => ({ x: 0, y: 0 })));
  }, [cardsAtHand]);

  useEffect(() => {
    const sortedCards = [...cards].sort((a, b) => a?.id - b?.id);
    const initializedCards = sortedCards.map((card) => ({
      ...card,
      draggable: true,
      isBackLight: card?.isBackLight,
    }));

    // Підготовка шляхів до зображень для попереднього завантаження
    const imagePaths = initializedCards
      .map((card) => {
        const foundCardData = mockCards.find((v) => v.id === card.id)?.data;

        if (!foundCardData) {
          console.log(card.id);
        }

        if (foundCardData) {
          const { image } = foundCardData;

          try {
            return require(`../../assets/images/cards/${card.id}.png`);
          } catch (error) {
            console.error(`Image for card ID ${card.id} not found.`);
            return null;
          }
        }
      })
      .filter((src) => src !== null);

    // console.log('PRELOAD', imagePaths);

    // Попереднє завантаження зображень
    preloadImages(imagePaths)
      .then(() => {
        //console.log('All images preloaded successfully.');
        setCardsAtHand(initializedCards);
        setPositions(initializedCards.map(() => ({ x: 0, y: 0 })));
        setDragOffsets(initializedCards.map(() => ({ x: 0, y: 0 })));
        setImagesLoaded(true); // Встановлюємо стан після завантаження всіх зображень
      })
      .catch((error: any) => {
        console.error("Error preloading images:", error);
        // Можна вирішити, що робити у випадку помилки (наприклад, показати повідомлення)
      });

    const imagePathsLights = [
      require(`../../assets/images/cardsIcons/0.png`),
      require(`../../assets/images/cardsIcons/2.png`),
      require(`../../assets/images/cardsIcons/3.png`),
    ];

    // console.log('PRELOAD', imagePathsLights);

    preloadImages(imagePathsLights)
      .then(() => {
        //console.log('All images preloaded successfully.');
        setCardsAtHand(initializedCards);
        setPositions(initializedCards.map(() => ({ x: 0, y: 0 })));
        setDragOffsets(initializedCards.map(() => ({ x: 0, y: 0 })));
        setImagesLoaded(true); // Встановлюємо стан після завантаження всіх зображень
      })
      .catch((error: any) => {
        console.error("Error preloading card lights images:", error);
        // Можна вирішити, що робити у випадку помилки (наприклад, показати повідомлення)
      });
  }, [cards]);

  useEffect(() => {
    const updateCardTypes = () => {
      const newCardTypes = {} as any;

      cards.forEach((card) => {
        const cardData = mockCards.find((v) => v.id === card.id)?.data;

        if (cardData) {
          // console.log("cardData", cardData);
          newCardTypes[card.uid] = cardData.type;

          cardStrategy[cardData.type]({ card });
        }
      });

      setCardTypes(newCardTypes);
    };

    updateCardTypes();
  }, [cards.length]);

  const updateCardTypesUsed = (newArray: CardProps[]) => {
    const newCardTypes: { [uid: string]: CardType } = {};

    newArray.forEach((card: CardProps) => {
      const cardData = mockCards.find((v: any) => v.id === card.id)?.data;
      if (cardData) {
        newCardTypes[card.uid] = cardData.type;
      }
    });

    setUsedCardTypes(newCardTypes);
  };

  const scale: number = 1.3;

  const getRotationAngle = (index: number, totalCards: number) => {
    const maxAngle = 8;
    const minAngle = -8;

    if (totalCards <= 2) return 0;

    const angleStep = (maxAngle - minAngle) / (totalCards - 1);

    return (minAngle + index * angleStep).toFixed(2);
  };

  const getVerticalOffset = (index: number, totalCards: number) => {
    const b = totalCards < 6 ? -15 : -25;

    if (totalCards === 1) return 0;

    const angleStep = Math.PI / (totalCards - 1);
    const angle = angleStep * index;

    return (b * Math.sin(angle)).toFixed(2);
  };

  const selectedTransformStyle = {
    transform: `rotate(0deg) translateY(0)`,
  };

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleStart = (e: any, data: any, index: number, uid: string) => {
    if (!isDraggingCards) return false;
    setTutorialCardDroppedNotToTheEnd(false);
    if (tutorialSave?.stage === TutorialStage.battle && !cardIsClicked) {
      setCardIsClicked(true)
    }
    if (!cardsAtHand[index].draggable || draggableCard !== "") return false;
    // Уникаємо ситуацій, де дані можуть бути некоректними
    if (data.x === null || data.y === null) return false;

    if (tutorialSave?.stage === TutorialStage.battle && !showAnimation) {
      setShowAnimation(true)
    }
    setDraggableCard(uid);
    setDragOffsets((prevOffsets) => {
      const newOffsets = [...prevOffsets];
      // console.log('NEW OFFSETS', newOffsets)
      newOffsets[index] = { x: data.x, y: data.y };
      return newOffsets;
    });
  };

  const handleDrag = (e: any, data: any, index: number) => {
    // if (
    //   tutorialSave?.stage !== TutorialStage.dropFirstCard &&
    //   tutorialSave?.enemyId === 0 &&
    //   index === 0
    // ) {
    //   return;
    // }

    if (!cardsAtHand[index].draggable) return false;

    expandTiers()

    let offsetX: number = data.x - dragOffsets[index].x;
    let offsetY: number = data.y - dragOffsets[index].y;
    if (tutorialSave?.stage && tutorialSave?.stage === TutorialStage.battle && showAnimation && !((Math.abs(offsetX) + Math.abs(offsetY)) < 30)) {
      setShowAnimation(false)
    }

    // Існуюча логіка переміщення
    if (
      positions[index]?.x !== 0 ||
      positions[index]?.y !== 0 ||
      offsetX < 0 ||
      offsetX > 0 ||
      offsetY < 0 ||
      offsetY > 0
    ) {

      setPositions((prevPositions) => {
        const newPositions = [...prevPositions];
        newPositions[index] = {
          x: data.x - dragOffsets[index].x,
          y: data.y - dragOffsets[index].y,
        };
        return newPositions;
      });
    }
  };

  const [disappearingCard, setDisappearingCard] = useState("0");
  const { playSound } = useSoundService();

  const handleStop = (e: any, data: any, index: number) => {
    if (!cardsAtHand[index].draggable) return false;
    setDraggableCard("");
    if (tutorialSave?.stage === TutorialStage.battle && showAnimation) {
      setShowAnimation(false)
    }
    if (tutorialSave?.enemyId === 0) {
      updateSave({save: {...tutorialSave, cardIsDropped: true}})
    }
    const allowedArea = allowedDropAreaRef.current;
    if (
      allowedArea &&
      (positions[index]?.x !== 0 || positions[index]?.y !== 0)
    ) {
      const rect = allowedArea.getBoundingClientRect();
      const element = e.target.getBoundingClientRect();

      if (element.top + element.height / 2 < rect.height) {
        cardsAtHand[index].draggable = false;

        setPositions((prev) => {
          const newPositions = [...prev];
          newPositions[index] = {
            x: data.x - dragOffsets[index].x,
            y: data.y - dragOffsets[index].y,
          };
          return newPositions;
        });

        const usedCard = cardsAtHand.filter((card, i) => i === index)[0];

        const newArrayCards = [...usedCards, usedCard];

        setUsedCards(newArrayCards);

        updateCardTypesUsed(newArrayCards);
        const cardData = mockCards.find((v: any) => v.id === usedCard.id);
        if (cardData && cardData?.data.type === CardType.spell) {
          playSound("attackMagic");
        }
        if (cardData && cardData?.data.type === CardType.atk) {
          playSound("attackBow");
        }
        if (cardData && cardData?.data.type === CardType.equip) {
          playSound("defend");
        }
        if (cardData && cardData?.data.name === "16Red") {
          playSound("healing");
        }

        usedCardsPosition.push({ x: element.left, y: element.top });
        setUsedCardsPosition(usedCardsPosition);

        setPositions(positions.filter((p, i) => i !== index));
        setCardsAtHand(
          cardsAtHand.filter((card, ind) => card.uid !== usedCard.uid)
        );
        removeCard(usedCard.uid);
        setTutorialCardIsDropped(true);

        if (
          tutorialSave?.stage === TutorialStage.battle &&
          tutorialSave.enemyId === 0
        ) {
          // updateSave({
          //   save: { enemyId: 0, stage: TutorialStage.dropFirstCard },
          // });
        }

        setDragOffsets(dragOffsets.filter((p, i) => i !== index));

        makeCardAction({
          clientId: userId,
          cardUid: usedCard.uid,
          cardId: usedCard.id,
        });
        setDisappearingCard(cardsAtHand[index].uid);
        //TODO makeAction
        setTimeout(function () {
          const newUsedCards = newArrayCards;

          setUsedCards(newUsedCards);

          setTimeout(() => {
            newUsedCards.filter((card) => card.uid === usedCard.uid)[0].hidden =
              true;
            setUsedCards(newUsedCards);
          }, 1000);
          // setUsedCards((prev) => {
          //   const newUsedCards = [...prev];
          //   newUsedCards.filter((card) => card.uid === usedCard.uid)[0].hidden =
          //     true;
          //   return newUsedCards;
          // });
        }, 150);
      } else {
        setPositions((prev) => {
          const newPositions = [...prev];
          newPositions[index] = { x: 0, y: 0 };
          return newPositions;
        });
        setTutorialCardDroppedNotToTheEnd(true);
      }
    }
  };

  useEffect(() => {
    if (tutorialSave?.stage === TutorialStage.battle) {
      setShowAnimation(true)
    }
  }, [tutorialSave?.stage])

  // Визначаємо початкову позицію для появи карт
  const initialCardPosition = { x: 70, y: 170 };

  if (!imagesLoaded) {
    // Покажіть глобальний завантажувач або нічого не рендеріть
    return null; // Або <div>Завантаження...</div>
  }

  let toolTip: string | undefined
  if(tutorialSave?.stage === TutorialStage.battle){
    toolTip = cardIsClicked?'Drag the card onto the enemy to play it.':'Press and hold the card.'
  }

  return (
    <>
      <div
        ref={allowedDropAreaRef}
        className='absolute left-0 top-0 right-0 bottom-[330px]'
      ></div>
      {usedCards.map((card, index) => (
        <div
          className='absolute max-w-[30%]'
          key={index}
          style={{
            width: `${100 / usedCards.length + 75}%`,
            top: usedCardsPosition[index]?.y,
            left: usedCardsPosition[index]?.x,
          }}
        >
          <AnimatePresence>
            {!card.hidden && (
              <motion.div
                initial={{opacity: 1}}
                animate={{opacity: 0}}
                exit={{opacity: 0}}
                transition={{duration: 0.2}}
                style={{position: "relative"}}
              >
                <Card
                  id={card.id}
                  lvl={card.lvl}
                  uid={card.uid}
                  glow={false}
                  IsDraggable={isDraggingCards}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {disappearingCard === card.uid && (
              <motion.div
                initial={{opacity: 1}}
                animate={{opacity: 0}}
                exit={{opacity: 0}}
                transition={{duration: 1.5}}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "100%", // Збільшена ширина
                  height: "100%", // Збільшена висота
                  transform: "translate(-50%, -50%)", // Центрування
                  zIndex: 100,
                  transition: "width 0.3s ease, height 0.3s ease",
                }}
              >
                <img
                  className='w-full h-full'
                  src={require(`../../assets/images/cardsIcons/${cardUsedTypes[card.uid]
                  }.png`)}
                  alt=''
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}

      <div
        ref={activeCardsRef}
        className={`absolute w-full px-3 pb-[154px] flex items-end h-full ${tutorialSave?.stage === TutorialStage.battle &&
        tutorialSave.enemyId === 0 && !tutorialSave?.cardIsDropped && cardsAtHand?.length
          ? "z-[10]"
          : "z-[0]"
        }`}
      >
        {tutorialSave?.enemyId == 0 && toolTip && !tutorialSave?.cardIsDropped && (
          <div className='absolute bottom-[15%] left-[15%] w-[50%] flex items-center justify-center z-[1]'>
            <TutorialTooltip value={toolTip ? toolTip : 'broken'} type="top_right"/>
          </div>
        )}
        <div className='w-full flex justify-center items-end '>
          {cardsAtHand &&
            cardsAtHand.map((card, index) => {
              return (
                <DraggableCore
                  key={card.uid}
                  onStart={(e, data) =>
                    tutorialSave?.enemyId === 0 && index === 0
                      ? handleStart(e, data, index, card.uid)
                      : handleStart(e, data, index, card.uid)
                  }
                  onDrag={(e, data) =>
                    tutorialSave?.enemyId === 0 && index === 0
                      ? handleDrag(e, data, index)
                      : handleDrag(e, data, index)
                  }
                  onStop={(e, data) =>
                    tutorialSave?.enemyId === 0 && index === 0
                      ? handleStop(e, data, index)
                      : handleStop(e, data, index)
                  }
                >
                  <AnimatePresence>
                    <motion.div
                      className={`max-w-[30%] aspect-[0.67] 
                        ${index === 0 && tutorialSave?.stage === TutorialStage.battle && tutorialSave.enemyId === 0 && !tutorialSave?.cardIsDropped
                        ? 'brightness-50 pointer-events-none'
                        : ''}`}
                      style={{
                        width: 100 / cardsAtHand.length + 75 + "%",
                        marginLeft:
                          index !== 0
                            ? cardsAtHand.length < 4
                              ? "6px" // карток менше ніж 4
                              : cardsAtHand.length < 6
                                ? "-10%" // карток менше ніж 6
                                : "-20%" // карток більше ніж 6
                            : "0",
                        zIndex: draggableCard === card.uid ? 10 : 9,
                      }}
                      key={card.uid}
                      initial={{
                        opacity: 0,
                        x: initialCardPosition.x,
                        y: initialCardPosition.y,
                        scale: 0.1,
                        rotate: 0,
                        marginLeft: 0,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        y: 0,
                        transform:
                          draggableCard === card.uid
                            ? `${selectedTransformStyle.transform} ${positions[index]?.x === 0 &&
                            positions[index]?.y === 0
                              ? `scale(1.3) 
                              ${tutorialSave?.enemyId === 0 && index === 1 && tutorialSave?.stage === TutorialStage.battle ? 'translateY(0px)' : 'translateY(-130px)'}  `
                              : " scale(1) translateY(0)"
                            }
                            `
                            : `rotate(${getRotationAngle(
                              index,
                              cardsAtHand.length
                            )}deg) translateY(${getVerticalOffset(
                              index,
                              cardsAtHand.length
                            )}px) scale(1)`,
                        // Анімація marginLeft до потрібного значення
                        marginLeft:
                          index !== 0
                            ? cardsAtHand.length < 4
                              ? "6px"
                              : cardsAtHand.length < 6
                                ? "-10%"
                                : "-20%"
                            : "0",
                        willChange: "transform",
                        position: "relative",
                      }}
                      exit={{opacity: 0}}
                      transition={{
                        duration: 0.3,
                      }}
                    >
                      <div
                        className={``}
                        style={{
                          transform:
                            draggableCard === card.uid
                              ? `${selectedTransformStyle.transform} translate(${positions[index]?.x}px, ${positions[index]?.y}px)`
                              : `translate(${positions[index]?.x}px, ${positions[index]?.y}px)`,
                          zIndex: draggableCard === card.uid ? 2 : 1,
                          willChange: "transform",
                          position: "relative",
                        }}
                      >
                        {draggableCard === card.uid && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%", // Збільшена ширина
                              height: "95.5%", // Збільшена висота
                              zIndex: 100,
                              transition: "width 0.3s ease, height 0.3s ease",
                            }}
                          >
                            <CardCanvas cardId={cardTypes[card.uid]}/>
                          </div>
                        )}
                        <div className={``}>
                          {index === 1 && showAnimation && (
                            <div>
                              <TutorialBattleAnimation
                                draggableCard={draggableCard}
                                tutorialCardIsDropped={tutorialCardIsDropped}
                                tutorialCardDroppedNotToTheEnd={
                                  tutorialCardDroppedNotToTheEnd
                                }
                              >
                                <Card
                                  id={card.id}
                                  lvl={card.lvl}
                                  uid={card.uid}
                                  glow={false}
                                  IsDraggable={isDraggingCards}
                                />
                              </TutorialBattleAnimation>
                            </div>
                          )}
                          {index === 1 && tutorialSave?.stage === TutorialStage.battle && !cardIsClicked && tutorialSave?.enemyId === 0 && !tutorialSave?.cardIsDropped &&
                              <div className="absolute bottom-[20%] right-[20%] z-10 ">
                                  <PointerHand scale={'125'}/>
                              </div>}
                          <Card
                            id={card.id}
                            lvl={card.lvl}
                            uid={card.uid}
                            isBacklight={card?.isBacklight}
                            glow={true}
                            IsDraggable={isDraggingCards}
                            isFocused={draggableCard === card.uid}
                            card={card}
                          />
                        </div>
                      </div>
                    </motion.div>
                  </AnimatePresence>
                </DraggableCore>
              );
            })}
        </div>
      </div>
    </>
  );
};
