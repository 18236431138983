// userRankSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UserRank } from '../../interfaces/rank';
import { getUserRank } from '../../endpoints/rankEndpoints';


// Визначаємо параметри для асинхронного thunk
interface GetUserRankParams {
  clientId: string;
}

// Визначаємо стан slice
interface UserRankState {
  userRank: UserRank | null;
  loading: boolean;
  error: string | null;
}

// Ініціалізуємо початковий стан
const initialState: UserRankState = {
  userRank: null,
  loading: false,
  error: null,
};

// Створюємо асинхронний thunk для отримання рангу користувача
export const fetchUserRank = createAsyncThunk<
  UserRank, // Тип повернення
  GetUserRankParams, // Тип параметрів
  { rejectValue: string } // Тип помилки
>(
  'userRank/fetchUserRank',
  async (params, thunkAPI) => {
    try {
      const response = await getUserRank(params);
      return response;
    } catch (error) {
      // Можна додатково обробити помилку
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

// Створюємо slice
const userRankSlice = createSlice({
  name: 'userRank',
  initialState,
  reducers: {
    // За потреби додайте звичайні редуктори тут
    clearUserRank(state) {
      state.userRank = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserRank.fulfilled, (state, action: PayloadAction<UserRank>) => {
        state.loading = false;
        state.userRank = action.payload;
      })
      .addCase(fetchUserRank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Cant get User rank';
      });
  },
});

// Експортуємо дії та редуктор
export const { clearUserRank } = userRankSlice.actions;
export default userRankSlice.reducer;
