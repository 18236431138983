import { PopupButton } from "../../../../components/PopupButton";
import { OfferType } from "../../../../mock/offers";
import { OfferResource, OfferResourceProps } from "../OfferResources";
import Timer from "../Timer";
import { useTranslation } from "react-i18next";

export interface OfferProps {
  id: number;
  resourceList?: OfferResourceProps[];
  offerType?: OfferType;
  layout?: "slim" | "wide" | "vertical" | "full" | "rank";
  type?: "blue" | "gold";
  label?: "corner" | "top" | "vertical_corner" | "full_top";
  kitsu?: string;
  price?: string;
  deadline?: string;
  isClaimed?: boolean;
  rankPoints?: string;
  onClick?: () => void;
  onClose?: () => void;
}

export const Offer = (props: OfferProps) => {
  const { t } = useTranslation();

  const mockResourceList: OfferResourceProps[] = [
    {
      resourceType: 2,
      resourceId: 2,
      isEnabled: true,
      amount: "6",
      layout: "vertical",
    },
    {
      resourceType: 2,
      resourceId: 0,
      isEnabled: false,
      amount: "250K",
      layout: "vertical",
    },
    {
      resourceType: 1,
      resourceId: 0,
      isEnabled: false,
      amount: "5",
      layout: "vertical",
    },
    {
      resourceType: 0,
      resourceId: 0,
      isEnabled: false,
      amount: "25",
      layout: "vertical",
    },
  ];

  const {
    id = "0",
    offerType = OfferType.kitsune,
    resourceList = mockResourceList,
    layout = "slim",
    type = "gold",
    label = "corner",
    kitsu = "2500",
    price = 0,
    deadline = "November 21, 2024",
    isClaimed = false,
    rankPoints,
    onClick,
    onClose,
  } = props;

  const colors = {
    gold: {
      1: "from-[#E4E180] to-[#FD8D4D]",
      2: "",
      3: "",
    },
    blue: {
      1: "from-[#3d54c8] to-[#3d54c8]",
      2: "hidden",
      3: "text-[14px]",
    },
  };
  const layouts = {
    rank: {
      1: "w-full h-[94px] rounded-[4px] bg-gradient-to-r",
      2: "hidden z-10",
      3: "hidden",
      4: "h-full grid grid-cols-2 grid-rows-2 grid-flow-col auto-rows-auto justify-around items-center pb-1 bg-[url('./assets/images/shop/offer/offer-rank-bg.png')] bg-cover ",
      5: "hidden",
      6: "w-min-[60%] ml-1",
      7: "hidden",
      8: " absolute text-[21px] top-[-20px]",
      9: "hidden",
      10: "flex w-full min-w-full gap-1",
      11: " row-span-2 mt-3 mr-2 ml-3 font-[800] justify-self-end self-end ",
      12: "100px",
      13: "text-[12px]",
      14: "relative flex text-[24px] bg-gradient-to-r from-[#d95bd8] via-[#d95bd8] to-transparent ml-1",
      15: "top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] ",
    },
    full: {
      1: "w-full h-auto rounded-[4px]  ",
      2: "hidden",
      3: "hidden",
      4: "flex flex-col justify-around items-center h-[165px] bg-gradient-to-r mx-[16px] rounded-md",
      5: "absolute w-[50%] h-auto -translate-y-[70%] z-[-1]",
      6: "w-full px-[40px]",
      7: "absolute w-full left-0 -translate-y-[50%]",
      8: "relative top-[-30px] text-[24px] w-full text-center",
      9: "hidden",
      10: "flex justify-center items-center w-full min-w-full h-[92px] gap-[7px]",
      11: "translate-y-[30%] font-[800]",
      12: "134px",
      13: "text-[12px]",
      14: "hidden",
      15: "",
    },
    slim: {
      1: "w-full h-[64px] rounded-[4px] bg-gradient-to-r",
      2: "z-10",
      3: "",
      4: "flex justify-around items-center",
      5: "w-[4.3em] min-w-[4.3em] h-auto drop-shadow-[-3px_3px_1px_rgba(74,74,52,0.8)] z-[2]",
      6: "w-[36%]",
      7: "hidden",
      8: " relative text-[1.3em]",
      9: "hidden",
      10: "flex w-full min-w-full gap-1",
      11: "mt-3 mr-2 ml-3 font-[800]",
      12: "80px",
      13: "",
      14: "hidden",
      15: "scale-[0.7] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]",
    },
    wide: {
      1: "w-full h-[138px] rounded-[4px] bg-gradient-to-r ",
      2: "z-[1]",
      3: "",
      4: "flex justify-between items-center flex-row-reverse p-1 bg-[url('./assets/images/shop/offer/offer-wide-bg.png')] bg-cover bg-no-repeat ",
      5: "absolute w-[140px] h-auto mt-[-6px] drop-shadow-[-3px_3px_1px_rgba(74,74,52,0.8)] z-[2]",
      6: "w-full pl-1",
      7: "hidden",
      8: " relative text-[2em]",
      9: "",
      10: "flex relative w-[50%] min-w-[50%] gap-1 scale-[1.1] my-1 ml-2 z-[2]",
      11: "absolute right-[10px] bottom-[10px] mt-3 mr-2 ml-3 font-[800]",
      12: "80px",
      13: "hidden",
      14: "hidden",
      15: "scale-[1.2] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]",
    },
    vertical: {
      1: "w-[41%] aspect-[107/208]  bg-[url('./assets/images/shop/offer/offer-vertical-bg.png')] bg-cover bg-no-repeat ",
      2: "hidden",
      3: "hidden",
      4: "flex flex-col justify-around items-center h-full ",
      5: "w-[6em] min-w-[6em] h-auto pt-4 pb-2 px-4 z-[2]",
      6: "flex flex-col items-center w-[95%]",
      7: "hidden",
      8: "hidden",
      9: "hidden",
      10: "grid grid-cols-2 w-[80%] aspect-square justify-items-center gap-1",
      11: "mb-2 font-[800]",
      12: "80px",
      13: "hidden",
      14: "flex",
      15: "scale-[1] top-[50%] translate-y-[-50%] left-0 translate-x-[-20%] -rotate-[70deg] ",
    },
  };

  const labels = {
    corner: {
      1: "absolute z-10 right-[-10px] top-[-16px] w-[3em]",
      2: "absolute z-10 right-[-24px] top-[2px] w-[65px] h-[18px] flex justify-center items-center text-white text-[12px] text-stroke-small rotate-45",
    },
    top: {
      1: "absolute z-10 right-[20px] top-[-7px] w-[9em]",
      2: "absolute z-10 right-[55px] top-[-4px] w-[65px] h-[18px] flex justify-center items-center text-white text-[16px] text-stroke-small",
    },
    vertical_corner: {
      1: "absolute z-10 right-[-7px] top-[-12px] w-[3em]",
      2: "absolute z-10 right-[-20px] top-[5px] w-[65px] h-[18px] flex justify-center items-center text-white text-stroke-small rotate-45",
    },
    full_top: {
      1: "absolute z-10 right-[90px] top-[24px] w-[126px]",
      2: "absolute z-10 right-[115px] top-[24px] w-[65px] h-[18px] flex justify-center items-center text-white text-[16px] text-stroke-small",
    },
  };

  const sales: Record<number, string | undefined> = {
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "602%",
    7: "960%",
    8: "900%",
    9: "1090%",
    10: "1193%",
  };

  return (
    <div className="relative">
      <div
        className={`${layouts[layout][1]} ${colors[type][1]} ${
          !isClaimed ? "" : "saturate-0"
        }  relative border border-[#372C25] z-[2]
           shadow-[0px_4px_2px_0px_rgba(0,0,0,0.3),_0px_0px_3px_1px_rgba(255,255,255,0.2)_inset] `}
      >
        {/* Corners of offer block */}
        <img
          src={require("../../../../assets/images/shop/offer/corner.png")}
          className={` ${colors[type][2]} ${layouts[layout][2]} absolute left-[-4px] top-[-4px] -rotate-90 `}
          alt=""
        />
        <img
          src={require("../../../../assets/images/shop/offer/corner.png")}
          className={` ${colors[type][2]} ${layouts[layout][2]} absolute right-[-4px] top-[-4px] `}
          alt=""
        />
        <img
          src={require("../../../../assets/images/shop/offer/corner.png")}
          className={` ${colors[type][2]} ${layouts[layout][2]} absolute left-[-4px] bottom-[-4px] rotate-180 `}
          alt=""
        />
        <img
          src={require("../../../../assets/images/shop/offer/corner.png")}
          className={` ${colors[type][2]} ${layouts[layout][2]} absolute right-[-4px] bottom-[-4px] rotate-90 `}
          alt=""
        />

        {/* Border of the offer block */}
        <img
          src={require("../../../../assets/images/shop/offer/border-horizontal.png")}
          alt=""
          className={` ${colors[type][2]} ${layouts[layout][3]} absolute top-[-3px] w-full h-[3px] bg-[rgb(255,230,101)] `}
        />
        <img
          src={require("../../../../assets/images/shop/offer/border-vertical.png")}
          alt=""
          className={` ${colors[type][2]} ${layouts[layout][3]} absolute left-[-3px] w-[3px] h-full bg-[rgb(255,230,101)] `}
        />
        <img
          src={require("../../../../assets/images/shop/offer/border-vertical.png")}
          alt=""
          className={` ${colors[type][2]} ${layouts[layout][3]} absolute right-[-3px] w-[3px] h-full bg-[rgb(255,230,101)] `}
        />
        <img
          src={require("../../../../assets/images/shop/offer/border-horizontal.png")}
          alt=""
          className={` ${colors[type][2]} ${layouts[layout][3]} absolute bottom-[-3px] w-full h-[3px] bg-[rgb(255,230,101)] `}
        />

        {/* Offer label */}
        {/* 1 */}
        {type != "blue" && (
          <>
            <img
              src={require("../../../../assets/images/shop/offer/label.png")}
              className={`${
                label != "top" && label != "full_top"
                  ? labels[label][1]
                  : "hidden"
              }`}
              alt=""
            />
            <div
              className={`${
                label != "top" && label != "full_top"
                  ? labels[label][2]
                  : "hidden"
              }`}
            >
              {sales[id] != "" ? sales[id] : "1203%"}
            </div>
          </>
        )}
        {/* 2 */}
        {type != "blue" && (
          <>
            <img
              src={require("../../../../assets/images/shop/offer/label2.png")}
              className={`${
                label === "top" || label === "full_top"
                  ? labels[label][1]
                  : "hidden"
              }`}
              alt=""
            />
            <div
              className={`${
                label === "top" || label === "full_top"
                  ? labels[label][2]
                  : "hidden"
              }`}
            >
              {sales[id] != "" ? sales[id] : "1203%"}
            </div>
          </>
        )}

        {/* Close button for fullsize offer */}
        {layout === "full" && (
          <div
            className={`absolute right-[16px] top-[-55px] w-[3em] h-[3em] z-[100] p-2`}
            onClick={onClose}
          >
            <img
              src={require("../../../../assets/images/shop-modal-close.png")}
              className="w-full h-full"
              alt=""
            />
          </div>
        )}

        {/* Start of the content block */}
        <div className={`${layouts[layout][4]}`}>
          {/* Main picture of the offer */}
          <div className={`${layouts[layout][5]}`}>
            <img
              src={require(`../../../../assets/images/shop/offer/image${offerType}.png`)}
              className={`w-full h-auto`}
              alt=""
            />
          </div>

          {/* FOR VERTICAL AND RANK ONLY */}
          {/* Kitsu rewards */}
          <div
            className={`${layouts[layout][14]}  items-center font-bold text-white text-stroke-small `}
          >
            <div
              className={`absolute top-[2px] w-full h-[1px] bg-gradient-to-r from-[#e6af41] to-[#ffff23] `}
            ></div>
            <div
              className={`absolute bottom-[2px] w-full h-[1px] bg-gradient-to-r from-[#e6af41] to-[#ffff23] `}
            ></div>
            <img
              src={require(`../../../../assets/images/kitsu-icon.png`)}
              className={` w-[1em] h-[1em] `}
              alt=""
            />
            <p className="inline-block">x{kitsu}</p>
          </div>

          {/* Rewards section for all layouts */}
          <div
            className={`text-[#4A4432] text-[12px] leading-[1.2] ${layouts[layout][6]}`}
          >
            <div className={`${layouts[layout][7]} `}>
              <img
                src={require("../../../../assets/images/shop/offer/title-label.png")}
                className={`w-full h-auto`}
                alt=""
              />
            </div>
            <p
              className={`${layouts[layout][8]} ${colors[type][3]} text-white font-bold text-stroke-small z-10`}
            >
              {t(`shop.offers.${offerType}.title`)}
            </p>
            <div
              className={` ${
                layout === "wide" ? "" : "hidden"
              } w-[50%] h-[3px] bg-gradient-to-r from-black to-transparent  `}
            ></div>
            <div
              className={` ${
                layout === "wide" ? "flex" : "hidden"
              } items-center gap-1`}
            >
              <img
                src={require("../../../../assets/images/shop/offer/clock.png")}
                className={`w-[1.5em] h-[1.5em]`}
                alt=""
              />

              <Timer deadline={deadline} />
            </div>
            <div className={` ${layouts[layout][9]} w-[60%]`}>
              {t(`shop.offers.${offerType}.description`)}
            </div>
            <div
              className={` items-end ${layouts[layout][10]} overflow-scroll ${
                type != "blue" ? "bg-[rgb(0,0,0,0.3)]" : "bg-transparent"
              }  p-1 rounded-md `}
            >
              {resourceList.map((r, index) => (
                <OfferResource
                  key={index}
                  resourceType={r.resourceType}
                  resourceId={r.resourceId}
                  isEnabled={r.isEnabled}
                  amount={r.amount}
                  layout={layout}
                />
              ))}
            </div>
          </div>
          <div className={`${layouts[layout][11]} z-10`}>
            <PopupButton
              type="gold"
              width={layouts[layout][12]}
              onClick={onClick}
              size={
                layout === "full"
                  ? "big"
                  : layout === "rank"
                  ? "medium"
                  : "small"
              }
            >
              {/* Rank points notif button */}
              {price ? (
                <>
                  {type != "blue" && rankPoints && (
                    <div
                      className={` ${layouts[layout][13]} absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[80%] -translate-y-[20%] `}
                    >
                      <div
                        className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                      >
                        <img
                          src={require("../../../../assets/images/shop/offer/notify.png")}
                          className={`w-[2em] aspect-square -translate-y-[5%] `}
                          alt=""
                        />
                        {rankPoints}
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center items-center w-full gap-1">
                    <img
                      className="w-[16px] h-[16px]"
                      src={require("../../../../assets/images/shop/offer/offer-star.png")}
                      alt=""
                    />
                    <span className="text-white text-stroke-regular text-[14px] leading-[16px] font-[800]">
                      {price}
                    </span>
                  </div>
                </>
              ) : (
                "Collect"
              )}
            </PopupButton>
          </div>
        </div>
      </div>
      {isClaimed && (
        <div className={`absolute z-[100] ${layouts[layout][15]} `}>
          <div className={`relative w-[235px] h-[90px] `}>
            <img
              className={`absolute w-full h-full`}
              src={require("../../../../assets/images/shop/offer/offer-claimed.png")}
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  );
};
