import { useLocalStorage, useSessionStorage } from "@uidotdev/usehooks";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { clearError } from "../../app/features/errorSlice";
import { openWebSocket } from "../../Websocket/websocketInit";
import { TimerComponent } from "../TimerComponent";
import { useTranslation } from "react-i18next";
import { useSoundService } from "../../utils/soundService";

export const ErrorComponent = ({
  hasError,
  jsError,
}: {
  hasError?: any;
  jsError?: any;
}) => {
  const { t } = useTranslation();
  const [attempts, setAttempts] = useSessionStorage("attemptsReload", 0);
  const [retryDelay, setRetryDelay] = useSessionStorage("retryDelay", 3);
  const dispatch = useDispatch<AppDispatch>();
  const webSocketStatus = useSelector(
    (state: RootState) => state.webSocket.status
  );
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timerInitializedRef = useRef<boolean>(false);
  const { playSound, pausedMusic } = useSoundService();

  const timerCompletedHandler = () => {
    if (!hasError && !jsError) {
      setAttempts(0);
      setRetryDelay(3);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      timerInitializedRef.current = false;
      dispatch(clearError());
    } else if (hasError || jsError) {
      pausedMusic();
      if (attempts <= 1) {
        playSound("error");
      }
      // alert(`webSocketStatus ${webSocketStatus} hasError ${hasError}`);
      if (webSocketStatus === "connected" && hasError) {
        setAttempts(0);
        setRetryDelay(3);
        dispatch(clearError());
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }

      // alert("here");
      setAttempts((prev) => prev + 1);
      const newRetryDelay = Math.min(retryDelay + 3, 30);
      setRetryDelay(newRetryDelay);

      if (webSocketStatus === "idle" || webSocketStatus === "failed") {
        // alert(`webSocketStatus ${webSocketStatus} 2 hasError ${hasError}`);
        dispatch(clearError());
        dispatch(openWebSocket());
      }

      if (jsError) {
        jsError.resetError();
      }
    }
  };

  useEffect(() => {
    if (webSocketStatus === "connected" && hasError && !jsError) {
      setAttempts(0);
      setRetryDelay(3);
      dispatch(clearError());
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [webSocketStatus, hasError, jsError]);

  useEffect(() => {
    if (!jsError && !hasError) {
      // alert("here 2");
      setAttempts(0);
      setRetryDelay(3);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [hasError, jsError]);

  if (hasError || jsError) {
    return (
      <div className="absolute w-full h-full flex flex-col justify-center items-center text-white text-5xl bg-[#1f1c1a] z-[9999]">
        {jsError ? (
          <img
            src={require("../../assets/images/bugImages/smthWentWrong.png")}
            alt=""
          />
        ) : hasError ? (
          <img
            src={require("../../assets/images/bugImages/retryConnection.png")}
            alt=""
          />
        ) : null}
        <div className="flex items-center justify-center space-x-2 mt-5 mb-2">
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-200"></div>
          <div className="w-4 h-4 bg-[#ffefcb] rounded-full animate-bounce delay-400"></div>
        </div>
        <div className="px-3">
          <div className="text-center text-[#ffefcb] text-2xl font-black leading-normal mb-2">
            {jsError
              ? attempts < 3
                ? t("errors.somethingWentWrong")
                : t("errors.tryReloadAppManually")
              : hasError && attempts < 3
              ? t("errors.connectionServerError")
              : t("errors.serverTemporarilyUnavailable")}
          </div>
          <TimerComponent
            startValue={retryDelay}
            onComplete={timerCompletedHandler}
          />
          {jsError && attempts >= 3 ? (
            <div className="text-center text-[#db9328] text-[14px] font-black leading-normal mt-5">
              {t("errors.sorryText")}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return null;
};
