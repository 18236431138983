import React from 'react';
import { useState, useEffect } from 'react';

const Timer = (props: {deadline: string}) => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = new Date(props?.deadline);



    const getTime = (deadline: any) => {
        const time = Date.parse(deadline.toString()) - Date.now();
        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="flex items-center text-white text-stroke-small">
            {days > 1 ? <div>{days} days</div>
                :
                <div className='flex' >
                    <p>{hours < 10 ? '0' + hours : hours}:</p>
                    <p>{minutes < 10 ? '0' + minutes : minutes}:</p>
                    <p>{seconds < 10 ? '0' + seconds : seconds}</p>
                </div>}

        </div>
    );
};

export default Timer;
