import { useState } from "react";
import { ClaimButton } from "../../../../containers/Claim/components";
import { Resources } from "../../../../enums/resources";
import { GoldIco } from "../../../../layout/components/HeaderFarm/components/ResourceCard";
import { resourcesMap } from "../../../../utils/mapping";
import { InviteButton } from "../../../InvitesButtons/components";
import { PopupButton } from "../../../PopupButton";
import { BonusTable } from "./BonusTable";

export const InviteBonuse = ({
  index,
  taskDescription,
  taskDescription2,
  eligibleClaim,
  claimFunc,
  multiplier,
  regularReferralsAvailable,
  userEarnFromRefs,
  isUserInfluencer,
  isUserInfluencerRequestPending,
  revShareInfluencer,
  toClaimBalanceInfluencer,
  totalInfluencerBalance,
  expandPopup,
}: {
  index: number;
  taskDescription: string;
  taskDescription2?: string;
  eligibleClaim?: boolean;
  claimFunc: () => void;
  multiplier?: number;
  regularReferralsAvailable?: number;
  userEarnFromRefs?: number;
  isUserInfluencer?: boolean;
  isUserInfluencerRequestPending?: boolean;
  revShareInfluencer?: number;
  toClaimBalanceInfluencer?: number;
  totalInfluencerBalance?: number;
  expandPopup: () => void;
}) => {



  return (
    <div className="flex justify-between items-center bg-[#473d37] rounded border border-[#554837] shadow-inner-sm-white">

      <div className="p-[1px] h-full">
        <div
          className="bg-[#3f3832] h-full min-w-[102px] "
          style={{
            clipPath: "polygon(0 0, 100% 0%, 75% 100%, 0% 100%)",
          }}
        >
          {index === 0 ? (
            <div className="relative flex flex-col justify-center items-center py-2 px-4">
              <div className="flex justify-start items-center pb-1 pr-4">
                <div className="w-[50px] h-[50px]">
                  <img className="w-full h-full" src={resourcesMap[0].image} />
                </div>
              </div>
              <div className="absolute -bottom-1 pr-4 flex justify-center items-center text-shadow-black-sm">
                <div className="text-white text-2xl font-black  leading-normal">
                  800
                </div>
              </div>
            </div>
          ) : index === 1 ? (
            <div className="flex  justify-center items-end py-5 pr-12 pl-1">
              <div className="w-[50px] h-[50px] flex flex-col">
                <div className="mt-auto text-yellow-400 text-4xl font-extrabold text-shadow-black-sm whitespace-nowrap font-['Sofia_Sans_Semi_Condensed'] ">
                  {multiplier ? multiplier * 100 : 0}%
                </div>
              </div>
            </div>
          ) : index === 2 ? (
            isUserInfluencer ? (
              <div className="flex  justify-center items-end py-5 pr-12 pl-1">
                <div className="w-[50px] h-[50px] flex flex-col">
                  <div className="mt-auto text-yellow-400 text-4xl font-extrabold text-shadow-black-sm whitespace-nowrap">
                    {revShareInfluencer! * 100} %
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex  justify-center items-end p-5 pl-2">
                <div className="flex justify-start items-center ">
                  <div className="w-12 h-[60px]">
                    <img
                      className="w-full h-full"
                      src={require("../../../../assets/images/influencer.png")}
                    />
                  </div>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>

      {index === 0 ? (
        <div className="text-white text-sm font-extrabold  leading-[14px] ">
          {taskDescription}
        </div>
      ) : index === 1 ? (
        <div
          onClick={expandPopup}
          className="text-white text-sm font-extrabold  leading-[14px] underline">
          <span className=" inline-block w-[14px] h-[14px] text-center rounded-full bg-[#4181b7] border-solid border-[1px] border-[#043c6f]" >i </span>
          {taskDescription}
        </div>
      ) : index === 2 ? (
        isUserInfluencerRequestPending ? (
          <div className="flex justify-center items-center  text-center text-[#ffefcb] text-base font-bold  leading-none p-1">
            We have received your submission and are currently reviewing it.
            Thank you for your patience.
          </div>
        ) : isUserInfluencer ? (
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="flex flex-col justify-start items-start ">
              <div className="text-[#ffefcb] text-[13px] font-medium font-['Sofia Sans Semi Condensed'] leading-[13px]">
                {taskDescription}
              </div>
              <div className="p-1 bg-[#342f2b] rounded shadow-inner flex justify-start items-start gap-2.5 ">
                <div className=" text-white text-[13px] font-black font-['Sofia Sans Semi Condensed'] leading-[13px]">
                  USDT {toClaimBalanceInfluencer}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start">
              <div className="text-[#ffefcb] text-[13px] font-medium font-['Sofia Sans Semi Condensed'] leading-[13px]">
                {taskDescription2}
              </div>
              <div className="p-1 bg-[#342f2b] rounded shadow-inner flex justify-start items-start gap-2.5 ">
                <div className="text-white text-[13px] font-black font-['Sofia Sans Semi Condensed'] leading-[13px]">
                  USDT {totalInfluencerBalance}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center ">
            <div className="text-center text-white text-2xl font-black  leading-normal">
              {taskDescription}
            </div>
            <div className="text-center text-[#ffefcb] text-base font-bold  leading-none">
              {taskDescription2}
            </div>
          </div>
        )
      ) : null}

      <div className="flex flex-col justify-center items-center gap-2 py-2 mr-4">
        {userEarnFromRefs! >= 0 ? (
          <div className="flex justify-center items-center gap-2 mr-3 bg-[#342f2b] shadow-inner-sm-black rounded-md border border-warmGray-300 px-2 py-1">
            <div className="w-3 h-3">
              <img
                src={resourcesMap[Resources.kitsu].image}
                className="w-full h-full"
              />
            </div>
            <div className="text-white text-[11px] font-black font-['Sofia Sans Semi Condensed'] leading-[11px]">
              {userEarnFromRefs?.toFixed(2)}
            </div>
          </div>
        ) : null}
        
        {index === 2 && !isUserInfluencer && !isUserInfluencerRequestPending && 
        <div className={`text-[9px] text-[#C3B996] mb-[-5px]`} >Become an influencer</div>}
        
        {index === 2 && isUserInfluencerRequestPending ? null : (
          <PopupButton
            className="align-middle"
            onClick={claimFunc}
            width="72px"
            size="medium"
            type={eligibleClaim ? "green" : "blue"}
          >
            {eligibleClaim ? (
              <div className="flex justify-center items-center absolute -top-[5px] -right-[5px] z-50 w-5 h-5 pointer-events-none">
                <img
                  className="absolute w-full h-full"
                  src={require("../../../../assets/images/heroes/cards/notification.png")}
                  alt="Notification"
                />
                <div className="absolute text-center text-white text-[10px] font-bold  leading-[10px] ">
                  {regularReferralsAvailable ? regularReferralsAvailable : null}
                </div>
              </div>
            ) : null}
            {index === 2
              ? isUserInfluencer
                ? eligibleClaim
                  ? "Claim"
                  : "Invite"
                : "GO"
              : eligibleClaim
                ? "Claim"
                : "Invite"}
          </PopupButton>
        )}
      </div>
    </div>
  );
};
