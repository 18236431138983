import { useTranslation } from "react-i18next";
import { useUtils } from "../../utils/navigateTo";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { TutorialFarmSave, TutorialFarmStage } from "../../interfaces/tutorial";
import { saveFarmTutorialProgress } from "../../app/features/farmTutoralSlice";
import { useTelegram } from "../../hooks/useTelegram";
import { useEffect, useState } from "react";
import { getQuests, getSocialTasks } from "../../endpoints/userQuests";
import { TaskStatus } from "../../app/features/questsSlice";

export const QuestsButton = () => {
  const { t } = useTranslation();
  const { navigateTo } = useUtils();
  const farmTutorialSave = useSelector(
    (state: RootState) => state.farmTutorial.tutorialFarmSave.save
  );

  const { userId } = useTelegram();

  const dispatch = useDispatch<AppDispatch>();
  const updateSave = (save: TutorialFarmSave) => {
    dispatch(saveFarmTutorialProgress({ clientId: userId, save }));
  };
  //TODO SWITCH STAGE SAVE TO clickOnQuestList

  useEffect(() => {
    const fetchQuests = async () => {
      try {
        const fetchedQuests = await getQuests({
          clientId: userId,
        });
        console.log("Fetched Quests:", fetchedQuests);
      } catch (err) {
        console.log("Failed to load quests.");
      }
    };
    const fetchSocialTasks = async () => {
      try {
        const fetchedSocialTasks = await getSocialTasks({
          clientId: userId,
        });

        console.log("Fetched SocialTasks:", fetchedSocialTasks);
      } catch (err) {
        console.log("Failed to load social tasks.");
      }
    };

    fetchSocialTasks();
    fetchQuests();
  });
  
  return (
    <div className="select-none cursor-pointer w-14 h-10 pb-[2px] bg-[#452B31] border border-black rounded-lg shadow-lg  relative transform transition-transform duration-150 active:scale-95">
      <div
        className="w-full h-full rounded-lg flex justify-center items-center bg-gradient-to-b from-[#FEE7BA] to-[#B6765A] p-1 relative"
        onClick={() => {
          if (farmTutorialSave?.stage === TutorialFarmStage.clickOnQuests) {
            updateSave({
              stage: TutorialFarmStage.clickOnQuestList,
            });
          }
          navigateTo("/quests");
        }}
      >
        <div className=" relative flex flex-col items-center w-7 h-7">
          <div className="relative w-full flex justify-center items-center overflow-visible">
            <img
              src={require("../../assets/images/farmIcons/quests.png")}
              className="absolute -top-3 w-[34px] h-[34px] max-w-none"
            />
          </div>
          <div
            className="absolute -top-2.5 text-center text-white text-sm font-bold leading-3 mt-8"
            style={{
              filter:
                "drop-shadow(1px 0px 0px black) drop-shadow(-1px 0px 0px black) drop-shadow(0px 1px 0px black) drop-shadow(0px -1px 0px black)",
            }}
          >
            {t("quests.name")}
          </div>
        </div>
        <QuestsNotificationDot />
      </div>
    </div>
  );
};

const QuestsNotificationDot = () => {
  const [completedQuestsAndTasks, setCompletedQuestsAndTasks] =
    useState<number>(0);

  const dailyQuests = useSelector((state: RootState) => state.quests.quests);
  const socialTask = useSelector((state: RootState) => state.quests.socialTask);

  const checkComplition = () => {
    let counter = 0;
    dailyQuests.map((quest) => {
      if (quest.status === TaskStatus.Done) {
        counter++;
      }
    });
    socialTask.map((task) => {
      if (task.status === TaskStatus.Done) {
        counter++;
      }
    });
    setCompletedQuestsAndTasks(counter);
  };

  useEffect(() => {
    checkComplition();
  }, [dailyQuests, socialTask]);

  return completedQuestsAndTasks ? (
    <div className="absolute flex items-center justify-center -top-[6px] -right-[2px] z-50 w-4 h-4">
      {completedQuestsAndTasks > 1 && (
        <span className="text-center text-white font-bold text-[10px] z-10">{completedQuestsAndTasks}</span>
      )}
      <img
        className="absolute object-cover"
        src={require("../../assets/images/heroes/cards/notification.png")}
        alt="Notification"
      />
    </div>
  ) : null;
};