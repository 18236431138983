export interface TutorialSave {
  stage?: TutorialStage;
  enemyId?: number;
  dialogueId?: number;
  completed?: boolean;
  cardIsDropped?: boolean;
}

export interface TutorialFarmSave {
  stage?: TutorialFarmStage;
  dialogueId?: number;
  completed?: boolean;
}

export enum TutorialStage {
  start,
  stone,
  stone2,
  torch,
  fire,
  amulet,
  battle,
  dungeon,
  dialogue1,
  dialogue2,
  dialogue3,
  finish,
}

export enum TutorialFarmStage {
  battle,
  endbattle,
  start,
  //
  clickOnQuests,
  clickOnQuestList,
  clickOnCompletedQuest,
  claimQuest,
  finishQuestTutor,
  building,
  //new stages for hero uprade tutorial
  clickOnRewards,
  clickOnHeroes,
  clickOnHero,
  upgradeHero,
  //-----------------------------------
  repair,
  startRepair,
  finishRepair,
  finishFirstBuilding,
  //Stages for daily quests and hero tier upgrade tutorial
  clickOnRewards2,
  clickOnUncompletedQuest,
  clickToNavigate,
  clickOnHero2,
  clickOnRankUpgrade,
  chooseTierToUpgrade,
  upgradeTier,
  finishTierTutorial,

  
  finishSecondBuilding,
  finish
}

export enum HeroList {
  hero1,
}
